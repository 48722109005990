
import React, {Component, useEffect, useState, useRef} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Modal , Row, Col } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as FaIcons from "react-icons/fa";
import Loader from '../loader/loader';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


const columns = [
    { id: '1', label: 'File Number', minWidth: 50,span: false },
    { id: '2', label: 'File Name', minWidth: 50,span: false },
    { id: '3', label: 'Company Name', minWidth: 50,span: false },
    { id: '4', label: 'Branch', minWidth: 50,span: false },
    { id: '5', label: 'Submitted By', minWidth: 50,span: false },
    { id: '6', label: 'Designation', minWidth: 50,span: false },
    { id: '7', label: 'File Type', minWidth: 50,span: false },
    { id: '8', label: 'File Date', minWidth: 50,span: false },
    { id: '9', label: 'Submit Date', minWidth: 50,span: false },
    { id: '10', label: 'Status', minWidth: 50,span: false },
    { id: '11', label: '', minWidth: 50,span: false },
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }
  
  const source = [
    {
      
    }
  ];

function Filemanager(props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [modal, setModal] = React.useState(false);
    const [value, setValue] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>File manager</p>
               <Button variant="contained" onClick={()=> setModal(true)}>Add</Button>
            </div>
            <TableContainer sx={{ maxHeight: 440, marginTop: 3 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell >test</TableCell>
                              <TableCell >test</TableCell>
                              <TableCell >test</TableCell>
                              <TableCell >test</TableCell>
                              <TableCell >test</TableCell>
                              <TableCell >test</TableCell>
                              <TableCell >test</TableCell>
                              <TableCell >test</TableCell>
                              <TableCell >test</TableCell>
                              <TableCell >test</TableCell>
                              
                              <TableCell >
                                <Button variant="contained">View</Button>
                              </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Upload file
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12}>
                      <FormControl sx={{ mt: 3, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">Company Name</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                label="Age"
                                size="small"
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                                  <MenuItem value={1}>test</MenuItem>
                                  <MenuItem value={2}>test</MenuItem>
                                  <MenuItem value={3}>test</MenuItem>
  
                            </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12}>
                      <FormControl sx={{ mt: 3, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">Branch</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                label="Age"
                                size="small"
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                                  <MenuItem value={1}>test</MenuItem>
                                  <MenuItem value={2}>test</MenuItem>
                                  <MenuItem value={3}>test</MenuItem>
                            </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12} >
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField  id="outlined-basic" label="File name" variant="outlined"  size="small"/>
                            </FormControl>
                    </Col>
                    <Col xs={12}>
                      <FormControl sx={{ mt: 3, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">File Type</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                label="Age"
                                size="small"
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                                  <MenuItem value={1}>test</MenuItem>
                                  <MenuItem value={2}>test</MenuItem>
                                  <MenuItem value={3}>test</MenuItem>
                            </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12} style={{marginTop: 30}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            label="Date"
                            inputFormat="MM/dd/yyyy"
                            value={value}
                            onChange={handleChange}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Col>
                    <Col xs={12} >
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField  id="outlined-basic" label="Submitted By" variant="outlined"  size="small"/>
                            </FormControl>
                    </Col>
                    <Col xs={12}>
                      <FormControl sx={{ mt: 3, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">Designation</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                label="Age"
                                size="small"
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                                  <MenuItem value={1}>test</MenuItem>
                                  <MenuItem value={2}>test</MenuItem>
                                  <MenuItem value={3}>test</MenuItem>
                            </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12} style={{marginTop:30}}>
                        {/* <Button  variant="contained">Upload</Button> */}
                        <input type="file" name="file"  />
                    </Col>
                    <Col xs={5}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <FormControlLabel control={
                            <Checkbox  />
                            } label="Signature" />
                        </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button style={{float: 'right'}} variant="contained">Submit</Button>
                      <Button style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                  </Row>
              </Modal.Body>
           </Modal>
          </Paper> 
      </div>
    )
}
export default Filemanager;