import React, {useState, useEffect} from 'react';
import { Form  } from "react-bootstrap";
import './accounts.css'
import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
// import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col, Table } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../../loader/loader'


const columns = [
    { id: '1', label: 'Asset', minWidth: 50,span: false },
    { id: '2', label: 'Amount', minWidth: 50,span: false },
   
  ];

  const columns1 = [
    { id: '1', label: 'Liability', minWidth: 50,span: false },
    { id: '2', label: 'Amount', minWidth: 50,span: false },
   
  ];

  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

function Balancesheet(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = useState("")

    const [toggle,  setToggle] = useState(false)
  const [modal, setModal] = useState(false);
  const [age, setAge] = React.useState('');

  const [assetsource, setAssetource] = React.useState([]);
  const [liabilitysource, setLiabilitysource] = React.useState([]);
  

  const [loader, setLoader] = React.useState(false);
  const [routeId, setRouteid] = React.useState("");
  const [routename, setRoutename] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [blocked, setBlocked] = React.useState(0)

  // function setData(childData) {
  //   setToggle(childData)
  // }

  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const checkChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
      setBlocked(1)
    }
    else {
      setBlocked(0)
    }
    // do whatever you want with isChecked value
  }

  useEffect(() => {
      getData()
    }, []);

    const getData = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        setLoader(true)
        var data = {
          "type":  userdata.user_type,
          "companyid": company
        }
        fetch(global.url + "updatedbalanceSheet", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("response balancesheet", responseJson)
              if(responseJson.error == false){
                setLoader(false)
                setAssetource(responseJson.data.asset)
                setLiabilitysource(responseJson.data.liability)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    return(
        <div className="main-content">
          
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Balance Sheet</p>
            </div>
            <div>
              <div style={{margin: 20, display: 'flex',}}>
                <div className="pandlh">
                  <Table responsive style={{borderBottomWidth: 'unset'}} >
                      <thead>
                        <tr>
                          <th colSpan={2} style={{width: '50%', borderBottomWidth: 0}}>Liabilities</th>
                          {/* <th style={{width: '25%', borderBottomWidth: 0, textAlign: 'end'}}></th> */}
                          <th style={{width: '50%', borderBottomWidth: 0, textAlign: 'end'}}>as at 1-Nov-2018</th>
                        </tr>
                      </thead>
                  </Table>
                </div>
                <div className="pandlh">
                  <Table responsive >
                    <thead>
                      <tr>
                        <th colSpan={2} style={{width: '50%', borderBottomWidth: 0}}>Assets</th>
                        {/* <th style={{width: '25%', borderBottomWidth: 0, textAlign: 'end'}}></th> */}
                        <th style={{width: '50%', borderBottomWidth: 0, textAlign: 'end'}}>as at 1-Nov-2018</th>
                      </tr>
                    </thead>
                  </Table>
                </div>
              </div>

              <div style={{margin: 20, display: 'flex', marginTop: -20}}>
                <div className="pandl">
                  <Table responsive >
                      <tbody>
                        <>
                          <tr>
                            <th colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}>Capital Account</th>
                            <th style={{width: '25%', borderBottomWidth: 0}}></th>
                            <th style={{textAlign : 'end', fontSize: 13,width: '25%', borderBottomWidth: 0}}>10000000</th>
                          </tr>
                              <tr>
                                <td colSpan={2} style={{fontSize: 13, paddingLeft: 30, width: '50%', borderBottomWidth: 0}}>Capital a/c - sanjay sharma</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}>50000000</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}></td>
                              </tr>
                              <tr>
                                <td colSpan={2} style={{fontSize: 13, paddingLeft: 30, width: '50%', borderBottomWidth: 0}}>Capital a/c - vijay sharma</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', }}>50000000</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}></td>
                              </tr>
                        </>
                        <tr>
                            <th colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}>Loans (Liability)</th>
                            <th style={{width: '25%', borderBottomWidth: 0}}></th>
                            <th style={{textAlign : 'end', fontSize: 13,width: '25%', borderBottomWidth: 0}}></th>
                          </tr>
                            <tr>
                                <th colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}>Current Liabilities</th>
                                <th style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}></th>
                                <th style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}>631897</th>
                              </tr>
                            <>
                              <tr>
                                <td colSpan={2} style={{fontSize: 13, paddingLeft: 30, width: '50%', borderBottomWidth: 0}}>Duties & Taxes</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%',borderBottomWidth: 0 }}>491040</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}></td>
                              </tr>
                              <tr>
                                <td colSpan={2} style={{fontSize: 13, paddingLeft: 30, width: '50%', borderBottomWidth: 0}}>Sunday Creditor</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', }}>140857</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}></td>
                              </tr>
                            </> 
                            <tr>
                                <th colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}>Profit & Loss A/c</th>
                                <th style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}></th>
                                <th style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}>6664872</th>
                              </tr>
                            <>
                              <tr>
                                <td colSpan={2} style={{fontSize: 13, paddingLeft: 30, width: '50%', borderBottomWidth: 0}}>Opening Balance</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%',borderBottomWidth: 0 }}>5916212</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}></td>
                              </tr>
                              <tr>
                                <td colSpan={2} style={{fontSize: 13, paddingLeft: 30, width: '50%', borderBottomWidth: 0}}>Current Period</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', }}>748660</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}></td>
                              </tr>
                            </>   
                    </tbody>
                  </Table>
                </div>
                <div className="pandl">
                  <Table responsive>
                      <tbody>
                            <>
                              <tr>
                                <th colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}>Fixed Assets</th>
                                <th style={{width: '25%', borderBottomWidth: 0}}></th>
                                <th style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}>101712</th>
                              </tr>
                                <tr>
                                  <td colSpan={2} style={{fontSize: 13, paddingLeft: 30, width: '50%', borderBottomWidth: 0}}>Computer</td>
                                  <td style={{textAlign : 'end', fontSize: 13, width: '25%',}}>101732</td>
                                  <td style={{width: '25%', borderBottomWidth: 0}}></td>
                                </tr>
                            </>
                            <tr>
                              <th colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}>Current Assets</th>
                              <th style={{width: '25%', borderBottomWidth: 0}}></th>
                              <th style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}>8193737.81</th>
                            </tr>
                            <>
                              <tr>
                                <td colSpan={2} style={{fontSize: 13, paddingLeft: 30, width: '50%', borderBottomWidth: 0}}>Closing stock</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}>407677</td>
                                <td style={{width: '25%', borderBottomWidth: 0}}></td>
                              </tr>
                              <tr>
                                <td colSpan={2} style={{fontSize: 13, paddingLeft: 30, width: '50%', borderBottomWidth: 0}}>Sunday debitors</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}>1693065</td>
                                <td style={{width: '25%', borderBottomWidth: 0}}></td>
                              </tr>
                              <tr>
                                <td colSpan={2} style={{fontSize: 13, paddingLeft: 30, width: '50%', borderBottomWidth: 0}}>Cash-in-hand</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}>2133295</td>
                                <td style={{width: '25%', borderBottomWidth: 0}}></td>
                              </tr>
                              <tr>
                                <td colSpan={2} style={{fontSize: 13, paddingLeft: 30, width: '50%', borderBottomWidth: 0}}>Bank Accounts</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%',}}>3959700</td>
                                <td style={{width: '25%', borderBottomWidth: 0}}></td>
                              </tr>
                            </>
                      </tbody>
                  </Table>
                </div>
              </div>

              <div style={{margin: 20, display: 'flex', marginTop: -20}}>
                <div className="pandlb">
                  <Table responsive >
                      <tbody>
                          <tr>
                            <td colSpan={2} className='balance-table' style={{width: '50%'}}></td>
                            <td style={{width: '25%'}}></td>
                            <td style={{textAlign : 'end', fontSize: 13,width: '25%'}}></td>
                          </tr>
                          <tr>
                            <th colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}>Total</th>
                            <th style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}></th>
                            <th style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}>8296769.81</th>
                          </tr>
                    </tbody>
                  </Table>
                </div>
                <div className="pandlb">
                  <Table responsive>
                    <tbody>
                            <tr>
                              <td colSpan={2} className='balance-table' style={{width: '50%',}}></td>
                              <td style={{width: '25%'}}></td>
                              <td style={{textAlign : 'end', fontSize: 13,width: '25%'}}></td>
                            </tr>
                            <tr>
                              <th colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}>Total</th>
                              <th style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}></th>
                              <th style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}>8296769.81</th>
                            </tr>
                      </tbody>
                  </Table>
                </div>
              </div>

            </div>
          </Paper> 
        
      </div>
    )
}
export default Balancesheet;