import './dashboard.css';
import React, {Component, useEffect, useState, useRef} from 'react';
import Paper from '@mui/material/Paper';
import { Row, Col, Table, Modal } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { HiSave } from "react-icons/hi";
import { BsFillPeopleFill, BsFillEyeFill} from "react-icons/bs";
import { BiPencil} from "react-icons/bi";
import { FiUmbrella, FiShoppingBag } from "react-icons/fi";
import { AiFillDollarCircle, AiFillMoneyCollect, AiOutlineSetting } from "react-icons/ai";
import { MdFindReplace } from "react-icons/md";
import { GoSettings } from "react-icons/go";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Checkbox from '@mui/material/Checkbox';
import { Doughnut } from 'react-chartjs-2';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// import Table from 'react-bootstrap/Table';

import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, LineChart, Line, Brush, AreaChart, Area, ResponsiveContainer } from 'recharts';

// const data = [
// {name: 'Jan', uv: 100, pv: 2400, amt: 2400},
// {name: 'Feb', uv: 300, pv: 2400, amt: 2400},
// {name: 'Mar', uv: 500, pv: 2400, amt: 2400},
// {name: 'Apr', uv: 200, pv: 2400, amt: 2400},
// {name: 'May', uv: 400, pv: 2400, amt: 2400},
// {name: 'Jun', uv: 300, pv: 2400, amt: 2400}];

// const items = [
//     { name: 'Jan', uv: 10, pv: 50, amt: 10 },
//     { name: 'Fab', uv: 50, pv: 60, amt: 50 },
//     { name: 'Mar', uv: 100, pv: 10, amt: 100 },
//     { name: 'Apr', uv: 80, pv: 90, amt: 80 },
//     { name: 'May', uv: 50, pv: 30, amt: 50 },
//     { name: 'Jun', uv: 80, pv: 100, amt: 80 },
//     { name: 'Jul', uv: 150, pv: 180, amt: 150 },
//     { name: 'Aug', uv: 120, pv: 140, amt: 120 },
//     { name: 'Sep', uv: 40, pv: 80, amt: 40 },
//     { name: 'Oct', uv: 20, pv: 120, amt: 20 },
//     { name: 'Nov', uv: 60, pv: 20, amt: 60 },
//     { name: 'Dec', uv: 150, pv: 100, amt: 150 },
//   ];

//   const data = [
//     {
//       "name": "1",
//       "uv": 4000,
     
//     },
//     {
//       "name": "2",
//       "uv": 6000,
     
//     },
//     {
//       "name": "3",
//       "uv": 2000,
     
//     },
//     {
//       "name": "4",
//       "uv": 9780,
     
//     },
//     {
//       "name": "5",
//       "uv": 1890,
     
//     },
//     {
//       "name": "6",
//       "uv": 6390,
     
//     },
//     {
//       "name": "7",
//       "uv": 3490,
     
//     },
//     {
//         "name": "8",
//         "uv": 3000,
       
//       },
//       {
//         "name": "9",
//         "uv": 5000,
       
//       },
//       {
//         "name": "10",
//         "uv": 8000,
       
//       },
//       {
//         "name": "11",
//         "uv": 2780,
       
//       },
//       {
//         "name": "12",
//         "uv": 6890,
       
//       },
//       {
//         "name": "13",
//         "uv": 7350,
       
//       },
//       {
//         "name": "14",
//         "uv": 9490,
       
//       },
//       {
//         "name": "15",
//         "uv": 4000,
       
//       },
//       {
//         "name": "16",
//         "uv": 2000,
       
//       },
//       {
//         "name": "17",
//         "uv": 3060,
       
//       },
//       {
//         "name": "18",
//         "uv": 2780,
       
//       },
//       {
//         "name": "19",
//         "uv": 1890,
       
//       },
//       {
//         "name": "20",
//         "uv": 8390,
       
//       },
//       {
//         "name": "21",
//         "uv": 9490,
       
//       },
//       {
//           "name": "22",
//           "uv": 5000,
         
//         },
//         {
//           "name": "23",
//           "uv": 7000,
         
//         },
//         {
//           "name": "24",
//           "uv": 3574,
         
//         },
//         {
//           "name": "25",
//           "uv": 5436,
         
//         },
//         {
//           "name": "26",
//           "uv": 2789,
         
//         },
//         {
//           "name": "27",
//           "uv": 8056,
         
//         },
//         {
//           "name": "28",
//           "uv": 6234,
         
//         },
//         {
//             "name": "29",
//             "uv": 3452,
           
//           },
//           {
//             "name": "30",
//             "uv": 8390,
           
//           },
//           {
//             "name": "31",
//             "uv": 3490,
           
//           }
//   ];
  const data2 = [
    {
      "name": "sales",
      "uv": 4000,
      "pv": 2400
    },
    // {
    //   "name": "SO",
    //   "uv": 3000,
    //   "pv": 1398
    // },
    // {
    //   "name": "SR",
    //   "uv": 2000,
    //   "pv": 9800
    // },
    // {
    //   "name": "Page D",
    //   "uv": 2780,
    //   "pv": 3908
    // },
    // {
    //   "name": "Page E",
    //   "uv": 1890,
    //   "pv": 4800
    // },
    // {
    //   "name": "Page F",
    //   "uv": 2390,
    //   "pv": 3800
    // },
    // {
    //   "name": "Page G",
    //   "uv": 3490,
    //   "pv": 4300
    // },
    // {
    //     "name": "Page h",
    //     "uv": 7490,
    //     "pv": 5000
    //   }
  ];

  const data3 = [
    {
        "name": "sales",
        "u": 4000,
        "p": 2400
      },
    
      
  ];
  

const columns = [
  { id: '1', label: 'Company', minWidth: 50,span: false },
  { id: '2', label: 'Ledger', minWidth: 50,span: false },
  { id: '3', label: 'address', minWidth: 50,span: false },
  { id: '4', label: 'GSTIN', minWidth: 50,span: false },
  { id: '5', label: 'Mobile', minWidth: 50,span: false },
  { id: '6', label: 'Email', minWidth: 50,span: false },
  { id: '7', label: 'Website', minWidth: 50,span: false },
  { id: '8', label: 'Whatsap No', minWidth: 50,span: false },
  { id: '9', label: 'Mobile Prefix', minWidth: 50,span: false },
  { id: '10', label: 'Location', minWidth: 50,span: false },
  { id: '11', label: 'Caption', minWidth: 50,span: false },
];


  const data1 = {
    labels: ['Red', 'Yellow', 'Green', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: [7, 15, 2, 3,],
        backgroundColor: [
          '#fff700',
          '#00ff33',
          '#80159e',
          '#ffa600',
        ],
        borderColor: [
          '#fff700',
          '#00ff33',
          '#80159e',
          '#ffa600',
        ],
        borderWidth: 1,
      },
    ],
  };

function Dashboard(props) {
const [modal, setModal] = useState(false);

  const [currSales, setCurrSales] = React.useState([]);
  const [avgSales, setAvgSales] = React.useState([]);
  const [avgPurchase, setAvgPurchase] = React.useState([]);

  const [dashboardobject, setDashboardobject] = React.useState({});
  const [totalorders, setTotalorders] = React.useState([]);
  const [cancelorders, setCancelorders] = React.useState([]);
  const [pendingorders, setPendingorders] = React.useState([]);


  useEffect(() => {
      getData()
      getDashboarddata()
      getCurrentSales()
      getAvgSales()
      getAvgPurchase()
  }, []);

  const getDashboarddata = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    console.log("company", company)
      var data = {
          "companyid": company
      }
    fetch(global.url + "adminDashboard", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      console.log("dashboard data", responseJson)
      setDashboardobject(responseJson.data)
      setTotalorders(responseJson.data.salesorder)
      setCancelorders(responseJson.data.cancelorder)
      setPendingorders(responseJson.data.pendingorder)
    })
    .catch((error)=>{
        console.log(error)
    })
  }
  const getCurrentSales = () => {
    var company = JSON.parse(localStorage.getItem("company"))
    console.log("company", company)
      var data = {
          "companyid": company
      }
    fetch(global.url + "salesCurrentMonth", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      console.log("sales avg graph", responseJson)
        if(responseJson.error == false){
            setCurrSales(responseJson.data)
        }
    })
    .catch((error)=>{
        console.log(error)
    })
  }
  const getAvgSales = () => {
    var company = JSON.parse(localStorage.getItem("company"))
    console.log("company", company)
      var data = {
          "companyid": company
      }
    fetch(global.url + "salesAverageMonthwise", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      console.log("sales graph", responseJson)
        if(responseJson.error == false){
            setAvgSales(responseJson.data)
        }
    })
    .catch((error)=>{
        console.log(error)
    })
  }
  const getAvgPurchase = () => {
    var company = JSON.parse(localStorage.getItem("company"))
    console.log("company", company)
      var data = {
          "companyid": company
      }
    fetch(global.url + "purchaseAverageMonthwise", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      console.log("sales graph", responseJson)
        if(responseJson.error == false){
            setAvgPurchase(responseJson.data)
        }
    })
    .catch((error)=>{
        console.log(error)
    })
  }
  const getData = () => {

      fetch(global.url + "viewcompany", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("data", responseJson)
        //   setSource(responseJson.data)
  
      })
      .catch((error)=>{
          console.log(error)
      })
  }


  const statusChange = (event, id) => {
        // console.log("k", k)
        // var input  = [...statusarray]
        // input[k] = event.target.value
        // setStatusarray(input)

        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
            "companyid":  company,
            "status": event.target.value,
            "pdc_id":  id
        }
        fetch(global.url + "PDCDone", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              if(responseJson.error == false){
                window.location.reload();
                alert(responseJson.message)
              }
              else{
                alert(responseJson.message)
              }
                
              
          })
          .catch((error)=>{
              console.log(error)
          })
    }



  return (
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20}}>
                <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
                    <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Dashboard</p>
               </div>
            <div sx={{ width: '100%', overflow: 'hidden' }}>
                {/* <Row>
                    <Col xs={12} md={11}>
                       <div>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                <th></th>
                                <th>Sales</th>
                                <th>Sales Order</th>
                                <th>Sales Return</th>
                                <th>Purchase</th>
                                <th>Purchase Return</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>Today</td>
                                <td>1000</td>
                                <td>250</td>
                                <td>25</td>
                                <td>2500</td>
                                <td>2500</td>
                                </tr>
                                <tr>
                                <td>This Month</td>
                                <td>3000</td>
                                <td>1500</td>
                                <td>50</td>
                                <td>7500</td>
                                <td>7700</td>
                                </tr>
                                <tr>
                                <td>This Year</td>
                                <td>6000</td>
                                <td>3000</td>
                                <td>150</td>
                                <td>1500</td>
                                <td>7500</td>
                                </tr>
                            </tbody>
                        </Table>
                      </div>
                    </Col>
                </Row> */}
               
                <Row style={{marginTop: '30px'}}>
                    <Col xs={12} md={6}>
                    <div style={{textAlign: 'center', fontSize: 15, fontWeight: 'bold'}}>Sales current month</div>
                    <div style={{marginTop: 20}}>
                        <BarChart width={500} height={300} data={currSales}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="UV" fill="#8884d8" />
                        </BarChart>
                    </div>
                    
                    </Col>
                    <Col xs={12} md={6}>
                       <Row>
                            <Col xs={12} md={6}>
                            <div style={{textAlign: 'center', fontSize: 15, fontWeight: 'bold'}}>Sales Average</div>
                            <div style={{marginTop: 20}}>
                                <BarChart width={250} height={300} data={avgSales}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="uptomonth" fill="#8884d8" />
                                    <Bar dataKey="currentmonth" fill="#82ca9d" />
                                </BarChart>
                            </div>
                                
                            </Col>
                            <Col xs={12} md={6} >
                            <div style={{textAlign: 'center', fontSize: 15, fontWeight: 'bold'}}>Purchase Average</div>
                            <div style={{marginTop: 20}}>
                                <BarChart width={250} height={300} data={avgPurchase}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="uptomonth" fill="#8884d8" />
                                    <Bar dataKey="currentmonth" fill="#82ca9d" />
                                </BarChart>
                            </div>
                                
                            </Col>
                          
                       </Row>  
                       <Row style={{marginTop: 30}}>
                           
                            {/* <Col xs={12} md={3}>
                                <BarChart width={100} height={150} data={data3}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="p" fill="#82ca9d" />
                                <Bar dataKey="u" fill="#8884d8" />
                                </BarChart>
                             </Col> */}
                       </Row>      
                    </Col>
                    
                </Row>

                {/* <Row>
                    <Col xs={12} md={6}>
                    <BarChart width={130} height={180} data={data3}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="pv" fill="#82ca9d" />
                        <Bar dataKey="uv" fill="#8884d8" />
                        </BarChart>
                    </Col>
                </Row> */}
            
            {/* <Row>
                <div style={{float: 'right'}}>
                    <a type='button' onClick={() => setModal(true)}>
                        <AiOutlineSetting className="spinner" style={{fontSize:40}}/>
                    </a>
                </div>
                </Row> */}
                {/* <Row>
                <span style={{marginTop: 30}}><strong>Registered Users</strong></span>
                </Row>
                <Row style={{marginTop: 10}}>
                    <Col sm={3}>
                       
                        <div className="card w_data_1">
                            <div className="card-body">
                                <div style={{position: 'absolute', right: 0, top: 0, }}>
                                    <a href="accounts/employee">
                                        <BsFillEyeFill style={{marginRight: 10, marginTop: 5, fontSize: 20}}/>
                                    </a>
                                    <a href="accounts/employee">
                                        <BiPencil style={{marginRight: 10, marginTop: 5, fontSize: 20}}/>
                                    </a>
                                </div>
                                <div className="w_icon indigo">
                                    <BsFillPeopleFill />
                                </div>
                                <h4 class="mt-3">18</h4>
                                <span><strong>Employees</strong></span>
                            </div>
                        </div>
                    </Col>
                    <Col sm={3}>
                       
                        XZ
                    </Col>
                    <Col sm={3}>
                       
                        <div className="card w_data_1">
                            <div className="card-body">
                                <div style={{position: 'absolute', right: 0, top: 0, }}>
                                    <a href="/accounts/supplier">
                                        <BsFillEyeFill style={{marginRight: 10, marginTop: 5, fontSize: 20}}/>
                                    </a>
                                    <a href="/accounts/supplier">
                                        <BiPencil style={{marginRight: 10, marginTop: 5, fontSize: 20}}/>
                                    </a>
                                </div>
                                <div className="w_icon indigo">
                                <BsFillPeopleFill />
                                </div>
                                <h4 class="mt-3">18</h4>
                                <span><strong>Supplier</strong></span>
                            </div>
                        </div>
                    </Col>
                    <Col sm={3}>
                       
                        <div className="card w_data_1">
                            <div className="card-body">
                                <div style={{position: 'absolute', right: 0, top: 0, }}>
                                    <a href="accounts/employee">
                                        <BsFillEyeFill style={{marginRight: 10, marginTop: 5, fontSize: 20}}/>
                                    </a>
                                    <a href="accounts/employee">
                                        <BiPencil style={{marginRight: 10, marginTop: 5, fontSize: 20}}/>
                                    </a>
                                </div>
                                <div className="w_icon indigo">
                                <BsFillPeopleFill />
                                </div>
                                <h4 class="mt-3">18</h4>
                                <span><strong>Referrals</strong></span>
                            </div>
                        </div>
                    </Col>
                </Row> */}

                {/* <Row style={{marginTop: 50}}>
                    <Col sm={3}>
                       
                        <div className="card w_data_1">
                            <div className="card-body">
                                <div className="erp-icon-rectangle bg-gradient-primary">
                                    <AiFillDollarCircle style={{fontSize:30}}/>
                                </div>
                                <h4 class="mt-3">$32,459.00</h4>
                                <span><strong>Total Income</strong></span>
                            </div>
                        </div>
                    </Col>
                    <Col sm={3}>
                       
                        <div className="card w_data_1">
                            <div className="card-body">
                                <div className="erp-icon-rectangle bg-gradient-warning">
                                    <AiFillMoneyCollect style={{fontSize:30}}/>
                                </div>
                                <h4 class="mt-3">$23.361.00</h4>
                                <span><strong>Budget</strong></span>
                            </div>
                        </div>
                    </Col>
                    <Col sm={3}>
                       
                        <div className="card w_data_1">
                            <div className="card-body">
                                <div className="erp-icon-rectangle bg-gradient-success">
                                    <FiUmbrella style={{fontSize:30}}/>
                                </div>
                                <h4 class="mt-3">$40,863.00</h4>
                                <span><strong>Spending</strong></span>
                            </div>
                        </div>
                    </Col>
                    <Col sm={3}>
                       
                        <div className="card w_data_1">
                            <div className="card-body">
                                <div className="erp-icon-rectangle bg-gradient-danger">
                                    <FiShoppingBag style={{fontSize:30}}/>
                                </div>
                                <h4 class="mt-3">4215</h4>
                                <span><strong>Orders</strong></span>
                            </div>
                        </div>
                    </Col>
                </Row> */}

                {/* <Row style={{marginTop: 20}}>
                <span><strong>Currancy Rate</strong></span>
                </Row>
                <Row style={{marginTop: 10}}>
                    <Col sm={3}>
                       
                        <div className="card w_data_1">
                            <div className="card-body">
                                
                                <h4 class="mt-3">18</h4>
                                <span><strong>QAR</strong></span>
                            </div>
                        </div>
                    </Col>
                    <Col sm={3}>
                       
                        <div className="card w_data_1">
                            <div className="card-body">
                                
                                <h4 class="mt-3">18</h4>
                                <span><strong>AED</strong></span>
                            </div>
                        </div>
                    </Col>
                    <Col sm={3}>
                       
                        <div className="card w_data_1">
                            <div className="card-body">
                                
                                <h4 class="mt-3">18</h4>
                                <span><strong>USD</strong></span>
                            </div>
                        </div>
                    </Col>
                    <Col sm={3}>
                       
                        <div className="card w_data_1">
                            <div className="card-body">
                                
                                <h4 class="mt-3">18</h4>
                                <span><strong>KD</strong></span>
                            </div>
                        </div>
                    </Col>
                </Row> */}
                {/* <Row>
                    <span style={{marginTop: 30}}><strong>Sales</strong></span>
                </Row>

                <Row style={{marginTop: 30}}>
                    <Col sm={3}>
                       
                        <div className="card w_data_1" style={{background: '#04BE5B'}}>
                            <div className="card-body" style={{background: '#04BE5B'}}>
                                <div style={{position: 'absolute', right: 0, top: 0, }}>
                                <a href="reports/salesreport">
                                    <BsFillEyeFill style={{color: '#fff', marginRight: 10, marginTop: 5}}/>
                                </a>
                               
                                </div>
                                <span style={{color: '#fff', marginTop: 10}}><strong>DAILY SALE</strong></span>
                                <h4 style={{color: '#fff'}} class="mt-3">QAR {dashboardobject.todaysales}</h4>
                            </div>
                        </div>
                    </Col>
                    <Col sm={3}> 
                       
                        <div className="card w_data_1" style={{background: '#46b6fe'}}>
                            <div className="card-body" style={{background: '#46b6fe'}}>
                                <span style={{color: '#fff'}}><strong>MONTHLY SALE</strong></span>
                                <h4 style={{color: '#fff'}} class="mt-3">QAR {dashboardobject.monthlysales}</h4>
                            </div>
                        </div>
                    </Col>
                    <Col sm={3}>
                       
                        <div className="card w_data_1" style={{background: '#04BE5B'}}>
                            <div className="card-body" style={{background: '#04BE5B'}}>
                                <span style={{color: '#fff'}}><strong>THIS YEAR SALE</strong></span>
                                <h4 style={{color: '#fff'}} class="mt-3">QAR {dashboardobject.yearlysales}</h4>
                            </div>
                        </div>
                    </Col>
                    
                </Row>

                <Row>
                    <span style={{marginTop: 30}}><strong>Purchase</strong></span>
                </Row>

                <Row style={{marginTop: 30}}>
                    <Col sm={3}>
                       
                        <div className="card w_data_1" style={{background: '#04BE5B'}}>
                            <div className="card-body" style={{background: '#04BE5B'}}>
                                <div style={{position: 'absolute', right: 0, top: 0, }}>
                                <a href="/Reports/PurchaseReport">
                                    <BsFillEyeFill style={{color: '#fff', marginRight: 10, marginTop: 5}}/>
                                </a>
                               
                                </div>
                                <span style={{color: '#fff', marginTop: 10}}><strong>DAILY PURCHASE</strong></span>
                                <h4 style={{color: '#fff'}} class="mt-3">QAR {dashboardobject.todaypurchse}</h4>
                            </div>
                        </div>
                    </Col>
                    <Col sm={3}> 
                       
                        <div className="card w_data_1" style={{background: '#46b6fe'}}>
                            <div className="card-body" style={{background: '#46b6fe'}}>
                                <span style={{color: '#fff'}}><strong>MONTHLY PURCHASE</strong></span>
                                <h4 style={{color: '#fff'}} class="mt-3">QAR {dashboardobject.monthlypurchase}</h4>
                            </div>
                        </div>
                    </Col>
                    <Col sm={3}>
                       
                        <div className="card w_data_1" style={{background: '#04BE5B'}}>
                            <div className="card-body" style={{background: '#04BE5B'}}>
                                <span style={{color: '#fff'}}><strong>THIS YEAR PURCHASE</strong></span>
                                <h4 style={{color: '#fff'}} class="mt-3">QAR {dashboardobject.yearlypurchase}</h4>
                            </div>
                        </div>
                    </Col>
                    
                </Row>
                {totalorders.length == 0 ? null :
                    <div>
                        <Row>
                            <span style={{marginTop: 30}}><strong>Sales Orders</strong></span>
                        </Row>
                        <Row>
                            {totalorders.map((i, index) => (
                                <Col sm={3} style={{marginTop: 30}}>   
                                    <div className="card w_data_1" style={{background: '#46b6fe'}}>
                                        <div className="card-body" style={{background: '#46b6fe'}}>
                                            <div style={{position: 'absolute', right: 0, top: 0, }}>
                                                <a href="/Sales/SalesApproval">
                                                    <BsFillEyeFill style={{color: '#fff', marginRight: 10, marginTop: 5}}/>
                                                </a>
                                                
                                            </div>
                                            <span style={{color: '#fff'}}><strong>{i.ordertype_name}</strong></span>
                                            <h6 style={{color: '#fff'}} class="mt-3">TOTAL ORDERS : {i.totalorder}</h6>
                                        </div>
                                    </div>  
                                </Col>
                            ))}
                        </Row>
                    </div>
                }
                {cancelorders.length == 0 ? null :
                <div>
                    <Row>
                        <span style={{marginTop: 30}}><strong>Pending Orders</strong></span>
                    </Row>
                    <Row>
                        {pendingorders.map((i, index) => (
                            <Col sm={3} style={{marginTop: 30}}>   
                                <div className="card w_data_1" style={{background: '#46b6fe'}}>
                                    <div className="card-body" style={{background: '#46b6fe'}}>
                                        <div style={{position: 'absolute', right: 0, top: 0, }}>
                                            <a href="/Sales/SalesApproval">
                                                <BsFillEyeFill style={{color: '#fff', marginRight: 10, marginTop: 5}}/>
                                            </a>
                                           
                                        </div>
                                        <span style={{color: '#fff'}}><strong>{i.ordertype_name}</strong></span>
                                        <h6 style={{color: '#fff'}} class="mt-3">TOTAL ORDERS : {i.totalorder}</h6>
                                    </div>
                                </div>  
                            </Col>
                        ))}
                    </Row>
                </div>
            }
                {cancelorders.length == 0 ? null :
                    <div> 
                    <Row>
                        <span style={{marginTop: 30}}><strong>Cancel Orders</strong></span>
                    </Row>
                    <Row>
                        {cancelorders.map((i, index) => (
                            <Col sm={3} style={{marginTop: 30}}>   
                                <div className="card w_data_1" style={{background: '#46b6fe'}}>
                                    <div className="card-body" style={{background: '#46b6fe'}}>
                                        <div style={{position: 'absolute', right: 0, top: 0, }}>
                                            <a href="/Sales/SalesApproval">
                                                <BsFillEyeFill style={{color: '#fff', marginRight: 10, marginTop: 5}}/>
                                            </a>
                                          
                                        </div>
                                        <span style={{color: '#fff'}}><strong>{i.ordertype_name}</strong></span>
                                        <h6 style={{color: '#fff'}} class="mt-3">TOTAL ORDERS : {i.totalorder}</h6>
                                    </div>
                                </div>  
                            </Col>
                        ))}
                    </Row>
                </div>} */}
                
            {/* 
                <Row style={{marginTop: 20}}>
                    <Col xs={12} >
                        <span><strong>Manage pdc</strong></span>
                    </Col>
                    
                </Row> */}
                {/* <Row style={{marginTop: 10, paddingBottom: 20}} className="header-table"> */}
                    {/* <Col sm={2} style={{marginTop: 10}} className="clr">
                        <LocalizationProvider sx={{width: '100%'}} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="From Date"
                                value={date}
                                onChange={(newValue) => {
                                    setDate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Col>
                    <Col sm={2} style={{marginTop: 10}} className="clr">
                        <LocalizationProvider sx={{width: '100%'}} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="To Date"
                                value={date}
                                onChange={(newValue) => {
                                    setDate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Col>

                    <Col sm={2} className="clr">
                      <FormControl sx={{mt:1, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">Cheque Type</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={company}
                                label="Age"
                                onChange={companyChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                                <MenuItem value="0">PAYMENT</MenuItem>
                                <MenuItem value="2">RECIEVED</MenuItem>
                            </Select>
                      </FormControl>
                    </Col>
                    <Col sm={2} className="clr">
                      <FormControl sx={{mt:1, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">Client Name</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={company}
                                label="Age"
                                onChange={companyChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>All</em>
                              </MenuItem>
                                <MenuItem value="0">PAYMENT</MenuItem>
                                <MenuItem value="2">RECIEVED</MenuItem>
                            </Select>
                      </FormControl>
                    </Col>
                    <Col sm={2} className="clr">
                      <FormControl sx={{mt:1, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={company}
                                label="Age"
                                onChange={companyChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>All</em>
                              </MenuItem>
                                <MenuItem value="0">PAYMENT</MenuItem>
                                <MenuItem value="2">RECIEVED</MenuItem>
                            </Select>
                      </FormControl>
                    </Col>

                    <Col sm={2}>
                        <Button variant="contained" >View</Button>
                    </Col> */}
                {/* </Row> */}
                {/* <Row>
                        <Col xs={12}>
                            <Table bordered responsive style={{height: 100}}>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Status</th>
                                        <th>Cheque No</th>
                                        <th>Entry date</th>
                                        <th>Post date</th>
                                        <th>Done date</th>
                                        <th>Client name</th>
                                        <th>Bank</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        pdclist.map((i,k) => {
                                            return(
                                                <tr>
                                                    <td>{k+1}</td>
                                                    <td>
                                                       {i.pdc_status == 1? <label>Paid</label> :i.pdc_status == 2? <label>Pending</label>:<label>Returned</label>  }
                                                       {
                                                           i.pdc_status == 1 || i.pdc_status == 3? null :
                                                            <FormControl sx={{  width: '100%', mt:3 }}>
                                                                        <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Status</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-helper-label"
                                                                            id="demo-simple-select-helper"
                                                                            value={statusarray[15]}
                                                                            label="Age"
                                                                            onChange={(e) => statusChange(e,i.pdc_id)}
                                                                            size="small"
                                                                            
                                                                        >
                                                                    
                                                                        <MenuItem value="1">Paid</MenuItem>
                                                                        <MenuItem value="2">Pending</MenuItem>
                                                                        <MenuItem value="3">Returned</MenuItem>
                                                                        </Select>
                                                                </FormControl>

                                                       }
                                                    
                                                    </td>
                                                    <td>{i.pdc_cheque}</td>
                                                    <td>{i.pdc_cheque_date}</td>
                                                    <td>{i.pdc_post_date}</td>
                                                    <td>{i.pdc_date}</td>
                                                    <td>{i.ledger_name}</td>
                                                    <td>{i.pdc_bank_name}</td>
                                                    <td>{i.pdc_amount}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    
                                </tbody>
                            </Table>
                            <div className="table-footer">
                                <Row>
                                    <Col sm={3}>
                                        <Button variant="contained" >Save</Button>
                                    </Col>
                                    <Col sm={3}>
                                        <Button variant="contained" >Clear</Button>
                                    </Col>
                                    <Col sm={3}>
                                        <div className="textbox-padding spcl" style={{marginTop: 10}}>
                                            <FormControl sx={{width: '100%' }}>
                                                <TextField  id="outlined-basic" variant="outlined"  size="small"  />
                                            </FormControl>
                                        </div>
                                        <div className="textbox-padding spcl" style={{marginTop: 10}}>
                                            <FormControl sx={{width: '100%' }}>
                                                <TextField  id="outlined-basic" variant="outlined"  size="small"  />
                                            </FormControl>
                                        </div>
                                        <div className="textbox-padding spcl" style={{marginTop: 10}}>
                                            <FormControl sx={{width: '100%' }}>
                                                <TextField  id="outlined-basic" variant="outlined"  size="small"  />
                                            </FormControl>
                                        </div>
                                    </Col>
                                    <Col sm={3}>
                                        <div className="textbox-padding spcl" style={{marginTop: 10}}>
                                            <FormControl sx={{width: '100%' }}>
                                                <TextField  id="outlined-basic" variant="outlined"  size="small"  />
                                            </FormControl>
                                        </div>
                                        <div className="textbox-padding spcl" style={{marginTop: 10}}>
                                            <FormControl sx={{width: '100%' }}>
                                                <TextField  id="outlined-basic" variant="outlined"  size="small"  />
                                            </FormControl>
                                        </div>
                                        <div className="textbox-padding spcl" style={{marginTop: 10}}>
                                            <FormControl sx={{width: '100%' }}>
                                                <TextField  id="outlined-basic" variant="outlined"  size="small"  />
                                            </FormControl>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>  */}

                    {/* <Row style={{marginTop:50}}>
                        <Col sm={3} >
                                <div className="dash-numbers">
                                    <Row>
                                        <Col xs ={6}
                                            // <div className="dash-round" >
                                            // </div>
                                        </Col>
                                    </Row>
                                </div>
                        </Col>
                        <Col sm={3}>
                            <div className="dash-numbers">
                            <div className="dash-round">
                                    </div>
                            </div>
                        </Col>
                        <Col sm={3}>
                            <div className="dash-numbers">
                            <div className="dash-round">
                                    </div>
                            </div>
                            
                        </Col>
                        <Col sm={3}>
                            <div className="dash-numbers">
                            <div className="dash-round">
                                    </div>
                            </div>
                        </Col>
                    </Row> */}

                    {/* <Row>    
                        <Col sm={8}>
                            <div style={{marginTop:40}}>
                                <span><strong>GROWTH RATE OF CLIENTS</strong></span>
                                <div style={{marginTop: 30}}>
                                   
                                        <ResponsiveContainer width="100%" height={450}>
                                            <LineChart
                                            width={500}
                                            height={300}
                                            data={items}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}
                                            style={{backgroundColor: '#fff', padding: 10}}
                                            >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Line type="monotone" dataKey="pv" stroke="#8d13c2" activeDot={{ r: 7 }}/>
                                            <Line type="monotone" dataKey="uv" stroke="#ff0000" activeDot={{ r: 5 }} />
                                            </LineChart>
                                        </ResponsiveContainer>
                                </div>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div style={{marginTop:40}}>
                                <span><strong>Sales</strong></span>
                                <div style={{marginTop: 30, backgroundColor: '#fff', padding: 20}}>
                                    <Doughnut data={data1} style={{marginTop: 20}}/>
                                </div>
                            </div>
                        </Col>
                    </Row> */}

                    {/* <Row style={{marginTop:40}}>
                        <Col xs={12} md={7}>
                            <span><strong>EMPLOYEES STATISTICS</strong></span>
                            <div style={{marginTop: 30, overflowX: 'auto', padding: 15, backgroundColor: '#fff'}}>
                                <BarChart width={600} height={300} data={data}>
                                    <XAxis gridLine dataKey="name" stroke="#8884d8" />
                                    <YAxis gridLine={false} />
                                    <Tooltip wrapperStyle={{ width: 100, backgroundColor: '#d3d3d3' }} />
                                    <Legend width={100} wrapperStyle={{ top: 10, right: 20, backgroundColor: '#f5f5f5', border: '1px solid #d5d5d5', borderRadius: 3, lineHeight: '40px' }} />
                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                    <Bar dataKey="uv" fill="7e77fe" barSize={30} />
                                </BarChart>
                            </div>
                        </Col>
                    </Row> */}
            </div>
            </div>
        </Paper>
            <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12}>
                        <div style={{padding: 50}}>
                            <Row>
                                <Col xs={6} lg={3}>
                                    <p className="setting-para">Employees</p>
                                </Col>
                                <Col xs={6} lg={3}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch />} label="" />
                                    </FormGroup>
                                </Col>
                                <Col xs={6} lg={3}>
                                    <p className="setting-para">Customer</p>
                                </Col>
                                <Col xs={6} lg={3}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch />} label="" />
                                    </FormGroup>
                                </Col>
                                <Col xs={6} lg={3}>
                                    <p className="setting-para">Supplier</p>
                                </Col>
                                <Col xs={6} lg={3}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch />} label="" />
                                    </FormGroup>
                                </Col>
                                <Col xs={6} lg={3}>
                                    <p className="setting-para">Referrals</p>
                                </Col>
                                <Col xs={6} lg={3}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch />} label="" />
                                    </FormGroup>
                                </Col>
                                <Col xs={6} lg={3}>
                                    <p className="setting-para">Total Income</p>
                                </Col>
                                <Col xs={6} lg={3}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch />} label="" />
                                    </FormGroup>
                                </Col>
                                <Col xs={6} lg={3}>
                                    <p className="setting-para">Budget</p>
                                </Col>
                                <Col xs={6} lg={3}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch />} label="" />
                                    </FormGroup>
                                </Col>
                                <Col xs={6} lg={3}>
                                    <p className="setting-para">Spending</p>
                                </Col>
                                <Col xs={6} lg={3}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch />} label="" />
                                    </FormGroup>
                                </Col>
                                <Col xs={6} lg={3}>
                                    <p className="setting-para">orders</p>
                                </Col>
                                <Col xs={6} lg={3}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch />} label="" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} style={{marginTop:30}}>
                      <Button style={{float: 'right'}} variant="contained">Save</Button>
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Close</Button>
                    </Col>
                  </Row>
              </Modal.Body>
           </Modal>
        </div>
  );
}

export default Dashboard;
