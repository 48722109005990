import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Table , Row, Col, Modal, Container} from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";

function DeliveryNote(props) {
    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Delivery Note</p>
            </div>
            <Row>
                <Col sm={12}>
                    <Row>
                        <Col sm={6} lg={1} style={{marginTop:30}}>
                            <p className="label">Bill Type</p>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Bill Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        label="Age"
                                        size="small"
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    </Select>
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={1} style={{marginTop:30}}>
                            <p className="label">Code</p>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>

                    </Row>
                    <Row>
                        <Col sm={6} lg={1} style={{marginTop:30}}>
                            <p className="label">Doc Date</p>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                        size="small"
                                            label="Date"
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                </LocalizationProvider>
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={1} style={{marginTop:30}}>
                            <p className="label">Amount</p>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={1} style={{marginTop:30}}>
                            <p className="label">District</p>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">District</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        label="Age"
                                        size="small"
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    </Select>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} lg={1} style={{marginTop:30}}>
                            <p className="label">Doc No.</p>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={1} style={{marginTop:30}}>
                            <p className="label">Country</p>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Country</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        label="Age"
                                        size="small"
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    </Select>
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={1} style={{marginTop:30}}>
                            <p className="label">Mobile</p>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} lg={1} style={{marginTop:30}}>
                            <p className="label">Due Date</p>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                    size="small"
                                        label="Date"
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={1} style={{marginTop:30}}>
                            <p className="label">State</p>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">State</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        label="Age"
                                        size="small"
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    </Select>
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={1} style={{marginTop:30}}>
                            <p className="label">TRN</p>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row>
                </Col>
            </Row> 
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <Table bordered responsive>
                        <thead>
                            <tr>
                                <th>Sl No.</th>
                                <th>Code</th>
                                <th>Product Name</th>
                                <th>Unit</th>
                                <th>Quality</th>
                                <th>Price</th>
                                <th>Amount</th>
                                <th>Disc %</th>
                                <th>Discount</th>
                                <th>Gross</th>
                                <th>Tax</th>
                                <th>Total</th>
                                <th>Narration</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>test</td>
                                <td>test</td>
                                <td>test</td>
                                <td><input autocomplete="off"  type="number" /></td>
                                <td><input autocomplete="off"  type="text" /></td>
                                <td><input autocomplete="off"  type="number" /></td>
                                <td><input autocomplete="off"  type="number" /></td>
                                <td><input autocomplete="off"  type="number" /></td>
                                <td><input autocomplete="off"  type="number" /></td>
                                <td><input autocomplete="off"  type="number" /></td>
                                <td><input autocomplete="off"  type="text" /></td>
                                <td><input autocomplete="off"  type="text" /></td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <div style={{marginTop: 30}}>
                <Row style={{marginTop: 20}}>
                    <Col xs={12} md={6}>
                        <TextareaAutosize 
                            minRows={3}
                            placeholder="Narration"
                            style={{background: 'transparent', width: '100%'}}
                        />
                    </Col>
                </Row>
                
                <Row style={{marginTop: 20}}>
                    <Col xs={12} >
                        <ul className="list-inline">
                            <li><Button variant="outlined"><IconName.BsDownload style={{marginRight: 10}}/>Save</Button></li>
                            <li><Button variant="outlined"><GI.GiBinoculars style={{marginRight: 10}}/>Find</Button></li>
                            <li><Button variant="outlined"><BI.BiPrinter style={{marginRight: 10}}/>Print</Button></li>
                            <li><Button variant="outlined"><RI.RiDeleteBin6Line style={{marginRight: 10}}/>Delete</Button></li>
                            <li><Button variant="outlined"><RI.RiCloseFill style={{marginRight: 10}}/>Close</Button></li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <Row style={{marginTop: 30}}>
                <Col xs={12} md={6} lg={4}>
                    <Table bordered responsive>
                        <tbody>
                            <tr>
                                <td>Amount</td>
                                <td>0.00</td>
                            </tr>
                            <tr>
                                <td>Item Discount</td>
                                <td>0.00</td>
                            </tr>
                            <tr>
                                <td>Gross</td>
                                <td>0.00</td>
                            </tr>
                            <tr>
                                <td>Tax</td>
                                <td>0.00</td>
                            </tr>
                            <tr>
                                <td>Sub Total</td>
                                <td>0.00</td>
                            </tr>
                            <tr>
                                <td>Disc %</td>
                                <td>0.00</td>
                            </tr>
                            <tr>
                                <td>Discount</td>
                                <td>0.00</td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td>0.00</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col xs={12} md={6} lg={4}>
                    <Table bordered responsive>
                        <thead>
                            <tr>
                                <th>Sl No.</th>
                                <th>Footer Name</th>
                                <th>Amount Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
          </Paper> 
      </div>
    )
}
export default DeliveryNote;