
import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
import Loader from '../../loader/loader'

const columns = [
    { id: '1', label: 'Delivery Boy', minWidth: 50,span: false },
    { id: '2', label: 'Invoice', minWidth: 50,span: false },
    { id: '3', label: 'Date', minWidth: 50,span: false },
    { id: '4', label: 'Customer', minWidth: 50,span: false },
    { id: '5', label: 'Mobile', minWidth: 50,span: false },   
    { id: '7', label: 'Building', minWidth: 50,span: false },
    { id: '8', label: 'street', minWidth: 50,span: false },   
    { id: '9', label: 'Zone', minWidth: 50,span: false },
    { id: '10', label: 'Area', minWidth: 50,span: false },
    { id: '11', label: 'Total', minWidth: 50,span: false },  
  ];

  function SalesExecutive(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [userId, setUserid] = useState("")
    const [source, setSource] = React.useState([]);
    const [total, setTotal] = React.useState()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [date, setDate] = React.useState(new Date);
    const [loader, setLoader] = React.useState(false);
    const [dateFrom, setDatefrom] = React.useState(new Date);
    const [dateTo, setDateTo] = React.useState(new Date);
    const [starttime, setStarttime] = React.useState(new Date);
    const [endtime, setEndtime] = React.useState(new Date);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const startChange = (newValue) => {
        setStarttime(newValue);
      };
    
      const endChange = (newValue) => {
        setEndtime(newValue);
      };
    
    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        setLoader(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var company = JSON.parse(localStorage.getItem("company"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var data = {
            'companyid' : company,
            "branchid" : branch,
            'type' :  userdata.user_type,
            'sales_user_id' : userdata.user_id,
            "salesorder_fromdate" : dateFrom.toISOString().split('T')[0],
            "salesorder_todate" : dateTo.toISOString().split('T')[0],
            "salesorder_fromtime": new Date(starttime.toISOString()).toLocaleTimeString('en',
                              { timeStyle: 'short', hour12: false, }),
            "salesorder_totime": new Date(endtime.toISOString()).toLocaleTimeString('en',
                            { timeStyle: 'short', hour12: false, }),
        }
        console.log('data', data)
        fetch(global.url + 'salesOrderReport', {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) =>{
            console.log('res executive', responseJson)
            if(responseJson.status == 'false'){
                setLoader(false)
                setSource(responseJson.data)
            }else{
                setLoader(false)
                setSource([])
            }

        })
        .catch((error)=>{
            console.log(error)
        })
    }
    const filter = () => {
        console.log('click')
        getData()
    }
      return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <div style={{margin: 20,}}>
                    <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Sales Executive Report</p>
                    <Row>
                        <Col xs={12} md={2} style={{marginTop: 25}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="From"
                                    value={dateFrom}
                                    onChange={(newValue) => {
                                    setDatefrom(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 25}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="To"
                                    value={dateTo}
                                    onChange={(newValue) => {
                                        setDateTo(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 25}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <TimePicker
                                  label="Start Time Shift"
                                  value={starttime}
                                  onChange={startChange}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                          </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 25}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                                label="End Time Shift"
                                value={endtime}
                                onChange={endChange}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 25}}>
                            <Button onClick={filter} style={{width: '50%'}} variant="contained">Filter</Button>
                        </Col>
                    </Row>
                </div>
                <div style={{margin: 20,}}>
                    <TableContainer sx={{ maxHeight: 440, marginTop: 2 }}>
                    {loader ? <Loader /> :
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                        colSpan={column.span == true ? 2: 1}
                                    >
                                        {column.label} 
                                    </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                            source
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.code}>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.user_name}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.salesorder_invoice}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.salesorder_date}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.customer_name}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.customer_mobile}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.customer_building}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.customer_street}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.customer_zone}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.customer_area}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.salesorder_total}
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
  }
                    </TableContainer>
                    <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={source.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </Paper> 
        </div>
      );
  }

  export default SalesExecutive;
