import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Form  } from "react-bootstrap";
import Map from '../../google-map/map';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import WizardFormFirstPage from './FirstPage'
import WizardFormSecondPage from './SecondPage';
import Loader from '../../loader/loader';

const columns = [
    { id: '1', label: 'Code', minWidth: 50, span: false },
    { id: '2', label: 'Account', minWidth: 50, span: false },
    { id: '4', label: 'Address 1', minWidth: 50,span: false },
    { id: '8', label: 'Address 2', minWidth: 50,span: false },
    { id: '9', label: 'Address 3', minWidth: 50,span: false },
    { id: '13', label: 'GSTIN', minWidth: 50,span: false },
    { id: '14', label: 'Phone/Mob', minWidth: 50,span: false },
    { id: '15', label: 'Whatsapp', minWidth: 50,span: false },
    { id: '16', label: 'Email', minWidth: 50,span: false },
    { id: '17', label: 'Website', minWidth: 50,span: false },
    { id: '18', label: 'Opening Balance', minWidth: 50,span: false },
    { id: '19', label: 'Blocked', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];
    
  const rows = [
    {
      company: 'Lorem Ipsum',

    },
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
  ];

function State(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")

  const [toggle,  setToggle] = useState(false)
  const [modal, setModal] = useState(false);
  const [editMode, setEditmode] = useState(false);
  const [age, setAge] = React.useState('');
  const [value, setValue] = React.useState('1');

  const [ledgerId, setLedgerid] = React.useState('');
  const [employeeId, setEmployeeId] = React.useState('');
  const [ledgerList, setLedgerList] = React.useState('');
  const [ledger, setLedger] = React.useState('');
  const [destinationList, setDestinationList] = React.useState('');
  const [destination, setDestination] = React.useState('');
  const [branch, setBranch] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [form, setForm] = useState({
    empCode: "",
    empAccount: "",
    empLedger: "",
      address1: "",
      address2: "",
      address3: "",
      gstin: "",
      mobile: "",
      email: "",
      website: "",
      whatsapp: "",
      openBalnce: 0
    })
  const [blocked, setBlocked] = useState(0)
  const [enableBill, setEnablebill] = useState(0)
  const [count, setCount] = useState(1)

  const [accountId, setAccountid] = useState(0)

  const [source, setSource] = React.useState([]);
  const [userId, setUserid] = React.useState('');
  const [loader, setLoader] = React.useState(false);
  const [state, setState] = React.useState("");
  const [stateList, setStatelist] = React.useState([]);
  const [district, setDistrict] = React.useState("");
  const [districtList, setDistrictlist] = React.useState([]);
  const [country, setCountry] = React.useState('');
  const [countryList, setCountryList] = React.useState([]);
  const [latitude, setLatitude] = React.useState(25.3548);
  const [longitude, setLongitude] = React.useState(51.1839);
  const [decr, setDecr] = React.useState(0);
  const [date, setDate] = React.useState(null);
  const [billNo, setBillno] = React.useState("");
  const [recievable, setRecievable] = React.useState("");
  const [payable, setPayable] = React.useState("");
  const [tableRow, setTableRow] = React.useState([]);

  const [selected, setSelected] = React.useState([]);
  const [branchlist, setBranchlist] = React.useState([]);
  
    
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const enableBillchange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
        setEnablebill(1)
    }
    else {
        setEnablebill(0)
    }

  }
  const radioChange = (event) => {
    console.log(event.target.value, "event.target.value")
    if(event.target.value == "credit") {
       setDecr(1)
    }
    else {
      setDecr(0)
    }
  }
  useEffect(() => {
    getCountry()
    getLedger()
    getBranch()
    getDesignation()
  }, []);

  useEffect(() => {
    getData()
  }, []);

  const getBranch = () => {
    fetch(global.url + "multipleBranch", {
    method: "POST",
    headers: {
        'Content-Type': 'application/json',
    },
  })
  .then((response)=> response.json())
  .then((responseJson)=>{
    console.log("branch", responseJson)
      if(responseJson.error == false){
          setBranchlist(responseJson.data)
      }
  })
  .catch((error)=>{
      console.log(error)
  })
}

  const getDesignation = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUserid(userdata.user_id)
    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewDestination", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("dest resp", responseJson)
        if(responseJson.status == 'true'){
          setDestinationList([])
        }
        else{
          setDestinationList(responseJson.data)
        }
          
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const updateForm = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }
  const countryChange = (event) => {
    setCountry(event.target.value);
    getState(event.target.value)
  };
  const ledgerChange = (event) => {
    setLedger(event.target.value);
    
  };
  const destinationChange = (event) => {
    setDestination(event.target.value);
  }
  const stateChange = (event) => {
    setState(event.target.value);
    getDistrict(event.target.value)
  };

  const districtChange = (event) => {
    setDistrict(event.target.value);
  };
 

  const getDistrict = (id) => {
    var data = {
      "countryid": id
    }
    fetch(global.url + "viewDistrict", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      console.log(responseJson)
      if(responseJson.status == "false") {
        setDistrictlist(responseJson.data)
      }
      
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const getState = (id) => {
    var data = {
      "countryid": id
    }
    fetch(global.url + "viewState", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        if(responseJson.status == "false") {
          setStatelist(responseJson.data)
        }
    })
    .catch((error)=>{
        console.log(error)
    })
  }


const getCountry = () => {
  fetch(global.url + "viewCountry", {
      method: "GET",
      headers: {
          'Content-Type': 'application/json',
      },
  })
  .then((response)=> response.json())
  .then((responseJson)=>{
    console.log("country", responseJson)
    if(responseJson.status == "false") {
      setCountryList(responseJson.data)
    }
  })
  .catch((error)=>{
      console.log(error)
  })
}


  const getData = () => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUserid(userdata.user_id)
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewEmployee", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("response", responseJson)
        setLoader(false)
        if(responseJson.status == "false"){
         
          if(responseJson.status != "true") {
            setSource(responseJson.data)
          }
        }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const onAdd = () => {
    setModal(true)
    const newValues = { ...form }
    newValues['empCode']= ""
    newValues['empAccount']= ""
    newValues['empLedger']= ""
    newValues['address1']= ""
    newValues['address2']= ""
    newValues['address3']= ""
    newValues['gstin']= ""
    newValues['mobile']= ""
    newValues['email']= ""
    newValues['website']= ""
    newValues['whatsapp']= ""
    newValues['openBalnce']= 0
    setForm(newValues)
    setEmployeeId("")
    setEditmode(false)
    setCountry("")
    setState("")
    setDistrict("")
    setBlocked(0)
    setEnablebill(0)
    setDecr(0)
    setLatitude(25.3548)
    setLongitude(51.1839)
   
}

    const onSave = () => {
          var data = {
            "employee_id": employeeId,
            "employee_userid": userId,
            "employee_code": form.empCode,
            "employee_name": form.empAccount,
            "ledger_group" : ledger,
            "employee_address1": form.address1,
            "employee_address2": form.address2,
            "employee_address3": form.address3,
            "employee_country_fk": country,
            "employee_state_fk": state,
            "employee_district_fk": district,
            "employee_gstin": form.gstin,
            "employee_mob": form.mobile,
            "employee_email": form.email,
            "employee_web": form.website,
            "employee_whatsap": form.whatsapp,
            "employee_openbalance": form.openBalnce,
            "employee_decr": decr,
            "employee_blocked": blocked,
            "employee_destination_fk": destination,
            "employee_laltitude" : latitude,
            "employee_longitude" : longitude,
            "employee_branchid" : userbranchid,
            "employee_companyid" : usercompanyid,
            "employee_allowedbranch": selected
          }
          console.log("data", data)

          fetch(global.url + "updateEmployee", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
              console.log("responsejsonemployee", responseJson);
              if(responseJson.status == 'false') {
                getData()
                const newValues = { ...form }
                alert(responseJson.message)
                setModal(false)
              }
              if(responseJson.status == "true") {
                alert(responseJson.message)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }

const onhide = () => {
  setEditmode(false)
  setModal(false)
  setCount(1)
  setBranch([])
}

const checkChange = (e) => {
  let isChecked = e.target.checked;
  if(isChecked == true) {
      setBlocked(1)
  }
  else {
      setBlocked(0)
  }
}

const onLedgersave = () => {
  var data = {
    "ledger_id": ledgerId,
    "ledger_user_id": userId,
    "ledger_code": form.ledgerCode,
    "ledger_account": form.ledgerAccount,
    "ledger_name": form.ledgerName,
    "ledger_address1": form.address1,
    "ledger_address2": form.address2,
    "ledger_address3": form.address3,
    "ledger_country": country,
    "ledger_state": state,
    "ledger_district": district,
    "ledger_gstin": form.gstin,
    "ledger_mobile": form.mobile,
    "ledger_email": form.email,
    "ledger_website": form.website,
    "ledger_whatsapp": form.whatsapp,
    "ledger_open_balance": form.openBalnce,
    "ledger_credit_limit": form.creditLimit,
    "ledger_isbillwise_entry": enableBill,
    "ledger_decr": decr,
    "ledger_isactive": blocked,
  }
  console.log("data", data)

  fetch(global.url + "updateLedger", {
    method: "POST",
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      console.log("responsejson", responseJson);
      if(responseJson.status == "false") {
        alert(responseJson.data)
        getData()
      }
      if(responseJson.status == "true") {
        alert(responseJson.message)
      }
  })
  .catch((error)=>{
      console.log(error)
  })
}

const deleteClick = (id) =>  {
  var data = {
    "employee_id": id,
  }
  fetch(global.url + "deleteEmployee", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
  })
  .then((response)=> response.json())
  .then((responseJson)=>{
      alert(responseJson.data)
      getData()
  })
  .catch((error)=>{
      console.log(error)
  })
}

const getLedger = () => {
  var userdata = JSON.parse(localStorage.getItem("user"))
  var company = JSON.parse(localStorage.getItem("company"))
  setUserid(userdata.user_id)
  var data = {
    "companyid":  company,
    "type":  userdata.user_type
  }
  fetch(global.url + "viewGroup", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        if(responseJson.status == "false") {
          setLedgerList(responseJson.data)
        }
        else{
          setLedgerList([])
        }
    })
    .catch((error)=>{
        console.log(error)
    })
}

const mapValues = (lat, long) => {
  setLatitude(lat)
  setLongitude(long)
}

  const editClick = (
        empid, 
        // empuserid,
        empcode,
        empname,
        empledger,
        empaddress1,
        empaddress2,
        empaddress3,
        empgstin,
        empcntid,
        empstid,
        empdstin,
        empmobile,
        empemail,
        empwebsite,
        empwts,
        empopening,
        empdecr,
        empblocked,
        empdesti,
        emplat,
        emplng
      ) => {
        setModal(true)
        const newValues = { ...form }
        setEmployeeId(empid)
        setCountry(empcntid)
        setState(empstid)
        setDistrict(empdstin)
        setDestination(empdesti)
        setBlocked(empblocked)
        setDecr(empdecr)
        setLedger(empledger)
        newValues['empCode']= empcode
        newValues['empAccount']= empname
        newValues['address1']= empaddress1
        newValues['address2']= empaddress2
        newValues['address3']= empaddress3
        newValues['gstin']= empgstin
        newValues['mobile']= empmobile
        newValues['whatsapp']= empwts
        newValues['email']= empemail
        newValues['website']= empwebsite
        newValues['openBalnce']= empopening
        setForm(newValues)    
        setLatitude(parseFloat(emplat))
        setLongitude(parseFloat(emplng)) 
        getSelectedbranch(empid) 
    }

    const getSelectedbranch =(id) => {
        var data = {
          "id": id,
          "type": "employee"
        }
        fetch(global.url + "multipleBranchSelect", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then((responseJson) => {
          if(responseJson.error == false) {
            setSelected(responseJson.data)
          }
        })
        .catch((error) => {
            console.log(error)
        })
  }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Employee List</p>
               <Button variant="contained" onClick={onAdd}>Add</Button>
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.employee_code}
                              </TableCell>
                              <TableCell >
                                  {row.employee_name}
                              </TableCell>
                              <TableCell >
                                  {row.employee_address1}
                              </TableCell>
                              <TableCell >
                                  {row.employee_address2}
                              </TableCell>
                              <TableCell >
                                  {row.employee_address3}
                              </TableCell>
                              <TableCell >
                                  {row.employee_gstin}
                              </TableCell>
                              <TableCell >
                                  {row.employee_mob}
                              </TableCell>
                              <TableCell >
                                  {row.employee_whatsap}
                              </TableCell>
                              <TableCell >
                                  {row.employee_email}
                              </TableCell>
                              <TableCell >
                                  {row.employee_web}
                              </TableCell>
                              <TableCell >
                                  {row.employee_openbalance}
                              </TableCell>
                              <TableCell >
                                  {row.employee_blocked}
                              </TableCell>
                              <TableCell >
                                  <a href="javascript:void(0)" onClick={() => 
                                    editClick(
                                        row.employee_id, 
                                        // row.employee_userid, 
                                        row.employee_code,
                                        row.employee_name, 
                                        row.group_id, 
                                        row.employee_address1, 
                                        row.employee_address2,
                                        row.employee_address3, 
                                        row.employee_gstin,
                                        row.employee_country_fk,
                                        row.employee_state_fk,
                                        row.employee_district_fk,
                                        row.employee_mob,
                                        row.employee_email,
                                        row.employee_web,
                                        row.employee_whatsap,
                                        row.employee_openbalance,
                                        row.employee_decr,
                                        row.employee_blocked,
                                        row.employee_destination_fk,
                                        row.employee_laltitude,
                                        row.employee_longitude
                                    )}>
                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                    </a>
                              </TableCell>
                              <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.employee_id)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={onhide}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Employee Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                      <form
                        className="col-12 form"
                        onSubmit={() =>
                          alert(
                            `Submitted Email: ${form.email} Name: ${form.name} Password: ${form.password}`
                          )
                        }
                      >
                        {count === 1 ? (
                          <WizardFormFirstPage 
                                form={form} 
                                blocked = {blocked} 
                                countryChange = {countryChange}
                                ledgerChange = {ledgerChange}
                                destinationChange = {destinationChange}
                                stateChange ={stateChange}
                                districtChange ={districtChange}
                                state ={state}
                                stateList= {stateList}
                                district = {district}
                                districtList= {districtList}
                                country ={country}
                                countryList ={countryList}
                                ledger = {ledger}
                                ledgerList = {ledgerList}
                                destinationList = {destinationList}
                                updateform = {updateForm} 
                                checkchange = {checkChange} 
                                decr = {decr}
                                radioChange = {radioChange}
                                latitude = {latitude}
                                setLatitude = {setLatitude}
                                longitude = {longitude}
                                setLongitude = {setLongitude}
                                mapValues = {mapValues}

                                selected = {selected}
                                setSelected = {setSelected}
                                branchlist= {branchlist}
                          />
                        ) : null}
                        {count === 2 ? (
                          <WizardFormSecondPage 
                            branch = {branch}
                            setBranch = {setBranch}
                           />
                        ) : null}
                      </form>
                      <Col xs ={12} style={{marginTop:30}}>
                        <Button onClick={onSave}  style={{float: 'right'}} variant="contained">Next</Button>
                    </Col>
                </Box>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default State;