import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Button, Form  } from "react-bootstrap";
import { BsPersonFill, BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";
import './footer.css'

function Footer() {

    const [username, setUsername] = React.useState("")
    const [branch, setBranch] = React.useState("")

    useEffect(() => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        // console.log('userdata',userdata )
        setBranch(userdata.branch_name)
        setUsername(userdata.user_name)
    }, [])

    const formatDate = (datestring) => {
        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
          }
          
          var date = new Date(datestring)
          var month = pad2(date.getMonth()+1);//months (0-11)
          var day = pad2(date.getDate());//day (1-31)
          var year= date.getFullYear();
    
          const timeString12hr = new Date(datestring)
          .toLocaleTimeString('en-US',
              {timeZone:'Asia/Kuwait',hour12:true,hour:'numeric',minute:'numeric'}
          );
        //   console.log("timeString12hr", timeString12hr)
    
          var formattedDate = timeString12hr;
          return formattedDate; //03/02/2021
    }

  return (
      <div className="footer-content">
          <div style={{background: '#efefef', width: '100%', padding: 20}}>
          <ul className="footer-list">
                <li>
                    User : {username} 
                </li>
                <li style={{color: '#8a4cff'}}>
                    <a href="https://nasscript.com/" target="_blank" style={{textDecoration: 'none'}}>
                        NASScript Software Innovations
                    </a>
                </li>
                <li>
                    Name of financial year:2022
                </li>
                <li>
                    Financial Year Period 01-JAN-2022 To 31-DEC-2022
                </li>
                <li>
                    ©2021 NASScript Software Innovations W.L.L
                </li>
                <li style={{color: 'orangered'}}>
                    Branch: {branch}
                </li>
            </ul>
          </div>
    </div>
  );
}

export default Footer;
