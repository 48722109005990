import React, {Component, useEffect, useState} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Container, Row, Col, Button, Form  } from "react-bootstrap";
import { BsPersonFill, BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";
import * as FaIcons from "react-icons/fa";
import { BiUser, BiNotepad, BiHomeAlt} from "react-icons/bi";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { MdPayment } from "react-icons/md";
import { BsReceiptCutoff, BsDot } from "react-icons/bs";
import Tooltip from '@mui/material/Tooltip';
import { useLocation } from 'react-router-dom';
import { Route, useHistory } from 'react-router-dom';
import {RiCloseFill} from "react-icons/ri";
import './sidebar.css';
import './rightsidebar.css';

function Rightsidebar(props) {
    const [toggle,  setToggle] = useState(false)
    const location = useLocation();
    let history = useHistory();

    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
	const [userId, setUserid] = React.useState("");
    const [show, setShow] =React.useState(false);

    const showSidebar = (childData) => {
        setToggle(childData)
    };

    const logout = () => {
        logoutclear()
    }

    const logoutclear = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        console.log("userdata", userdata)
        var data = {
            "userid" : userdata.user_id,
        }
        console.log("user_id", data)
        fetch(global.url + "deleteDraftonLogout", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("logout clear", responseJson)
            localStorage.removeItem("isAuthenticated")
        localStorage.removeItem("branch")
        localStorage.removeItem("company")
        localStorage.removeItem("user")
        localStorage.removeItem("pos")
       
        history.push('/')
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    useEffect(() => {
		var userdata = JSON.parse(localStorage.getItem("user"))
		var branch = JSON.parse(localStorage.getItem("branch"))
		var company = JSON.parse(localStorage.getItem("company"))
		setUsercompanyid(company)
		setUserbranchid(branch)
		setUsertype(userdata.user_type)
		setUserid(userdata.user_id)
		
	}, []);

    // Window.onload = function() {
    //     var hidediv=document.getElementById('div1');
    //     document.onclick=function(div){
    //         if(div.target.id !='div1'){
    //             hidediv.style.display=='none';
    //         }
    //     };
    // };


  return (
      <div>        
<div className={toggle == true? "rightToggle" : ""} style={{marginLeft: 'auto'}} to="#">
            <FaIcons.FaBars className="rightsidebarIcon"  onClick={() =>showSidebar(!toggle)} />
            <div className={toggle == true? "rightsidbar": ""}>
                    {
                        usertype == 1 || usertype == 2 || usertype == 4 ? 
                        <ul className={toggle == true?  "sidebarlist" : "header-list"}>
                            <li>
                                <a href="/transaction/Salesorder/add" className={'/dashboard' == location.pathname ? "selected" : ""}>
                                    <Tooltip title="Sales Order" arrow>
                                        <img src={require("../../assets/images/icons/sales-order.svg").default} style={{width: 50, height: 50}} />
                                        
                                    </Tooltip>
                                </a>
                            </li>
                            <li>
                                <a href="/Sales/SalesApproval" className={'/dashboard' == location.pathname ? "selected" : ""}>
                                    <Tooltip title="Sales Approval" arrow>
                                        <img src={require("../../assets/images/icons/order-report.svg").default} style={{width: 50, height: 50}} />  
                                    </Tooltip>
                                </a>
                            </li>
                            <li>
                                <a href="/StockReport/CurrentStockReport" className={'/dashboard' == location.pathname ? "selected" : ""}>
                                    <Tooltip title="Stock Report" arrow>
                                        <img src={require("../../assets/images/icons/stock-report.svg").default} style={{width: 50, height: 50}} />
                                        
                                    </Tooltip>
                                </a>
                            </li>
                            <li>
                                <a href="/transaction/Salesexecutive" className={'/dashboard' == location.pathname ? "selected" : ""}>
                                    <Tooltip title="Sales Executive" arrow>
                                        <img src={require("../../assets/images/icons/salesexecutive.svg").default} style={{width: 60, height: 60}} />
                                        
                                    </Tooltip>
                                </a>
                            </li>
                            <li>
                                <a href="/transaction/Salesreplace" className={'/dashboard' == location.pathname ? "selected" : ""}>
                                    <Tooltip title="Sales Replacement" arrow>
                                        <img src={require("../../assets/images/icons/Replacement.svg").default} style={{width: 50, height: 50}} />
                                        
                                    </Tooltip>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" onClick={logout}>
                                    <Tooltip title="Client Login" arrow>
                                        <img src={require("../../assets/images/icons/logout.svg").default} style={{width: 50, height: 50, marginTop:8}} />
                                    </Tooltip>
                                </a>
                            </li>
                            
                        </ul> :
                        usertype == 3 ? 
                        <ul className={toggle == true?  "sidebarlist" : "header-list"}>
                            <li>
                                <a href="/Reports/deliveryboy" className={'/dashboard' == location.pathname ? "selected" : ""}>
                                    <Tooltip title="Sales Executive" arrow>
                                        <img src={require("../../assets/images/icons/deliveryboy.svg").default} style={{width: 60, height: 60}} />
                                        
                                    </Tooltip>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" onClick={logout}>
                                    <Tooltip title="Client Login" arrow>
                                        <img src={require("../../assets/images/icons/logout.svg").default} style={{width: 45, height: 45, marginTop:1}} />
                                    </Tooltip>
                                </a>
                            </li>
                            
                        </ul>
                        :<ul className={toggle == true?  "sidebarlist" : "header-list"}>
                        
                                <li>
                                    <a href="/dashboard" className={'/dashboard' == location.pathname ? "selected" : ""}>
                                        <Tooltip title="Dashboard" arrow>
                                            <img src={require("../../assets/images/icons/home.svg").default} style={{width: 50, height: 50}} />
                                        </Tooltip>
                                    </a>
                                </li>
                                {/* <li>
                                    
                                    <a href="/reports/paymentreport" className={'/reports/paymentreport' == location.pathname ? "selected" : ""}>
                                        <Tooltip title="Payment Report" arrow>
                                            <img src={require("../../assets/images/icons/Payments.svg").default} style={{width: 50, height: 50}} />
                                        </Tooltip>
                                    </a>
                                </li> */}
                                <li>
                                    <a href="/StockReport/CurrentStockReport" className={'/StockReport/CurrentStockReport' == location.pathname ? "selected" : ""}>
                                        <Tooltip title="Stock Report" arrow>
                                            <img src={require("../../assets/images/icons/stock-report.svg").default} style={{width: 50, height: 50}} />
                                        </Tooltip>
                                    </a>
                                </li>
                                <li>
                                    <a href="/transaction/Salesorder/add" className={'/transaction/Salesorder/add' == location.pathname ? "selected" : ""}>
                                        <Tooltip title="Sales Order" arrow>
                                            <img src={require("../../assets/images/icons/sales-order.svg").default} style={{width: 50, height: 50}} />
                                            
                                        </Tooltip>
                                    </a>
                                </li>
                                <li>
                                    <a href="/Sales/SalesApproval" className={'/Sales/SalesApproval' == location.pathname ? "selected" : ""}>
                                        <Tooltip title="Employees" arrow>
                                            <img src={require("../../assets/images/icons/salesapprovel.svg").default} style={{width: 50, height: 50}} />
                                        </Tooltip>
                                    </a>
                                </li>
                                {/* <li>
                                    <a href="/reports/paymentreport" className={'/reports/paymentreport' == location.pathname ? "selected" : ""}>
                                        <Tooltip title="Payment Report" arrow>
                                            <BsReceiptCutoff style={{fontSize:36}}/>
                                        </Tooltip>
                                    </a>
                                </li> */}
{/*                                 
                            <li>

                                    <a href="/accounts/customer" className={'/accounts/customer' == location.pathname ? "selected" : ""}>
                                        <Tooltip title="Customers" arrow>
                                            <img src={require("../../assets/images/icons/Customer.svg").default} style={{width: 50, height: 50}} />
                                        </Tooltip>
                                    </a>
                                </li> */}
                                
                                <li>
                                    <a href="/transaction/Sales/add" className={'/transaction/Sales/add' == location.pathname ? "selected" : ""}>
                                        <Tooltip title="Sales" arrow>
                                            <img src={require("../../assets/images/icons/sales.svg").default} style={{width: 44, height: 44, marginTop: 5}} />
                                        </Tooltip>
                                    </a>
                                </li>
                                <li>
                                    <a href="/reports/salesreport" className={'/reports/salesreport' == location.pathname ? "selected" : ""} >
                                        <Tooltip title="Sales Report" arrow>
                                            <img src={require("../../assets/images/icons/SalesReport.svg").default} style={{width: 50, height: 50, marginTop: 5}} />
                                        </Tooltip>
                                    </a>
                                </li>
                                <li>
                                    <a href="/transaction/Purchase/add" className={'/transaction/Purchase' == location.pathname ? "selected" : ""}>
                                        <Tooltip title="Purchase" arrow>
                                            <img src={require("../../assets/images/icons/Purchase.svg").default} style={{width: 42, height: 42, marginTop: 3}} />
                                        </Tooltip>
                                    </a>
                                </li>
                                <li>
                                    <a href="/reports/purchasereport" className={'/reports/purchasereport' == location.pathname ? "selected" : ""}>
                                        <Tooltip title="Purchase Report" arrow>
                                            <img src={require("../../assets/images/icons/PurchaseReport.svg").default} style={{width: 75, height: 75, marginTop: 3}} />
                                        </Tooltip>
                                    </a>
                                </li>
                                <li>
                                    <a href="/Products/Products" className={'/Products/Products' == location.pathname ? "selected" : ""}>
                                        <Tooltip title="Product" arrow>
                                            <img src={require("../../assets/images/icons/product.svg").default} style={{width: 50, height: 50}} />
                                        </Tooltip>
                                    </a>
                                </li>
                                {/* <li>
                                    <a href="/accounts/account" className={'/accounts/account' == location.pathname ? "selected" : ""}>
                                        <Tooltip title="Ledger" arrow>
                                            <img src={require("../../assets/images/icons/ledger.svg").default} style={{width: 50, height: 50}} />
                                        </Tooltip>
                                    </a>
                                </li>
                                <li>
                                    <a href="/transaction/paymentvoucher/add" className={'/transaction/paymentvoucher/add' == location.pathname ? "selected" : ""}>
                                        <Tooltip title="Payment" arrow>
                                            <img src={require("../../assets/images/icons/Payment.svg").default} style={{width: 50, height: 50}} />
                                        </Tooltip>
                                    </a>
                                </li>
                                <li>
                                    <a href="/transaction/receiptvoucher/add" className={'/transaction/receiptvoucher/add' == location.pathname ? "selected" : ""}>
                                        <Tooltip title="Receipt" arrow>
                                            <img src={require("../../assets/images/icons/Receipt.svg").default} style={{width: 50, height: 50}} />
                                        </Tooltip>
                                    </a>
                                </li> */}
                                {/* <li>
                                    <Tooltip title="Shortcut" arrow>
                                        <img src={require("../../assets/images/icons/ShortKeys.svg").default} style={{width: 50, height: 50, marginTop:4}} />
                                    </Tooltip>
                                </li> */}
                                {/* onClick={()=>setShow(true)} */}
                                {/* <li>
                                    <Tooltip  title="Notification" arrow>
                                        <img src={require("../../assets/images/icons/Notification.svg").default} style={{width: 55, height: 55, marginTop:4}} />
                                    </Tooltip>
                                   
                                </li> */}
                                <li>
                                    <a href="javascript:void(0)" onClick={logout}>
                                        <Tooltip title="Client Login" arrow>
                                            <img src={require("../../assets/images/icons/logout.svg").default} style={{width: 40, height: 40, marginTop:8}} />
                                        </Tooltip>
                                    </a>
                                </li>
                                {/* <li>
                                    <Tooltip title="Theme" arrow>
                                        <img src={require("../../assets/images/icons/theme.svg").default} style={{width: 40, height: 40, marginTop:8}} />
                                    </Tooltip>
                                </li> */}
                            </ul>
                             

                    }
                
            </div>
            {show ?
            <div className='divnot'>
                  <div style={{display: 'flex', justifyContent:'space-between'}}>
                      <div style={{fontWeight: 500}}>Notifications</div>
                       
                  </div>  
                  <hr />  
                    
                    <Row>
                        <Col xs={12} md={2} lg={2}>
                        <BsDot size={40} style={{color:'#04be5b'}}/> 
                        </Col>
                        <Col xs={12} md={10} lg={10}>
                        <p style={{marginTop:5}}>message1</p>
                        </Col>
                    </Row>    
                    <Row>
                        <Col xs={12} md={2} lg={2}>
                        <BsDot size={40} style={{color:'#04be5b'}}/> 
                        </Col>
                        <Col xs={12} md={10} lg={10}>
                        <p style={{marginTop:5}}>message1</p>
                        </Col>
                    </Row>    
                     
                    <Row>
                        <Col xs={12} md={2} lg={2}>
                        <BsDot size={40} style={{color:'#04be5b'}}/> 
                        </Col>
                        <Col xs={12} md={10} lg={10}>
                        <p style={{marginTop:5}}>message1</p>
                        </Col>
                    </Row>    
                    <Row>
                        <Col xs={12} md={2} lg={2}>
                        <BsDot size={40} style={{color:'#04be5b'}}/> 
                        </Col>
                        <Col xs={12} md={10} lg={10}>
                        <p style={{marginTop:5}}>message1</p>
                        </Col>
                    </Row>    
                    <Row>
                        <Col xs={12} md={2} lg={2}>
                        <BsDot size={40} style={{color:'#04be5b'}}/> 
                        </Col>
                        <Col xs={12} md={10} lg={10}>
                        <p style={{marginTop:5}}>message1</p>
                        </Col>
                    </Row>    
                     
                    <Row>
                        <Col xs={12} md={2} lg={2}>
                        <BsDot size={40} style={{color:'#04be5b'}}/> 
                        </Col>
                        <Col xs={12} md={10} lg={10}>
                        <p style={{marginTop:5}}>message1</p>
                        </Col>
                    </Row>    
                         
                   
                 
                             
            </div>:null}
           
        </div>
        <OutsideClickHandler  onOutsideClick={() =>{
               setShow(false);    
              }}>
          </OutsideClickHandler>
      </div>
    
  );
}

export default Rightsidebar;
