import '../Tax-Settings/TaxSettings.css';
import React, {Component, useEffect, useState, useRef} from 'react';
import Paper from '@mui/material/Paper';
import { Row, Col, Table, Modal } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { HiSave } from "react-icons/hi";
import { AiOutlineClose, AiFillMinusCircle } from "react-icons/ai";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiFillPrinter } from "react-icons/ai";
import { MdFindReplace } from "react-icons/md";
import { GoSettings } from "react-icons/go";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import '../Retail-Sale/RetailSale.css'



const columns = [
    { id: '1', label: 'SI No.', minWidth: 50,span: false },
    { id: '2', label: 'Tax Name', minWidth: 50,span: false },
    { id: '3', label: 'Tax %', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];
    
  const rows = [
    {
      company: 'Lorem Ipsum',

    },
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
  ];

function Retailsale(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [age, setAge] = React.useState('');
    const [date, setDate] = React.useState(new Date);
    const [tableRow,  setTableRow] = React.useState([{key:"",product_batch:"", code: "", productname: "", pricelevel: "", rsd_qty: "", unit: "", price: "", calcprice: "", discount: "", rsd_total_amount: 0 }])
    const [textArray, setTextarray] = React.useState([{key: "", value: ""}])
    const [selectedIndex, setSelectedindex] = React.useState("")
    const [code, setCode] = useState("");
    const [productlist, setProductlist] = React.useState([]);
    const [productname, setProductname] = useState("");
    const [invoiceno, setInvoiceno] = useState("")
    const [customer, setCustomer] = useState("")
    const [address, setAddress] = useState("")
    const [mobile, setMobile] = useState("")
    const [modal, setModal] = useState(false)

    const [userId, setUserid] = React.useState("");

    const [cash, setCash] = useState("")
    const [bank, setBank] = useState("")

    const [cashamount, setCashamount] = useState("")
    const [bankamount, setBankamount] = useState("")

    const [paymode, setPaymode] = useState("")
    const [round, setRound] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [gross, setGross] = useState(0)
    const [grandTotal, setGrandtotal] = useState(0)
    const [balance, setBalance] = useState(0)

    const [cashTotal, setCashtotal] = useState("")

    
    const [total, setTotal] = React.useState(0);
    const hasResults = code.length > 0;
    const hasResultsProduct = productname.length > 0;

    const inputRef = useRef();
    const resultsRef = useRef();
    const inputRef1 = useRef();

    const addRow = () => {
        setTableRow([...tableRow, {key: "", value: ""}])
    }


    const handleChange = (event) => {
        setAge(event.target.value);
    };

    useEffect(() => {
        inputRef.current.focus();
        getInvoiceno()
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
    }, []);

    useEffect(() => {
        getTotal()
    }, [cashamount, bankamount]);

    useEffect(() => {
        getTotalamount()
    }, [tableRow]);

    const getTotalamount = () => {
        var totalAmount = 0;
        for (var i = 0; i < tableRow.length; i++) {
          totalAmount += tableRow[i].rsd_total_amount
       }
        setTotal(totalAmount)
        setGross(totalAmount)
        var round = Math.round(totalAmount)
        setRound(round)
        setGrandtotal(round)
    }

    
    

    const getTotal = () => {
        var total = cashamount + bankamount
        setCashtotal(total)
    }

    const getInvoiceno = () => {
        fetch(global.url + "retailinvoiceno", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("salesinvoiceno", responseJson.data.invoiceno)
              setInvoiceno(responseJson.data.invoiceno)
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    useEffect(() => {
        if ( hasResults ) {
          document.body.addEventListener('keydown', onKeyDown);
        } else {
          document.body.removeEventListener('keydown', onKeyDown);
        }

        if ( hasResultsProduct ) {
            document.body.addEventListener('keydown', onKeyDown1);
        } else {
            document.body.removeEventListener('keydown', onKeyDown1);
        }

        
        return () => {
          document.body.removeEventListener('keydown', onKeyDown);
          document.body.removeEventListener('keydown', onKeyDown1);
        }
      }, [hasResults, hasResultsProduct]);


      function onKeyDown(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef.current;
    
        const resultsItems = Array.from(resultsRef.current.children)
    
        const activeResultIndex = resultsItems.findIndex(child => {
          return child.querySelector('a') === document.activeElement;
        });
    
        if ( isUp ) {
          console.log('Going up!');
          if ( inputIsFocused ) {
            resultsItems[resultsItems.length - 1].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex - 1] ) {
            resultsItems[activeResultIndex - 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
    
        if ( isDown ) {
          console.log('Going down!')
          if ( inputIsFocused ) {
            resultsItems[0].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex + 1] ) {
            resultsItems[activeResultIndex + 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
      }

      function onKeyDown1(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef1.current;
    
        const resultsItems = Array.from(resultsRef.current.children)
    
        const activeResultIndex = resultsItems.findIndex(child => {
          return child.querySelector('a') === document.activeElement;
        });
    
        if ( isUp ) {
          console.log('Going up!');
          if ( inputIsFocused ) {
            resultsItems[resultsItems.length - 1].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex - 1] ) {
            resultsItems[activeResultIndex - 1].querySelector('a').focus();
          } else {
            inputRef1.current.focus();
          }
        }
    
        if ( isDown ) {
          console.log('Going down!')
          if ( inputIsFocused ) {
            resultsItems[0].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex + 1] ) {
            resultsItems[activeResultIndex + 1].querySelector('a').focus();
          } else {
            inputRef1.current.focus();
          }
        }
      }


    const handleOnChange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].code = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setCode(event.currentTarget.value);
        getSearch(event.currentTarget.value, type)
    }

    const getSearch = (text, type) => {
        var company = JSON.parse(localStorage.getItem("company"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        if(type = "code") {
            var data = {
                "type" : type,
                "product_code":  text,
                "companyid" : company,
                "branchid" : branch
            }
        }
        else {
            var data = {
                "type" : type,
                "product_name":  text,
                "companyid" : company,
                "branchid" : branch
            }
        }
        
        fetch(global.url + "getProductbySearch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response", responseJson)
            setProductlist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
    }


    const listClickcode = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount) => {
            var input = [...tableRow]
            input[index].key = index
            input[index].product_batch = batchid
            input[index].code = code
            input[index].productname = name
            input[index].pricelevel = pricelevel
            input[index].rsd_qty = qty
            input[index].unit = unit
            input[index].price = salesrate
            input[index].calcprice = calcprice
            input[index].discount = discount
            // if(discount) {
            //     var totalcalc = (calcprice/100) * discount
            // }
            // else {
            //     var totalcalc = calcprice
            // }
            
            input[index].rsd_total_amount = calcprice
            setTableRow([...tableRow, input])
            setTableRow([...tableRow, {key:"",product_batch:"", code: "", productname: "", pricelevel: "", rsd_qty: "", unit: "", price: "", calcprice: "", discount: "", rsd_total_amount: 0 }])
            setCode("")
    }

    const handleProductchange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].productname = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setProductname(event.currentTarget.value);
        getSearch(event.currentTarget.value, type)
    }

    const listClickname = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount) => {
            var input = [...tableRow]
            input[index].key = index
            input[index].product_batch_id = batchid
            input[index].code = code
            input[index].productname = name
            input[index].pricelevel = pricelevel
            input[index].rsd_qty = qty
            input[index].unit = unit
            input[index].price = salesrate
            input[index].calcprice = calcprice
            input[index].discount = discount
            // if(discount) {
            //     var totalcalc = (calcprice/100) * discount
            // }
            // else {
            //     var totalcalc = calcprice
            // }
            
            input[index].rsd_total_amount = calcprice
            setTableRow([...tableRow, input])
            setTableRow([...tableRow, {key:"",product_batch:"", code: "", productname: "", pricelevel: "", rsd_qty: "", unit: "", price: "", calcprice: "", discount: "", rsd_total_amount: 0 }])
            setProductname("")
    }

    const deleteRow = (index, price) => {
        console.log("index", index)
        setTotal(total-price)
        var input = [...tableRow]
        input.splice(index, 1)
        console.log("input", input)
        setTableRow(input)
      }

      const onPopup = () => {
            setModal(true)
      }

      const paymodeChange = (event) => {
        setPaymode(event.target.value);
      };

      const cashChange = (e) => {
        let isChecked = e.target.checked;
        if(isChecked == true) {
            setCash(1)
            setPaymode("cash")
        }
        else {
            setCash(0)
            setPaymode("")
        }
        // do whatever you want with isChecked value
      }

      const bankChange = (e) => {
        let isChecked = e.target.checked;
        if(isChecked == true) {
          setBank(1)
          setPaymode("bank")
        }
        else {
           setBank(0)
           setPaymode("")
        }
        // do whatever you want with isChecked value
      }

      const disountChange = (event, index) => {
        var input = [...tableRow]
        input[index].discount = event.currentTarget.value
        var disc = (input[index].calcprice/100) * input[index].discount
        input[index].rsd_total_amount = input[index].calcprice - disc
        setTableRow(input)
      }

      const totalDiscountchange = (event) => {
        setDiscount(event)
        var disc = (total/100) * event
        var result = total - disc
        setGross(result)
        setRound(Math.round(result))
        setGrandtotal(Math.round(result))
    }

    const getRoundoff = () => {
        var discount1 = (total/100)* discount
        var round = Math.round(total) - discount1
        setRound(Math.round(round))
        setGrandtotal(Math.round(round))
        setGross(Math.round(round))
    }


      const onSave = () => {
        const exampleArray = []
        for(var i = 0 ; i< tableRow.length; i++) {
            if(tableRow[i].product_batch == "") {
                // tableRow.splice(i, 1)
            }
            else{
                exampleArray.push(tableRow[i])
            } 
        }
        console.log("tableRow", exampleArray)
        var data = {
            "retail_user_id" : userId,
            "retail_date":  date.toISOString().split('T')[0],
            "retail_invoice_no":  invoiceno,
            "retail_customername":  customer,
            "retail_address":  address,
            "retail_mobile_no":  mobile,
            "retail_round":  round,
            "retail_discount":  discount,
            "retail_gross":  gross,
            "retail_total_amount":  total,
            "retail_balance":  balance,
            "retail_grand_total":  grandTotal,
            "retail_payment_mode":  paymode,
            "retail_cash_received":  cashTotal,
            "retail_detail": exampleArray,
            "retail_branchid" : userbranchid,
            "retail_companyid" : usercompanyid
        }
        console.log("data", data)
        fetch(global.url + "updateRetailSale", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.error == true) {
                alert(responseJson.data)
            }
            else{
                alert(responseJson.message)
                window.location.reload();
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
      }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20}}>
            <div style={{background: '#6d37b9', width: '100%', padding: 5, display: 'flex'}}>
                <p style={{fontSize:20, color: '#fff', marginBottom: 0}}>Retail Sale</p>
            </div>
               <div>
                   <Row style={{marginTop: 30}}>
                       <Col xs={12} lg={3}>
                            <LocalizationProvider sx={{width: '100%'}} dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Date"
                                    value={date}
                                    onChange={(newValue) => {
                                    setDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                       </Col>
                       <Col xs={12} lg={3} className="retail-top">
                            <FormControl sx={{width: '100%' }}>
                                <TextField disabled value={invoiceno} id="outlined-basic" variant="outlined"  size="small" label="Invoice No."/>
                            </FormControl>
                       </Col>
                       <Col xs={12} lg={2} className="retail-top">
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => setCustomer(e.target.value)} value={customer} id="outlined-basic" variant="outlined"  size="small" label="Customer"/>
                            </FormControl>
                       </Col>
                       <Col xs={12} lg={2} className="retail-top">
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => setAddress(e.target.value)} value={address} id="outlined-basic" variant="outlined"  size="small" label="Address"/>
                            </FormControl>
                       </Col>
                       <Col xs={12} lg={2} className="retail-top">
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => setMobile(e.target.value)} value={mobile} id="outlined-basic" variant="outlined"  size="small" label="Mob no."/>
                            </FormControl>
                       </Col>
                   </Row>
                    <Row style={{marginTop: 30}}>
                        <Col xs={12}>
                            <Table bordered responsive>
                                <thead>
                                <tr>
                                        <th>SlNo</th>
                                        <th>Code</th>
                                        <th>Product name</th>
                                        <th>Price level</th>
                                        <th>Qty</th>
                                        <th>Unit</th>
                                      
                                        <th>Price</th>
                                        <th>Calc Price</th>
                                        <th>Discount</th>
                                        <th>Amount</th>
                                        <th>Close</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRow.map((item, index) => {
                                        return (
                                            <tr>
                                                <td >{index+1}</td>
                                                {/* <td >
                                                    <TextField autoComplete="off" onChange={(event, value) => this.inputHandler(event.target.value, index)} id="outlined-basic" label="Ref No" variant="outlined"  size="small"/>
                                                    {this.state.selectedIndex == index && this.state.textLength.length >= 1  ? 
                                                    <div ref={this.myRef} className="develop">sacdscsd</div>: null
                                                    }
                                                    </td> */}
                                                <td>
                                                <input autocomplete="off" ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "code")} />
                                                    {hasResults && selectedIndex == index && (
                                                        <div className="autocomplete">
                                                            <ul ref={resultsRef} className="people">
                                                                {productlist.map(result => {
                                                                return (
                                                                    <li key={result.url}>
                                                                        <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                            index, 
                                                                            result.pb_id,
                                                                            result.product_code,
                                                                            result.product_name,
                                                                            result.pricelevel_name,
                                                                            1,
                                                                            result.unit_name,
                                                                            result.pb_salesrate,
                                                                            result.pb_salesrate,
                                                                            result.pothers_discount,
                                                                            "",
                                                                            )}>
                                                                          {result.product_code} - { result.product_name } -{result.pb_batch} 
                                                                        </a>
                                                                    </li>
                                                                )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    )}
                                                    </td>
                                                    <td>
                                                <input autocomplete="off" ref={inputRef1} type="text" name="query" value={item.productname} onChange={(event) => handleProductchange(event, index, "name")} />
                                                
                                                    {hasResultsProduct && selectedIndex == index && (
                                                        <div className="autocomplete">
                                                            <ul ref={resultsRef} className="people">
                                                                {productlist.map(result => {
                                                                return (
                                                                    <li key={result.url}>
                                                                        <a href="javascript:void(0)" onClick={() => listClickname(
                                                                            index, 
                                                                            result.pb_id,
                                                                            result.product_code,
                                                                            result.product_name,
                                                                            result.pricelevel_name,
                                                                            1,
                                                                            result.unit_name,
                                                                            result.pb_salesrate,
                                                                            result.pb_salesrate,
                                                                            result.pothers_discount,
                                                                            "",
                                                                        )}>
                                                                            {result.product_code} - { result.product_name } -{result.pb_batch} 
                                                                        </a>
                                                                    </li>
                                                                )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </td>
                                                <td>{item.pricelevel}</td>
                                                <td>{item.rsd_qty}</td>
                                                <td>{item.unit}</td>
                                                <td>{item.price}</td>
                                                <td>{item.calcprice}</td>
                                                <td><input autocomplete="off"  type="number" name="query" value={item.discount} onChange={(event) => disountChange(event, index)} /></td>
                                                <td>{item.rsd_total_amount}</td>
                                                <td><Button onClick={() => deleteRow(index, item.rsd_total_amount)} style={{backgroundColor: '#fff', border: 'none', boxShadow: 'none'}} variant="contained">< AiFillMinusCircle color="#6d37b9" fontSize={30} style={{marginRight: 10}} /></Button></td>
                                            </tr>
                                        )
                                    })}
                                    
                                </tbody>
                            </Table>
                            <div>
                                <Button onClick={() => addRow()} style={{marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20, marginTop: 20}} variant="contained">Add New</Button>
                            </div>
                            <Row style={{marginTop: 30}}>
                                
                                <Col xs={6} lg={2}>
                                    <p style={{fontSize: 15}}>Total</p>
                                </Col>
                                <Col xs={6} lg={2}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField disabled onChange={(e) => setTotal(e.target.value)} value={total} id="outlined-basic" variant="outlined"  size="small" />
                                    </FormControl>
                                </Col>
                                <Col xs={6} lg={2}>
                                    <p style={{fontSize: 15}}>Discount(%)</p>
                                </Col>
                                <Col xs={6} lg={2}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField type="number" onChange={(e) => totalDiscountchange(e.target.value)} value={discount} id="outlined-basic" variant="outlined"  size="small" />
                                    </FormControl>
                                </Col>
                                <Col xs={6} lg={2}>
                                    <p style={{fontSize: 15}}>Gross</p>
                                </Col>
                                <Col xs={6} lg={2}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField disabled onChange={(e) => setGross(e.target.value)} value={gross} id="outlined-basic" variant="outlined"  size="small" />
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 30}}>
                                <Col xs={6} lg={2}>
                                    <p style={{fontSize: 15}}>Round</p>
                                </Col>
                                <Col xs={6} lg={2}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField disabled onChange={(e) => setRound(e.target.value)} value={round} id="outlined-basic" variant="outlined"  size="small" />
                                    </FormControl>
                                </Col>
                                <Col xs={6} lg={2}>
                                    <p style={{fontSize: 15}}>Grand Total</p>
                                </Col>
                                <Col xs={6} lg={2}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField disabled onChange={(e) => setGrandtotal(e.target.value)} value={grandTotal} id="outlined-basic" variant="outlined"  size="small" />
                                    </FormControl>
                                </Col>
                                <Col xs={6} lg={2}>
                                    <p style={{fontSize: 15}}>Balance</p>
                                </Col>
                                <Col xs={6} lg={2}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField type="number" onChange={(e) => setBalance(e.target.value)} value={balance} id="outlined-basic" variant="outlined"  size="small" />
                                    </FormControl>
                                </Col>
                            </Row>
                            
                        </Col>
                        {/* <Col>
                            <Row>
                                <Col xs={12}>
                                    <Button style={{float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20, marginTop: 30}} variant="contained"><GoSettings  color="#6d37b9" fontSize={20} style={{marginRight: 10}} />Settings</Button>
                                </Col>
                            </Row>
                        </Col> */}
                    </Row> 
                    <Row>
                        <Col xs ={12} style={{marginTop:30, marginBottom: 20}}>
                            <Button onClick={onPopup} className="retail-btn" style={{backgroundColor: '#6d37b9', borderRadius: 20}} variant="contained"><HiSave  color="White" fontSize={20} style={{marginRight: 10}} />Save</Button>
                            <Button className="retail-btn" style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20}} variant="contained"><MdFindReplace  color="White" fontSize={20} style={{marginRight: 10}} />Find</Button>
                            <Button className="retail-btn" style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20}} variant="contained"><AiFillPrinter  color="White" fontSize={20} style={{marginRight: 10}} />Print</Button>
                            <Button className="retail-btn" style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20}} variant="contained"><RiDeleteBinLine  color="White" fontSize={20} style={{marginRight: 10}} />Delete</Button>
                            <Button className="retail-btn" style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20}} variant="contained"><AiOutlineClose color="White" fontSize={20} style={{marginRight: 10}} />Close</Button>
                        </Col>
                    </Row>
                </div>
            </div>
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Pay mode
                    </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={2}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                                <FormControlLabel control={
                                <Checkbox checked={cash == 0? false : true} onChange={e => cashChange(e)} />
                                } label="Cash" />
                        </FormControl>
                    </Col>
                    <Col xs={2}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                                <FormControlLabel control={
                                <Checkbox checked={bank == 0? false : true} onChange={e => bankChange(e)} />
                                } label="Bank" />
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    {cash == 1 ? 
                        <Col xs={6} style={{marginTop: 30}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => setCashamount(e.target.value)} value={cashamount} id="outlined-basic" variant="outlined"  size="small" label="Cash amount"/>
                            </FormControl>
                        </Col>: null
                    }
                    {bank == 1 ? 
                        <Col xs={6} style={{marginTop: 30}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => setBankamount(e.target.value)} value={bankamount} id="outlined-basic" variant="outlined"  size="small" label="Bank amount"/>
                            </FormControl>
                        </Col>: null
                    }
                   
                    <Col xs={6} style={{marginTop: 30}}>
                        <Button onClick={onSave} className="retail-btn" style={{backgroundColor: '#6d37b9', borderRadius: 20}} variant="contained"><HiSave  color="White" fontSize={20} style={{marginRight: 10}} />Save</Button>
                    </Col>
                </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default Retailsale;