import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Modal ,Table, Row, Col } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";


function PDC(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [userId, setUserid] = useState("")

    const [modal, setModal] = useState(false);
    const [paymode, setPaymode] = useState("");
    const [ledgeracc, setLedgeracc] = useState("");
    const [supplierlist, setSupplierlist] = useState([]);
    const [customerlist, setCustomerlist] = useState([]);
    const [pdcamt, setPdcamt] = useState("");
    const [customer, setCustomer] = useState("");
    const [supplier, setSupplier] = useState("");
    const [date, setDate] = React.useState(new Date);
    const [chequeno, setCheque] = useState("");
    const [bankcode, setBankcode] = useState("");
    const [bankname, setBankname] = useState("");
    const [pdcstatus, setPdcstatus] = useState("");

    const [source, setSource] = useState([]);
    

    const onAdd = () => {
        setModal(true)
    }

    const supplierChange = (event) => {
        setSupplier(event.target.value)
    }

    const customerChange = (event) => {
        setCustomer(event.target.value)
    }

    const pdcstatusChange = (event) => {
        setPdcstatus(event.target.value)
    }

    const paymodeChange = (event) => {
        setPaymode(event.target.value)
        if(event.target.value == "1"){
            getSupplierlist()
        }
        else{
            getCustomerlist()
        }
    }

    useEffect(() => {
        getdata()
      }, []);


      const getdata = () => {
            var userdata = JSON.parse(localStorage.getItem("user"))
            var branch = JSON.parse(localStorage.getItem("branch"))
            var company = JSON.parse(localStorage.getItem("company"))
            setUsercompanyid(company)
            setUserbranchid(branch)
            setUsertype(userdata.user_type)
            setUserid(userdata.user_id)
            var data = {
            "type":  userdata.user_type,
            "companyid": company
            }
            fetch(global.url + "viewPdc", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
                })
                .then((response)=> response.json())
                .then((responseJson)=>{
                    console.log("response supplier", responseJson)
                    if(responseJson.status == "true"){
                        setSource([])
                    }
                    else{
                        setSource(responseJson.data)
                    }
                   
                
                })
                .catch((error)=>{
                    console.log(error) 
            })
      }

    const getSupplierlist = () => {

        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        var data = {
          "type":  userdata.user_type,
          "companyid": company
        }
        fetch(global.url + "viewSupplier", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("response supplier", responseJson)
               
                if(responseJson.Error == "false") {
                    setSupplierlist(responseJson.data)
                    console.log("response", responseJson.data)
                }
                if(responseJson == "true"){
                    alert(responseJson.data)
                }
            })
            .catch((error)=>{
                console.log(error) 
        })
    }

    const getCustomerlist = () => {
       
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        var data = {
        "type":  userdata.user_type,
        "companyid": company
        }
        fetch(global.url + "viewCustomer", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response", responseJson)
           
            if(responseJson.Error == "false") {
                setCustomerlist(responseJson.data)
                console.log("response", responseJson.data)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const onSave = () =>  {
        var data = {
          "pdc_ledger_fk": supplier ? supplier : customer,
          "pdc_amount": pdcamt,
          "pdc_pay_mode": paymode,
          "pdc_cheque_date": date.toISOString().split('T')[0],
          "pdc_cheque": chequeno,
          "pdc_bank_code": bankcode,
          "pdc_bank_name": bankname,
          "companyid": usercompanyid,
          "pdc_status": pdcstatus,
          "pdc_userid": userId,
        }
          console.log("data", data)
        fetch(global.url + "updatePdc", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("add", responseJson);
            if(responseJson.error == false) {
              getdata()
              setModal(false)
              alert(responseJson.message)
            }
            if(responseJson.status == "true") {
              alert(responseJson.message)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
      }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>PDC</p>
               <Button variant="contained" onClick={onAdd}>Add</Button>
            </div>
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>SlNo</th>
                                <th>Code</th>
                                <th>Account</th>
                                <th>Amount</th>
                                <th>PayMode</th>
                                <th>Cheque Date</th>
                                <th>cheque</th>
                                <th>Bank Code</th>
                                <th>Bank Name</th>
                            </tr>
                        </thead>
                        <tbody>
                        {source.map((row, key) => {
                            return (
                                    <tr>
                                        <td>{key}</td>
                                        <td>{row.display_code}</td>
                                        <td>{row.display_name}</td>
                                        <td>{row.pdc_amount}</td>
                                        {row.pdc_pay_mode == "1" ? 
                                        <td>Payment</td>:
                                        <td>Recieved</td>
                                        }
                                        <td>{row.pdc_cheque_date}</td>
                                        
                                        <td>{row.pdc_cheque}</td>
                                        <td>{row.pdc_bank_code}</td>
                                        <td>{row.pdc_bank_name}</td>
                                        
                                    </tr>
                                    )
                            }
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            {/* <div style={{marginTop: 30}}>
                <Row style={{marginTop: 20}}>
                    <Col xs={12} >
                        <ul className="list-inline">
                            <li><Button variant="outlined"><IconName.BsDownload style={{marginRight: 10}}/>Save</Button></li>
                            <li><Button variant="outlined"><GI.GiBinoculars style={{marginRight: 10}}/>Find</Button></li>
                            <li><Button variant="outlined"><BI.BiPrinter style={{marginRight: 10}}/>Print</Button></li>
                            <li><Button variant="outlined"><RI.RiDeleteBin6Line style={{marginRight: 10}}/>Delete</Button></li>
                            <li><Button variant="outlined"><RI.RiCloseFill style={{marginRight: 10}}/>Close</Button></li>
                        </ul>
                    </Col>
                </Row>
            </div> */}
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 PDC Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                        <Col xs={12} >
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Pay Mode</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={paymode}
                                    label="Age"
                                    onChange={paymodeChange}
                                    size="small"
                                    
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                    <MenuItem value={"1"}>Payment</MenuItem>
                                    <MenuItem value={"2"}>Recieved</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>

                        {paymode == "1" ? 
                            <Col xs={12} >
                                <FormControl sx={{ mt: 4, width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Supplier List</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={supplier}
                                        label="Age"
                                        onChange={supplierChange}
                                        size="small"
                                        
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                        {supplierlist.map((item, index) => (
                                            <MenuItem value={item.supplier_id}>{item.supplier_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col> :
                            <Col xs={12} >
                                <FormControl sx={{ mt: 4, width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Customer List</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={customer}
                                        label="Age"
                                        onChange={customerChange}
                                        size="small"
                                        
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                        {customerlist.map((item, index) => (
                                            <MenuItem value={item.customer_id}>{item.customer_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                        }

                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField autoComplete="off" type="number" onChange={(e) => setPdcamt(e.target.value)} value={pdcamt} id="outlined-basic" label="PDC Amount" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>

                    <Col xs={12}  >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                        size="small"
                                            label="Date"
                                            value={date}
                                            onChange={(newValue) => {
                                                setDate(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                </LocalizationProvider>
                        </FormControl>
                    </Col>

                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField autoComplete="off" onChange={(e) => setCheque(e.target.value)} value={chequeno} id="outlined-basic" label="Cheque" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>

                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField autoComplete="off"  onChange={(e) => setBankcode(e.target.value)} value={bankcode} id="outlined-basic" label="Bank code" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>

                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField autoComplete="off"  onChange={(e) => setBankname(e.target.value)} value={bankname} id="outlined-basic" label="Bank Name" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={pdcstatus}
                                label="Age"
                                onChange={pdcstatusChange}
                                size="small"
                                
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                                <MenuItem value={"1"}>Paid</MenuItem>
                                <MenuItem value={"2"}>Pending</MenuItem>
                                <MenuItem value={"3"}>Returned</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>

                    <Col xs ={12} style={{marginTop:30}}>
                      <Button onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>
                      <Button style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                        
                  </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default PDC;