import React, {useState, useEffect, useRef,} from 'react';
import { useHistory, useParams } from "react-router-dom";
import Paper from '@mui/material/Paper';
import { Modal , Row, Col, Table } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ReactToPrint from 'react-to-print';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { AiOutlineClose, AiFillMinusCircle } from "react-icons/ai";
import { BiRefresh } from "react-icons/bi";
import { GoSettings } from "react-icons/go";
import { HiSave } from "react-icons/hi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiFillPrinter } from "react-icons/ai";
import { MdClearAll } from "react-icons/md";
import { GiCash } from "react-icons/gi";



const columns = [
    { id: '1', label: 'Sl No#', minWidth: 50,span: false },
    { id: '1', label: 'Invoice', minWidth: 50,span: false },
    { id: '2', label: 'Location', minWidth: 50,span: false },
    { id: '3', label: 'Building', minWidth: 50,span: false },
    { id: '4', label: 'Street', minWidth: 50,span: false },
    { id: '5', label: 'Zone', minWidth: 50,span: false },   
    { id: '6', label: 'Mobile', minWidth: 50,span: false },
    { id: '7', label: 'Rate', minWidth: 50,span: false },
    { id: '8', label: 'Item, Colour, Size, Quantity', minWidth: 50,span: false },  
    // { id: '9', label: 'Description', minWidth: 50,span: false },
    // { id: '10', label: 'Deliveryboy', minWidth: 50,span: false },
    { id: '11', label: 'Remarks', minWidth: 50,span: false },  
    { id: '7', label: 'Order Time', minWidth: 50,span: false },
  ];

  

  function Purchaserequest({branchid}) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    let history = useHistory();

    const [modal, setModal] = useState(false);
    const [age, setAge] = React.useState('');
    const [date, setDate] = React.useState(new Date);
    const [tableRow,setTableRow] = React.useState([{key:"",product_batch_id:"", code: "", pb_inter_barcode: "", productname: "", pricelevel: "", pqd_qty: "", unit: "", price: 0, salesrate: 0, calcprice: 0, discount: "", pqd_total_amount: 0, colorsize: [], }])
    const [textArray, setTextarray] = React.useState([{key: "", value: ""}])
    const [selectedIndex, setSelectedindex] = React.useState("")
    // const [productname, setProductname] = React.useState("")
    // const [selectedIndex, setSelectedindex] = React.useState("")


    // api values

    const [supplierList, setSupplierList] = React.useState([])
    const [supplier, setSupplier] = React.useState("")
    const [userId, setUserid] = React.useState("")
    const [userList, setUserlist] = React.useState([])
    const [salesman, setSalesman] = React.useState("")
    const [billTypelist, setBilltypelist] = React.useState([])
    const [billType, setBilltype] = React.useState('');
    const [invoiceno, setInvoiceno] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [refno, setRefno] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [creditLimit, setCreditlimit] = React.useState('');
    const [balance, setBalance] = React.useState('');
    const [limitbalance, setLimitbalance] = React.useState('');
    const [quotation, setQuotation] = React.useState('');
    const [lpono, setLpono] = React.useState('');
    const [lpodate, setLpodate] = React.useState(new Date);
    const [delno, setDelno] = React.useState('');
    const [orderno, setOrderno] = React.useState('');

    const [total, setTotal] = React.useState(0);
    const [discount, setDiscount] = React.useState(0);
    const [otherCharges, setOthercharge] = React.useState(0);
    const [advance, setAdvance] = React.useState('');
    const [amount, setAmount] = React.useState(0);
    const [grandtotal, setGrandtotal] = React.useState(0);

    const [productmodal, setProductModal] = useState(false)
    const [lookupproductlist, setLookupproductlist] = React.useState([]);
    const [indexState, setIndexstate] = React.useState(0)

    const [productlist, setProductlist] = React.useState([]);

    const [seqlist, setSeqlist] = React.useState([]);
    const [sequence, setSequence] = React.useState("");
    const [totalnw, setTotalnw] = useState("");

    const componentRef = useRef();

    const [unitidlist, setUnitidlist] = React.useState([]);
    const [unitid, setUnitid] = React.useState("");

    const [code, setCode] = useState("");
    const [productname, setProductname] = useState("")

    // const results = people.filter(({ name }) => query && name.toLowerCase().includes(query.toLowerCase()) );
    const hasResults = code.length > 0;
    const hasResultsProduct = productname.length > 0;

    const inputRef = useRef();
    const inputRef1 = useRef();
    const resultsRef = useRef();

    const [payable, setPayable] = React.useState(0);
    const [roundoff, setRoundoff] = React.useState(0);

    const [regmodal, setReqmodal] = useState(false);
    const [purchaserequestlist, setPurchaserequestlist] = React.useState([]);

  const unitChange = (event) => {
    setUnitid(event.target.value);
    getSeqproduct()
  };

  useEffect(() => {
    getInvoiceno(billType)
},[billType])

  const billtypeChange = (event) => {
    setBilltype(event.target.value);
    getInvoiceno()
  };

  const seqChange = (event) => {
    setSequence(event.target.value);
    getSeqproduct(event.target.value)
  }

  const getSeqproduct = (id) => {
    var company = JSON.parse(localStorage.getItem("company"))
        var data = {
            "sequence":  id,
            "companyid" : company,
            "unitid" : unitid
        }
        console.log("unit data", data)
        fetch(global.url + "listproductBySeq", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("listproductBySeq", responseJson)
            const arr1 = responseJson.data;
            const arr2 = tableRow;
            const children = arr1.concat(arr2);
            console.log("children", children)
            setTableRow(children)
        })
        .catch((error)=>{
            console.log(error)
        })
  }

  const userChange = (event) => {
    setSalesman(event.target.value);
  };

    const addRow = () => {
        setTableRow([...tableRow, {key:"",product_batch_id:"", code: "", barcode: "", productname: "", pricelevel: "", pqd_qty: "", unit: "", price: 0, salesrate: 0, calcprice: 0, discount: "", pqd_total_amount: 0,colorsize: [], }])
    }

    const supplierChange = (event) => {
        setSupplier(event.target.value);
        getSupplierbyid(event.target.value)
    };

    const getSupplierbyid = (id) => {
        var data = {
            "supplier_id":  id
        }
        fetch(global.url + "getSupplierbyId", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("one customer", responseJson)
            setBalance(responseJson.data.supplier_open_balance)
            setCreditlimit(responseJson.data.supplier_credit_limit)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const inputHandler = (text, key)=>{
        // setSelectedindex(key)
        // setProductname(text)
        // console.log("text", text)
        // const _inputs = [...example];
        // _inputs[key].value = text;
        // _inputs[key].key   = key;
        // console.log("inputs", _inputs)
        // setExample(_inputs)
    }

    // function onKeyDown(event) {
    //     const isUp = event.key === 'ArrowUp';
    //     const isDown = event.key === 'ArrowDown';
    //     const inputIsFocused = document.activeElement === inputRef.current;
    
    //     const resultsItems = Array.from(resultsRef.current.children)
    
    //     const activeResultIndex = resultsItems.findIndex(child => {
    //       return child.querySelector('a') === document.activeElement;
    //     });
    
    //     if ( isUp ) {
    //       console.log('Going up!');
    //       if ( inputIsFocused ) {
    //         resultsItems[resultsItems.length - 1].querySelector('a').focus();
    //       } else if ( resultsItems[activeResultIndex - 1] ) {
    //         resultsItems[activeResultIndex - 1].querySelector('a').focus();
    //       } else {
    //         inputRef.current.focus();
    //       }
    //     }
    
    //     if ( isDown ) {
    //       console.log('Going down!')
    //       if ( inputIsFocused ) {
    //         resultsItems[0].querySelector('a').focus();
    //       } else if ( resultsItems[activeResultIndex + 1] ) {
    //         resultsItems[activeResultIndex + 1].querySelector('a').focus();
    //       } else {
    //         inputRef.current.focus();
    //       }
    //     }
    //   }

    //   function onKeyDown1(event) {
    //     const isUp = event.key === 'ArrowUp';
    //     const isDown = event.key === 'ArrowDown';
    //     const inputIsFocused = document.activeElement === inputRef1.current;
    
    //     const resultsItems = Array.from(resultsRef.current.children)
    
    //     const activeResultIndex = resultsItems.findIndex(child => {
    //       return child.querySelector('a') === document.activeElement;
    //     });
    
    //     if ( isUp ) {
    //       console.log('Going up!');
    //       if ( inputIsFocused ) {
    //         resultsItems[resultsItems.length - 1].querySelector('a').focus();
    //       } else if ( resultsItems[activeResultIndex - 1] ) {
    //         resultsItems[activeResultIndex - 1].querySelector('a').focus();
    //       } else {
    //         inputRef1.current.focus();
    //       }
    //     }
    
    //     if ( isDown ) {
    //       console.log('Going down!')
    //       if ( inputIsFocused ) {
    //         resultsItems[0].querySelector('a').focus();
    //       } else if ( resultsItems[activeResultIndex + 1] ) {
    //         resultsItems[activeResultIndex + 1].querySelector('a').focus();
    //       } else {
    //         inputRef1.current.focus();
    //       }
    //     }
    //   }

      const roundOffchange = (event) => {
        console.log("roundoff", roundoff)
        setRoundoff(event)
          var round = grandtotal - roundoff
          var grandtotal1  =  round
          setGrandtotal(grandtotal1)
          setPayable(grandtotal1)
      }

      useEffect(() => {
        inputRef.current.focus();
        getBilltype()
        getUser()
        // getInvoiceno()
        getSupplier()
        getSequence()
        getUnitid()
        
      }, []);

      const getProducts = () => {
            var company = JSON.parse(localStorage.getItem("company"))
            var data = {
                "companyid" : company
            }
            fetch(global.url + "listproductByCheckboxPurchase", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                const arr1 = responseJson.data;
                const arr2 = tableRow;
                const children = arr1.concat(arr2);
                console.log("children", responseJson)
                setTableRow(children)
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const getUnitid = () => {
        var company = JSON.parse(localStorage.getItem("company"))
            var data = {
                "companyid" : company
            }
            fetch(global.url + "unitForSeq", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("unit id", responseJson)
                if(responseJson.error == false){
                    setUnitidlist(responseJson.data) 
                }
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const getSequence = () => {
        var company = JSON.parse(localStorage.getItem("company"))
       var data = {
           "companyid" : company
       }
        fetch(global.url + "listSequence", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("listSequence", responseJson)
              if(responseJson.error == false) {
                setSeqlist(responseJson.data)
              }
              else{
                setSeqlist([])
              }
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const getInvoiceno = (id) => {
        var data = {
            "id" : billType
        }
        console.log('type', data)
        fetch(global.url + "billset_invoice", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{

            // console.log("purchaseorderinvoiceno", responseJson)
                     
            if(responseJson.error == false){
                setInvoiceno(responseJson.data)
            }
            
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const getUser = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
    
        
        var data = {
          "userid":  userdata.user_id,
          "companyid": company,
          "branchid": branch,
          "type": userdata.user_type,
        }
        fetch(global.url + "viewuser", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("user response", responseJson)
              setUserlist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
          })
      }


      const getBilltype = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
  
        var data = {
          "type":  userdata.user_type,
          "companyid": company,
          "id" : 4
        }
        fetch(global.url + "billsett_tranNames", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("list response", responseJson)
              setBilltypelist(responseJson.data)
              setBilltype(responseJson.data[0].billset_id)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const getSupplier = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        var data = {
            "type":  userdata.user_type,
            "companyid": company
        }
        fetch(global.url + "viewSupplier", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("response", responseJson)
              if(responseJson.Error == "false") {
                setSupplierList(responseJson.data)
                console.log("response", responseJson.data)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      useEffect(() => {
        var creditbal = creditLimit - balance;
        console.log("creditbal", creditbal)
        setLimitbalance(creditbal)
      }, [creditLimit])


    //   useEffect(() => {
    //     if ( hasResults ) {
    //       document.body.addEventListener('keydown', onKeyDown);
    //     } else {
    //       document.body.removeEventListener('keydown', onKeyDown);
    //     }

    //     if ( hasResultsProduct ) {
    //         document.body.addEventListener('keydown', onKeyDown1);
    //     } else {
    //         document.body.removeEventListener('keydown', onKeyDown1);
    //     }

        
    //     return () => {
    //       document.body.removeEventListener('keydown', onKeyDown);
    //       document.body.removeEventListener('keydown', onKeyDown1);
    //     }
    //   }, [hasResults, hasResultsProduct]);

      const listClickcode = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount) => {
            var input = [...tableRow]
            input[index].key = index
            input[index].product_batch_id = batchid
            input[index].code = code
            input[index].productname = name
            input[index].pricelevel = pricelevel
            input[index].pqd_qty = qty
            input[index].unit = unit
            input[index].price = salesrate
            input[index].salesrate = salesrate
            input[index].calcprice = calcprice
            input[index].discount = discount
            
            // if(discount) {
            //     var totalcalc = (calcprice/100) * discount
            // }
            // else {
            //     var totalcalc = 0
            // }
            
            input[index].pqd_total_amount = calcprice
            setTableRow([...tableRow, input])
            setTableRow([...tableRow, {key:"",product_batch_id:"", code: "", barcode: "", productname: "", pricelevel: "", pqd_qty: "", unit: "", price: 0, calcprice: 0, discount: "", pqd_total_amount: 0, colorsize: [], }])
            setCode("")
            
            // var total1 = total + totalcalc
            // console.log("totalcalc", total1)
            // setTotal(total1)
      }

      const listClickname = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount) => {
            var input = [...tableRow]
            input[index].key = index
            input[index].product_batch_id = batchid
            input[index].code = code
            input[index].productname = name
            input[index].pricelevel = pricelevel
            input[index].pqd_qty = qty
            input[index].unit = unit
            input[index].price = salesrate
            input[index].calcprice = calcprice
            input[index].discount = discount
            // if(discount) {
            //     var totalcalc = (calcprice/100) * discount
            // }
            // else {
            //     var totalcalc = 0
            // }
            
            input[index].pqd_total_amount = calcprice
            setTableRow([...tableRow, input])
            setTableRow([...tableRow, {key:"",product_batch_id:"", code: "", barcode: "",  productname: "", pricelevel: "", pqd_qty: "", unit: "", price: 0, salesrate: 0, calcprice: 0, discount: "", pqd_total_amount: 0, colorsize: [], }])
            setProductname("")
    }

      const deleteRow = (index, price) => {
        console.log("index", index)
        setTotal(total-price)
        var input = [...tableRow]
        input.splice(index, 1)
        console.log("input", input)
        setTableRow(input)
      }


      const handleOnChange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].code = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setCode(event.currentTarget.value);
        getSearch(event.currentTarget.value, type, key)
    }

    const handleProductchange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].productname = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setProductname(event.currentTarget.value);
        getSearch(event.currentTarget.value, type)
    }

    const totalDiscountchange = (event) => {
        setDiscount(event)
        var disc = (amount/100) * event
        var result = amount - disc
        var grandtotal1  =  result + parseFloat(otherCharges) 
        setGrandtotal(grandtotal1)
    }

    const otherChargeschange = (event) => {
        if(event == "") {
            console.log("empty")
            setOthercharge(0)
        }
        else{
            setOthercharge(event)
        }
    }

    // for calculating quantity * price

    // const qtyChange = (event, index) => {
    //     var textArray1 = [...tableRow]
    //     console.log("price", textArray1[index].price)
    //     var totalprice = textArray1[index].price * event.target.value
    //     textArray1[index].qty = event.target.value
    //     textArray1[index].price = totalprice
    //     setTableRow([...tableRow, textArray1])
    // }

    const getSearch = (text, type, key) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        if(type == "code") {
            var data = {
                "type" : type,
                "product_code":  text,
                "companyid" : company,
                "branchid" : branch
            }
        }
        else {
            var data = {
                "type" : type,
                "refcode":  text,
                "companyid" : company,
                "branchid" : branch
            }
        }
        
        fetch(global.url + "getProductbySearchpc", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response product list", responseJson.data[0].products[0].pb_id)
            // setProductlist(responseJson.data)
            listClickrefcode(
                key, 
                responseJson.data[0].products_id,
                responseJson.data[0].products[0].pb_inter_barcode,
                responseJson.data[0].products[0].pb_id,
                responseJson.data[0].product_refcode,
                responseJson.data[0].product_name,
                responseJson.data[0].products,
                0
                )
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    useEffect(() => {
        getTotalammount()
      }, [tableRow, discount, roundoff, otherCharges]);

      const getTotalammount = () => {
        var totalAmount = 0;
        for (var i = 0; i < tableRow.length; i++) {
          totalAmount += parseFloat(tableRow[i].pqd_total_amount)
          console.log("totalamount", tableRow[i].pqd_total_amount)
       }
       console.log("totalamount2", otherCharges)
        setAmount(totalAmount)
        var disc = (totalAmount/100) * discount
        console.log("amount edit", roundoff)
        setGrandtotal(parseFloat(totalAmount)-disc+parseFloat(otherCharges)-roundoff)
        setPayable(parseFloat(totalAmount)-disc+parseFloat(otherCharges)-roundoff)
    }

    const onSave = () => {
        const exampleArray = []
        for(var i = 0 ; i< tableRow.length; i++) {
            if(tableRow[i].product_batch_id == "") {
                // tableRow.splice(i, 1)
            }
            else{
                exampleArray.push(tableRow[i])
            }  
        }
        console.log("tableRow", exampleArray)
        var data = {
            "purchaserequest_user_id" : userId,
            "purchaserequest_bill_type":  billType,
            "purchaserequest_ref_no":  refno,
            "purchaserequest_salesman":  salesman,
            "purchaserequest_invoice":  invoiceno,
            "purchaserequest_date":  date.toISOString().split('T')[0],
            "purchaserequest_customer":  supplier,
            "purchaserequest_crdt_limit":  creditLimit,
            "purchaserequest_balance":  balance,
            "purchaserequest_limit_balance":  limitbalance,
            "purchaserequest_total":  grandtotal,
            "purchaserequest_discount":  discount,
            "purchaserequest_other_charges":  otherCharges,
            "purchaserequest_advance":  advance,
            "purchaserequest_details": exampleArray,
            "purchaserequest_branchid" : userbranchid,
            "purchaserequest_companyid" : usercompanyid,
            "purchaserequest_roundof" : roundoff,

        }
        console.log("data preq", data)
        fetch(global.url + "updatePurchasrequest", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response", responseJson)
            if(responseJson.error == true) {
                alert(responseJson.data)
            }
            else{
                alert(responseJson.message)
                window.location.reload();
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const colorChange = (event) => {
        var input=[...tableRow]
        console.log("selectedIndex", selectedIndex)
        input[selectedIndex].product_batch_id = event.target.value
        setTableRow(input)
        
        getProductbybatch1(event.target.value, selectedIndex)
      //   setColor(event.target.value)
    }

    const getProductbybatch1 = (id, index) => {
   
        var data = {
            "pb_id" : id,
        }
        console.log("data", data)
        fetch(global.url + "getProductBatchById", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("details", responseJson.data)
            setDetails(id, index, responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
      }

      const qtyChange = (event, index) => {
            var textArray1 = [...tableRow]
            console.log("price", textArray1[index].price)
            var totalprice = textArray1[index].price * event.target.value
            textArray1[index].pqd_qty = event.target.value
            textArray1[index].calcprice = totalprice
            textArray1[index].pqd_total_amount = totalprice
            setTableRow(textArray1)
        }

        const discountChange=(event, index) => {
            var textArray1 = [...tableRow]
            console.log('discount', event.target.value)
            var totalprice = textArray1[index].calcprice - event.target.value
            console.log('totalprice', totalprice)
            textArray1[index].pqd_total_amount = totalprice
            setTableRow(textArray1)
        }

      const setDetails = (id, index, responseJson) => {

        var input = [...tableRow]
        input[index].key = index
        input[index].product_batch_id = id
        
        input[index].pb_stock = responseJson.pb_stock
        input[index].pqd_qty = 1
        input[index].unit = responseJson.unit_name
        input[index].color = responseJson.color_name
        input[index].size = responseJson.size_name
        input[index].pei_image = responseJson.pei_image 
        
        input[index].price = responseJson.pb_purchaseprice
        input[index].salesrate = responseJson.pb_salesrate
        input[index].calcprice = responseJson.pb_purchaseprice
        input[index].discount = 0

        input[index].pqd_total_amount = responseJson.pb_purchaseprice 
        console.log("input", input)
        setTableRow(input)
        addRow()
    }

    // const disountChange = (event, index) => {
    //     var input = [...tableRow]
    //     input[index].discount = event.currentTarget.value
    //     var disc = (input[index].calcprice/100) * input[index].discount
    //     input[index].pqd_total_amount = input[index].calcprice - disc
    //     setTableRow(input)
    // }

    const listClickrefcode = (index, productid, pb_inter_barcode, pbid, productrefcode,productname,products, iscombo) => {
        console.log('pbid', pbid)
   
        var input = [...tableRow]
        input[index].key = index
        input[index].products_id = productid
        input[index].code = productrefcode
        input[index].sales_iscombo = iscombo
        input[index].productname = productname
        input[index].colorsize = products
        input[index].barcode = pb_inter_barcode
        getProductbybatch1(pbid, index)
        setSelectedindex(index)
        input[selectedIndex].product_batch_id = pbid
        setTableRow([...tableRow, input])
        setTableRow([...tableRow, {key:"",product_batch_id:"",product_id: "", code: "", barcode: "", productname: "", pricelevel: "", sd_qty: "", unit: "", price: 0, salesrate: 0, calcprice: 0, discount: "", pqd_total_amount: 0, pb_stock: "", sales_iscombo: "",sd_id: "",color: "", size: "",colorsize: [] }])
        setCode("")
    }

    const checkBoxclick = (e) => {
        let isChecked = e.target.checked;
        if(isChecked == true) {
            getProducts()
        }
        else{
            setTableRow([])
        }
    }

    const lookupClick = () => {
        setProductModal(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
    
        var data = {
          "usertype": userdata.user_type,
          "companyid": company
        }
        fetch(global.url + "viewAllproductbatch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("item look up", responseJson)
              setLookupproductlist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    const onfilter = (text, type) => {
        var company = JSON.parse(localStorage.getItem("company"))
        if(type == "intercode") {
            var data = {
                "type" : 'intercode',
                "intercode":  text
            }
            fetch(global.url + "posGetSearch", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("response combo", responseJson)
                if(responseJson.error == false){
                    setLookupproductlist(responseJson.data)
                }else{
                    setLookupproductlist([])
                    
                }
            })
            .catch((error)=>{
                console.log(error)
            })
        }
        if(type == "refcode") {
            var data = {
                "type" : 'refcode',
                "refcode":  text
            }
            fetch(global.url + "posGetSearch", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("response combo", responseJson)
                if(responseJson.error == false){
                    setLookupproductlist(responseJson.data)
                }else{
                    setLookupproductlist([])
                    
                }
            })
            .catch((error)=>{
                console.log(error)
            })
        }
        if(type == "superadmin") {
            var data = {
                "type" : 'superadmin',
                "productname":  text,
                "companyid" : company
            }
            console.log("superadmin", data)
            fetch(global.url + "viewAllproductbatch", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("response combo", responseJson)
                if(responseJson.status == 'false'){
                    setLookupproductlist(responseJson.data)
                }else{
                    setLookupproductlist([])
                }
            })
            .catch((error)=>{
                console.log(error)
            })
        }
    }

    const getbarcodename = (id) => {
        setIndexstate(indexState + 1)
        console.log("index", indexState)
        setProductModal(false)
        // var textArray1 = [...tableRow]
        // textArray1[indexState].code = id
        // textArray1[indexState].pb_inter_barcode = id
        // setTextarray([...tableRow, textArray1])
        getSearch(id, "refcode", indexState)
     }

     const indexChange = (index) => {
        console.log("index", index)
        setIndexstate(index)
    }

    const onPopup = () => {
        setModal(true) 
         var userdata = JSON.parse(localStorage.getItem("user"))
         var branch = JSON.parse(localStorage.getItem("branch"))
         var company = JSON.parse(localStorage.getItem("company"))
         var data = {
           "type":  userdata.user_type,
           "companyid": company
         }
         console.log("data", data)
         fetch(global.url + "viewPurchaserequest", {
             method: "POST",
             headers: {
                 'Content-Type': 'application/json',
             },
             body: JSON.stringify(data),
         })
         .then((response)=> response.json())
         .then((responseJson)=>{
             console.log("Request response", responseJson)
             if(responseJson.status == "true") {
                 setPurchaserequestlist([])
                 console.log("true")
             }
             else{
                 console.log("false")
                 setPurchaserequestlist(responseJson.data)
             }
             
         })
         .catch((error)=>{
             console.log(error)
         })
     }

     const prequestClick = (id) => {
        var data = {
            "purchaserequest_id": id,
        }
        fetch(global.url + "purchaseRequestDataFill", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setModal(false)
            console.log("Purchase request response", responseJson)
            // setRefno(responseJson.data.purchaseorder_ref_no)
            // setSalesman(responseJson.data.purchaseorder_salesman)
            // setCreditlimit(responseJson.data.purchaseorder_crdt_limit)
            // setBalance(responseJson.data.purchaseorder_balance)
            // setCreditlimit(responseJson.data.purchaseorder_limit_balance)
            // setSupplier(responseJson.data.purchaseorder_customer)
            // setPurchaserequestno(responseJson.data.purchaseorder_invoice)
            setTableRow(responseJson.data.purchaseorder_details)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

      return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
            <div style={{margin: 20,}}>
                    <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Purchase Request</p>
                    <Row>
                        {/* <Col xs ={12} style={{marginTop:30}}>
                            <Button style={{float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20}} variant="contained"><GoSettings  color="#6d37b9" fontSize={20} style={{marginRight: 10}} />Settings</Button>
                            <Button style={{float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20}} variant="contained"><BiRefresh  color="#6d37b9" fontSize={20} style={{marginRight: 10}} />Refresh</Button>
                        </Col> */}
                        </Row>
                        <Row style={{marginTop: 30}}>
                                <Row>
                                    <Col xs={12} md={1}>
                                        <p>Bill Type</p>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <FormControl sx={{width: '100%' }}>
                                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Bill Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={billType}
                                                    label="Age"
                                                    onChange={billtypeChange}
                                                    size="small"
                                                >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {billTypelist.map((row, index) => {
                                                    return (
                                                    <MenuItem value={row.billset_id}>{row.billset_formtype}</MenuItem>
                                                )})}
                                                </Select>
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={1}>
                                        <p>Invoice</p>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField value={invoiceno} disabled id="outlined-basic" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                    {/* <Col xs={12} md={2}>
                                        <p>Ref No.</p>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField onChange={(e) => setRefno(e.target.value)} value={refno} id="outlined-basic" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col> */}
                                    <Col xs={12} md={2}>
                                        <p>Newly Added Products
                                        </p>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <FormControl sx={{width: '100%' }}>
                                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Sequence</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={sequence}
                                                    label="Age"
                                                    onChange={seqChange}
                                                    size="small"
                                                >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {seqlist.map((row, index) => {
                                                    return (
                                                    <MenuItem value={row.sequence}>{row.sequence}</MenuItem>
                                                )})}
                                                </Select>
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                value={date}
                                                onChange={(newValue) => {
                                                setDate(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Col>
                                </Row>                              

                                <Row style={{marginTop: 30}}>
                                    <Col xs={12} md={1}>
                                        <p>Supplier</p>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <FormControl sx={{width: '100%' }}>
                                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Supplier</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={supplier}
                                                label="Age"
                                                onChange={supplierChange}
                                                size="small"
                                            >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {supplierList.map((row, index) => {
                                                return (
                                                <MenuItem value={row.supplier_id}>{row.supplier_name}</MenuItem>
                                            )})}
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={1}>
                                        <p>Crdt Limit: </p>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField value={creditLimit} disabled id="outlined-basic" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                    <Col xs={12} md={1}>
                                        <p>Balance</p>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField value={balance} disabled id="outlined-basic" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: 20}}>
                                    
                                </Row>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col md={3}>
                            <FormControl sx={{  width: '100%' }}>
                                <FormControlLabel control={
                                <Checkbox  onChange={e => checkBoxclick(e)} />
                                } label="Checked Products" />
                            </FormControl>
                        </Col>
                        <Col md={2}>
                            <Button onClick={() => history.push("/Products/Products")} style={{backgroundColor: '#7e7e7e', borderRadius: 5, marginTop: 10}} variant="contained">Products</Button>
                        </Col>
                        <Col  md={2}>
                            <Button onClick={() => lookupClick()} className="retail-btn" style={{backgroundColor: '#7e7e7e', borderRadius: 5, marginTop: 10}} variant="contained">Item Lookup</Button>
                        </Col>
                        {/* <Col md={3}>
                            <Button onClick={() => onPopup()} style={{backgroundColor: '#7e7e7e', borderRadius: 5, marginTop: 10}} variant="contained">Get Purchase Request</Button>
                        </Col> */}
                    </Row>
                    
                </div>
                <div style={{margin: 20,}}>
                {/* {loader ? <Loader /> : */}
                    <Table bordered responsive >
                            <thead className="tableheaderexe1">
                                <tr>
                                    
                                    <th className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                    SlNo
                                    </th>
                                    <th className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                    Ref Code
                                    </th>
                                    <th className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                    Barcode
                                    </th>
                                    <th className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                    Product name
                                    </th>
                                    {/* <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                    Color, Size
                                    </TableCell> */}
                                    <th className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                    Qty
                                    </th>
                                    {/* <TableCell  className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                    Image
                                    </TableCell> */}
                                    <th  className='tablecellPrint1 nondisplay' style={{fontWeight: 'bold'}}>
                                    Unit
                                    </th>
                                    <th  className='tablecellPrint1 nondisplay' style={{fontWeight: 'bold'}}>
                                    Cost Price
                                    </th>
                                  
                                    {/* <TableCell className='tablecellPrint1 nondisplay' style={{fontWeight: 'bold'}}>
                                    Calc Price
                                    </TableCell> */}
                                    {/* <TableCell  className='tablecellPrint1 nondisplay' style={{fontWeight: 'bold'}}>
                                    Discount
                                    </TableCell> */}
                                    <th className='tablecellPrint1 nondisplay' style={{fontWeight: 'bold',width: '10%'}}>
                                    Total Amount
                                    </th>
                                    <th  className='tablecellPrint1 nondisplay' style={{fontWeight: 'bold'}}>
                                    Sales Price
                                    </th>
                                    <th className='tablecellPrint1 nondisplay' style={{fontWeight: 'bold',width: '10%'}}>
                                    Remove
                                    </th>
                                    {/* <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                    Order Time
                                    </TableCell> */}
                                </tr>
                            </thead>
                            <tbody>
                            {
                            tableRow.map((item, index) => {
                                return (
                                    <tr className='tablerowPrint1'  tabIndex={-1} >
                                        <td className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                        {index+1}
                                        </td>
                                        <td className='tablecellPrint1'  style={{fontWeight: 'bold'}}>
                                        <input autocomplete="off" autoFocus onFocus={() => indexChange(index)} style={{width: 100}} ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} />
                                                
                                                {/* {hasResults && selectedIndex == index && (
                                                        <div className="autocomplete">
                                                            <ul ref={resultsRef} className="people scrolling-div">
                                                                {productlist.map(item => {
                                                                    return (
                                                                        item.product_fetchiscombo == 0 ? 
                                                                        item.products.map(result => {
                                                                            return (
                                                                            <li key={item.url}>
                                                                                <a href="javascript:void(0)" onClick={() => listClickrefcode(
                                                                                    index, 
                                                                                    item.products_id,
                                                                                    result.pb_inter_barcode,
                                                                                    result.pb_id,
                                                                                    item.product_refcode,
                                                                                    item.product_name,
                                                                                    item.products,
                                                                                    0
                                                                                    )}>
                                                                                {item.product_refcode} - { item.product_name }
                                                                                </a>
                                                                            </li>
                                                                        )
                                                                        }) : 
                                                                        <li key={item.url}>
                                                                            <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                                index, 
                                                                                item.products_id,
                                                                                item.product_refcode,
                                                                                item.product_name,
                                                                                item.pricelevel_name,
                                                                                "1",
                                                                                item.unit_name,
                                                                                item.product_comboprice,
                                                                                item.product_comboprice,
                                                                                0,
                                                                                item.product_comboprice,
                                                                                item.pricelevel_percentage,
                                                                                item.product_combostock,
                                                                                1
                                                                                )}>
                                                                            {item.product_refcode} - { item.product_name }
                                                                            </a>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    )} */}
                                        </td>
                                        <td className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                        {item.barcode}
                                        </td>
                                        <td className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                        <p style={{width: 300}}>{item.productname}</p>
                                        </td>
                                        {/* <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                        <FormControl sx={{width: '100%' }}>
                                                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Select</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-helper-label"
                                                                id="demo-simple-select-helper"
                                                                value={item.product_batch_id}
                                                                label="Age"
                                                                onChange={colorChange}
                                                                size="small"
                                                            >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            {item.colorsize.map((row, index) => {
                                                                return (
                                                                <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                                            )})}
                                                            </Select>
                                                    </FormControl>
                                        </TableCell> */}
                                        <td className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                        <input autocomplete="off" style={{width: 50}} type="number" name="query" value={item.pqd_qty} onChange={(event) => qtyChange(event, index)} />
                                        </td>
                                        {/* <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                            <img src={global.images + 'ecommerce_photos/' + item.pei_image} style={{width: 60, marginLeft: 10}}/>
                                        </TableCell> */}
                                        <td className='tablecellPrint1 nondisplay' style={{fontWeight: 'bold'}}>
                                        {item.unit}
                                        </td>  
                                        <td className='tablecellPrint1 nondisplay' style={{fontWeight: 'bold'}}>
                                        {parseFloat(item.price).toFixed(2)}
                                        </td>   
                                                                                                      
                                        {/* <TableCell className='tablecellPrint1 nondisplay' style={{fontWeight: 'bold'}}>
                                        {parseFloat(item.calcprice).toFixed(2)}
                                        </TableCell> */}
                                        {/* <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                        <input autocomplete="off" type="number" name="query"  onChange={(event) => discountChange(event, index)} />
                                        </TableCell> */}
                                        {/* <TableCell className='tablecellPrint1 nondisplay' style={{fontWeight: 'bold'}}>
                                        {parseFloat(item.discount).toFixed(2)}
                                        </TableCell> */}
                                        <td className='tablecellPrint1 nondisplay' style={{fontWeight: 'bold'}}>
                                        {parseFloat(item.pqd_total_amount).toFixed(2)}
                                        </td>
                                        <td className='tablecellPrint1 nondisplay' style={{fontWeight: 'bold'}}>
                                        {parseFloat(item.salesrate).toFixed(2)}
                                        </td> 
                                        {/* <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>
                                        {row.deliver_boy}
                                        </TableCell> */}
                                        <td className='tablecellPrint1 nondisplay' style={{fontWeight: 'bold', width: '10%'}}>
                                        <Button onClick={() => deleteRow(index, item.amount)} style={{backgroundColor: '#fff', border: 'none', boxShadow: 'none'}} variant="contained">< AiFillMinusCircle color="#6d37b9" fontSize={30} style={{marginRight: 10}} /></Button>
                                        </td>
                                        {/* <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                        {row.salesorder_date} - {row.salesorder_created_at}
                                        </TableCell> */}
                                    </tr>
                                );
                                })}
                            </tbody>
                        </Table>
                    <Row>
                        <Col xs ={12} style={{marginTop:30, marginBottom: 20}}>
                            <Button onClick={() => addRow()} style={{float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20, marginTop: 20}} variant="contained">Add New</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs ={12} style={{marginTop:30, marginBottom: 20}}>
                            <Button onClick={onSave} style={{backgroundColor: '#9f9f9f', borderRadius: 20}} variant="contained"><HiSave  color="White" fontSize={20} style={{marginRight: 10}} />Save</Button>
                            {/* <Button style={{backgroundColor: '#9f9f9f', marginLeft: 20, borderRadius: 20}} variant="contained"><RiDeleteBinLine  color="White" fontSize={20} style={{marginRight: 10}} />Delete</Button> */}
                            <Button style={{backgroundColor: '#9f9f9f', marginLeft: 20, borderRadius: 20}} variant="contained"><AiFillPrinter  color="White" fontSize={20} style={{marginRight: 10}} />Print</Button>
                            <Button style={{backgroundColor: '#9f9f9f', marginLeft: 20, borderRadius: 20}} variant="contained"><MdClearAll  color="White" fontSize={20} style={{marginRight: 10}} />Clear</Button>
                            {/* <Button style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20}} variant="contained"><GiCash  color="White" fontSize={20} style={{marginRight: 10}} />GST</Button> */}
                          
                        </Col>
                    </Row>
                    <Row>
                        <Col  xs={12}>
                            <Row>
                                <Col xs={12} md={1} style={{marginTop: 30}}>
                                    <p>Total
                                    </p>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField disabled value={amount.toFixed(2)} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={1} style={{marginTop: 30}}>
                                    <p>Discount %</p>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField autoComplete="off" type="number" onChange={(e) => totalDiscountchange(e.target.value)} value={discount} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={1} style={{marginTop: 30}}>
                                    <p>Other Charges
                                    </p>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField autoComplete="off" type="number" onChange={(e) => otherChargeschange(e.target.value)} defaultValue={otherCharges} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={1} style={{marginTop: 30}}>
                                    <p>Roundoff
                                    </p>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField value={roundoff} onChange={(e) => roundOffchange(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={1} style={{marginTop: 30}}>
                                    <p>Grand Total</p>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField disabled onChange={(e) => setGrandtotal(e.target.value)} value={grandtotal.toFixed(2)} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={1} style={{marginTop: 30}}>
                                    <p>Payable
                                    </p>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField value={payable.toFixed(2)} onChange={(e) => setPayable(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>  
                            </Row>
                            
                        </Col>
                        
                    </Row> 

                         
                </div>
            </Paper> 
            <Modal
              size="lg"
              show={productmodal}
              onHide={() => setProductModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Product List
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} md={4}>
                            <TextField onChange={(event) => onfilter(event.target.value, "refcode")} id="outlined-basic" variant="outlined"  size="small" label="Ref Code"/>
                      </Col>
                      <Col xs={12} md={4}>
                            <TextField onChange={(event) => onfilter(event.target.value, "intercode")} id="outlined-basic" variant="outlined"  size="small" label="Barcode"/>
                      </Col>
                      <Col xs={12} md={4}>
                            <TextField onChange={(event) => onfilter(event.target.value, "superadmin")} id="outlined-basic" variant="outlined"  size="small" label="Product Name"/>
                      </Col>
                      {/* <Button onClick={()=> lookupClick()} style={{backgroundColor: '#fff', color: '#6d37b9', borderRadius: 5, fontWeight: 'bold'}} variant="contained">Refresh</Button> */}
                  </Row>
                  <Row style={{marginTop: 20}}>
                    <Col xs={12} >
                    <Table striped bordered hover >
                        <thead>
                            <tr>
                                <th>Ref Code</th>
                                <th>Barcode</th>
                                <th>Product Name</th>
                                <th>Stock</th>
                                <th>Sales Price</th>
                            </tr>
                        </thead>
                        {/* {productlistitem.length == 0 ? */}
                            <tbody>
                                {lookupproductlist?.map((column) => (
                                    <tr>
                                        <td onClick={() => getbarcodename(column.product_refcode)} style={{cursor: 'pointer'}}>{column.product_refcode}</td>
                                        <td onClick={() => getbarcodename(column.product_refcode)} style={{cursor: 'pointer'}} >{column.pb_inter_barcode}</td>
                                        <td onClick={() => getbarcodename(column.product_refcode)} style={{cursor: 'pointer'}}>{column.product_name}</td>
                                        <td>{column.pb_stock}</td>
                                        <td>{column.pb_salesrate}</td>
                                    </tr>
                                    ))
                                } 
                            </tbody>
                        </Table>
                    </Col>
                  </Row>
              </Modal.Body>
           </Modal>
           <Modal
            size="lg"
            show={modal}
            onHide={() => setReqmodal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
               Select Purchase Request
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <Row>
                    <Col xs={4}>
                        <FormControl sx={{width: '100%' }}>
                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Supplier</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={searchsupplier}
                                    label="Age"
                                    onChange={searchsupplierChange}
                                    size="small"
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {supplierList.map((row, index) => {
                                    return (
                                    <MenuItem value={row.supplier_id}>{row.supplier_name}</MenuItem>
                                )})}
                                </Select>
                        </FormControl>    
                    </Col>
                    <Col xs={4}>
                        <FormControl sx={{width: '100%' }}>
                            <TextField  onChange={(e) => setSearchinvoice(e.target.value)} value={searchinvoice} placeholder='Invoice Number' id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                </Row> */}
                <Row style={{marginTop: 20}}>
                    <Col xs={12}>
                        <Table hover responsive bordered className="section-to-print">
                            <thead>
                                <tr>
                                    <th>Request Invoice</th>
                                    <th>Date</th>
                                    <th>Supplier</th>
                                </tr>
                            </thead>
                            <tbody>
                            {purchaserequestlist.map(result => {
                                return (
                                    <tr style={{cursor: "pointer"}} onClick={() => prequestClick(result.purchaserequest_id)}>
                                        <td>{result.purchaserequest_invoice}</td>
                                        <td>{result.purchaserequest_date}</td>
                                        <td>{result.supplier_name}</td>
                                    </tr>
                                )
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
        </div>
      );
  }

  export default Purchaserequest;
