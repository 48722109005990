import React, {useState, useEffect} from 'react';
import { Form  } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl, { getFormControlUtilityClasses } from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Map from '../google-map/map';
import { Route } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const columns = [
    { id: '1', label: 'Order No.', minWidth: 50,span: false },
    { id: '2', label: 'Customer', minWidth: 50,span: false },
    { id: '3', label: 'Product', minWidth: 50,span: false },
    { id: '4', label: 'Included Products', minWidth: 50,span: false },
    { id: '5', label: 'Code', minWidth: 50,span: false },
    { id: '6', label: 'Quality', minWidth: 50,span: false },
    { id: '7', label: 'Address', minWidth: 50,span: false },
    { id: '8', label: 'Ordered Date', minWidth: 50,span: false },
    { id: '9', label: 'Processed', minWidth: 50,span: false },
    { id: '10', label: 'Shipped', minWidth: 50,span: false },
    { id: '11', label: 'Delivered', minWidth: 50,span: false },
    { id: '12', label: 'Invoice', minWidth: 50,span: false },
  ];

  const row = [
      {test : 'demo'}
  ]

function DeliveryOrder(props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [modal, setModal] = React.useState(false);
    const [value, setValue] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:20,  marginBottom: 0, fontWeight: 'bold'}}>Delivery Order</p>
            </div>
            <TableContainer sx={{ maxHeight: 440 }}>

              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.test}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.test}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.test}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.test}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.test}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.test}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.test}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.test}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.test}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.test}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.test}
                              </TableCell>
                              <TableCell >
                                <Button variant="contained">View</Button>
                              </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>

            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={row.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
      </div>
    )
}
export default DeliveryOrder;