import React, {Component, useEffect, useState, useRef} from 'react';
import { Container, Row, Col, Table,Modal } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import ReactToPrint from 'react-to-print';
import Button from '@mui/material/Button';

function Multipleprint(props){
    const [printarray, setPrintarray] =  useState([])
    const { id } = useParams();
    const [date, setDate] = React.useState("")
    const [voucherno, setVoucherno] = React.useState("")
    const [details, setDetails] = React.useState([])
    const [salestotal, setTotal] = React.useState("")
    const [othercharges, setOthercharges] = React.useState("")
    const [customer, setCustomer] = React.useState("")
    const [building, setBuilding] = React.useState("")

    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [customername, setCustomername] = useState('')
    const [area, setArea] = useState('')
    const [remarks, setRemarks] = useState('')
    const [ordertype, setOrdertype] = useState('')
    const [street, setStreet] = useState('')

    const [zone, setZone] = useState('')
    const [mobile, setMobile] = useState('')
    const [lpo, setLpono] = useState('')
    const [tableRow, setTableRow] = useState([])
    const [reference, setReference] = useState('')
    const [salesman, setSalesman] = useState('')

    const [totalamount, setTotalamount] = useState('')
    const [roundoff, setRoundoff] = useState('')
    const [subtotal, setSubtotal] = useState('')

    const [variationamount, setVariationamount] = useState('')
    const [deliverycharge, setDeliverycharge] = useState('')
    const [replacereturn, setReplacereturn] = useState('')

    const [replacebefore, setReplacebefore] = useState('')
    const componentRef = useRef();

    const onClose = () => {
        props.parentCallback()
    }
    
    
    return(
        <div>
            <Modal
                size="lg"
                show={props.modal}
                onHide={() => onClose()}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                  Sales Order Bill
                  <div style={{marginLeft: 10}}>
                    <ReactToPrint
                    
                        trigger={() => <Button style={{padding: 5, fontSize: 15}}  variant="contained">Print!</Button>}
                        content={() => componentRef.current}
                    />
                </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div ref={componentRef}>
                    {
                        props.printarray.map((item) => {
                            return(
                                <div  className="invoice-box printing">
                                    <div className="invoice-inside">
                                        <p style={{textAlign: 'center', fontSize: 18, fontWeight: 'bold'}}>{global.proname}</p>
                                        <p style={{textAlign: 'center', fontSize: 14, fontWeight: 'bold'}}>Salesorder Invoice</p>
                                        <Row>
                                        <Col xs={6}>
                                            <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>عميل</p>
                                            <p style={{fontSize: 12, fontWeight: 500}}>Customer: {item.customer_name}</p>
                                        </Col>
                                        <Col xs={6}>
                                            <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                            <p style={{fontSize: 12, fontWeight: 500}}>Mob No: {item.customer_mobile}</p>
                                        </Col>
                                        <Col xs={6}>
                                            <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>رقم القسيمة</p>
                                            <p style={{fontSize: 12, fontWeight: 500}}>Voucher No: {item.sales_invoice}</p>
                                        </Col>
                                        <Col xs={6}>
                                            <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                            <p style={{fontSize: 12, fontWeight: 500}}>Building: {item.customer_building}</p>
                                        </Col>


                                        <Col xs={6}>
                                            <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                            <p style={{fontSize: 12, fontWeight: 500}}>Street: {item.customer_street}</p>
                                        </Col>
                                        <Col xs={6}>
                                            <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                            <p style={{fontSize: 12, fontWeight: 500}}>Zone: {item.sales_zone}</p>
                                        </Col>
                                        <Col xs={6}>
                                            <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                            <p style={{fontSize: 12, fontWeight: 500}}>Area: {item.customer_area}</p>
                                        </Col>

                                      
                                        <Col xs={6}>
                                            <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                            <p style={{fontSize: 12, fontWeight: 500}}>Reference: {item.sales_reference}</p>
                                        </Col>

                                        <Col xs={6}>
                                            <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>تاريخ</p>
                                            <p style={{fontSize: 12, fontWeight: 500}}>Date: {item.sales_date}</p>
                                        </Col>
                                        
                                        </Row>    
                                        <div className='invoice-line' style={{width: '100%', height: 2, backgroundColor: '#d2d2d2'}}></div>
                                        <div>
                                            <Table bordered className='invoicetable'>
                                                <thead>
                                                    <tr>
                                                    <th style={{width: '10%'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>عدد</p>
                                                    <p style={{fontSize: 12, fontWeight: 'bold'}}>SLNo.</p>
                                                    </th>
                                                    {/* <th style={{width: '20%'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>الرمز الشريطي</p>
                                                    <p style={{fontSize: 12, fontWeight: 'bold'}}>Barcode</p>
                                                    </th> */}
                                                    <th style={{width: '35%'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>منتج</p>
                                                    <p style={{fontSize: 12, fontWeight: 'bold'}}>Product</p></th>
                                                    <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>كمية</p>
                                                    <p style={{fontSize: 12, fontWeight: 'bold'}}>Qty</p></th>
                                                    <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>السعر</p>
                                                    <p style={{fontSize: 12, fontWeight: 'bold'}}>Price</p></th>
                                                    <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>مجموع</p>
                                                    <p style={{fontSize: 12, fontWeight: 'bold'}}>Total</p></th>
                                                    </tr>
                                                </thead>
                                                    {
                                                        item.replacereturn == 1 ?
                                                        <tbody>
                                                            {
                                                                    item.sales_details.map((i, index) => {
                                                                    return(
                                                                        i.sod_iscancel == 1 ? 
                                                                        null:
                                                                        <tr>
                                                                            <td>{index+1}</td>
                                                                            {/* <td>{i.pb_inter_barcode}</td> */}
                                                                            <td>{i.productname}</td>
                                                                            <td>{i.sd_qty}</td>
                                                                            <td>QAR {i.price}</td>
                                                                            <td>QAR {i.sd_total_amount}</td>
                                                                        </tr>
                                                                    )
                                                            
                                                                })
                                                            }
                                                            <tr>
                                                                    
                                                                    <th colSpan={4} style={{textAlign: 'end'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>المجموع الفرعي</p>
                                                                    <p style={{fontSize: 12, fontWeight: 'bold'}}>Extra Delivery Charge</p></th>
                                                                    <td>QAR {item.delivery_charge}</td>
                                                            </tr>
                                                            <tr>
                                                                
                                                                    <th colSpan={4} style={{textAlign: 'end'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>المجموع الفرعي</p>
                                                                    <p style={{fontSize: 12, fontWeight: 'bold'}}>Variationamount</p></th>
                                                                    <td>QAR {item.variation_amount}</td>
                                                            </tr>
                                                            <tr>
                                                                    
                                                                    <th colSpan={4} style={{textAlign: 'end'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>المجموع الفرعي</p>
                                                                    <p style={{fontSize: 12, fontWeight: 'bold'}}>Total Amount</p></th>
                                                                    <td>QAR {item.variation_amount + item.delivery_charge}</td>
                                                            </tr>
                                                        </tbody> 
                                                        :
                                                        item.salesorder_replacebefore == 1 ?
                                                        <tbody>
                                                            {
                                                                    item.sales_details.map((i, index) => {
                                                                    return(
                                                                        i.sod_iscancel == 1 ? 
                                                                        null:
                                                                        <tr>
                                                                            <td>{index+1}</td>
                                                                            {/* <td>{i.pb_inter_barcode}</td> */}
                                                                            <td>{i.productname}</td>
                                                                            <td>{i.sd_qty}</td>
                                                                            <td>QAR {i.price}</td>
                                                                            <td>QAR {i.sd_total_amount}</td>
                                                                        </tr>
                                                                    )
                                                            
                                                                })
                                                            }
                                                            <tr>
                                                                
                                                                    <th colSpan={4} style={{textAlign: 'end'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>المجموع الفرعي</p>
                                                                    <p style={{fontSize: 12, fontWeight: 'bold'}}>Extra Delivery Charge</p></th>
                                                                    <td>QAR {item.salesorder_delivery_charge}</td>
                                                            </tr>
                                                            <tr>
                                                                
                                                                    <th colSpan={4} style={{textAlign: 'end'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset',}}>المجموع الفرعي</p>
                                                                    <p style={{fontSize: 12, fontWeight: 'bold', }}>Variationamount</p></th>
                                                                    <td>QAR {item.salesorder_variation_amount}</td>
                                                            </tr>
                                                            <tr>
                                                                    
                                                                    <th colSpan={4} style={{textAlign: 'end'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset', float: 'right'}}>المجموع الفرعي</p>
                                                                    <p style={{fontSize: 12, fontWeight: 'bold'}}>Total Amount</p></th>
                                                                    <td>QAR {item.salesorder_variation_amount + item.salesorder_delivery_charge}</td>
                                                            </tr>
                                                        </tbody> :
                                                        <tbody>
                                                            {
                                                                    item.sales_details.map((i, index) => {
                                                                    return(
                                                                        i.sod_iscancel == 1 ? 
                                                                        null:
                                                                        <tr>
                                                                            <td>{index+1}</td>
                                                                            {/* <td>{i.pb_inter_barcode}</td> */}
                                                                            <td>{i.productname}</td>
                                                                            <td>{i.sd_qty}</td>
                                                                            <td>QAR {i.price}</td>
                                                                            <td>QAR {i.sd_total_amount}</td>
                                                                        </tr>
                                                                    )
                                                            
                                                                })
                                                            }
                                                                <tr>
                                                                    
                                                                    <th colSpan={4} style={{textAlign: 'end'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>المجموع الفرعي</p>
                                                                    <p style={{fontSize: 12, fontWeight: 'bold'}}>Sub Total</p></th>
                                                                    <td>QAR {item.sales_subtotal}</td>
                                                                </tr>

                                                                <tr>
                                                                
                                                                    <th colSpan={4} style={{textAlign: 'end'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>المجموع الفرعي</p>
                                                                    <p style={{fontSize: 12, fontWeight: 'bold'}}>Other Charges</p></th>
                                                                    <td>QAR {item.sales_other_charges}</td>
                                                                </tr>

                                                                <tr>
                                                                    
                                                                    <th colSpan={4} style={{textAlign: 'end'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>نهاية الجولة</p>
                                                                    <p style={{fontSize: 12, fontWeight: 'bold'}}>Round off</p></th>
                                                                    <td>QAR {item.sales_roundoff}</td>
                                                                </tr>
                                                                
                                                                
                                                                <tr>
                                                                    
                                                                    <th colSpan={4} style={{textAlign: 'end'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>مجموع</p>
                                                                    <p style={{fontSize: 12, fontWeight: 'bold'}}>Total</p></th>
                                                                    <td>QAR {item.sales_total}</td>
                                                                </tr>
                                                        </tbody>
                                                    }
                                            </Table>
                                        </div>
                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                            <img src={require("../../assets/images/qrcode.png").default} style={{width: 50, height: 50}}/>
                                        </div>
                                        <div style={{marginTop: 30}} className="invoicebill">
                                            <ul className='billliststyle'>
                                                <li>Sales are non refundable</li>
                                                <li>Marchandise can only be exchanged upon presentation of a valid reciept within 7 days of the date of sale</li>
                                                <li>Used, Cleaned, altered or damaged marchandise will not be accepted for exchange</li>
                                                <li>Marchandise for exchange must inclued or iginal packing and labels</li>
                                            </ul>
                                        </div>
                                        <div style={{marginTop: 30}} className="invoicebill">
                                            <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>www.fetch.qa</p>
                                            <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>Visit Again!</p>
                                            <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>{global.proname}</p>
                                            <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>Doha, Qatar</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        } 
                        )
                    }
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}


export default Multipleprint;