
import './AppWIdget.css';
import React, {Component, useEffect, useState, useRef} from 'react';
import Paper from '@mui/material/Paper';
import { Row, Col, Button, Form } from "react-bootstrap";
import { AiOutlineSend} from "react-icons/ai";

import avatar from '../../assets/images/avatar.jpg'

const columns = [
    { id: '1', label: 'Sent To', minWidth: 50,span: false },
    { id: '2', label: 'Subject', minWidth: 50,span: false },
    { id: '3', label: 'Message', minWidth: 50,span: false },
    { id: '4', label: 'Sent Date', minWidth: 50,span: false },
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }
  
  const source = [
    {
      
    }
  ];

function Widgets(props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>App Widgets</p>
            </div>
            <div style={{margin: 20}}>
                <Row>
                    <Col xs={12} md={6}>
                        <div>
                            <p style={{fontWeight: 'bold'}}>TODO LIST</p>
                            <div style={{display: 'flex'}}>
                                <Form>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control type="email" placeholder="Type Here..." style={{borderRadius: 0}}/>
                                    </Form.Group>
                                </Form>
                                <Button variant="primary" type="submit" style={{height: 38, borderRadius: 0, backgroundColor: '#000', border: 'none'}}>Add Tudo</Button>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <div>
                            <p style={{fontWeight: 'bold'}}>CHAT BOX</p>
                            <div className='chatbox'>
                                <div style={{display: 'flex'}}>
                                    <div>
                                        <img src={avatar} style={{width: 80, height: 50, marginTop: 4}} />
                                    </div>
                                    <div>
                                        <p style={{fontSize: 12, marginBottom: 'unset'}}>Alan 5.08pm</p>
                                        <div style={{backgroundColor: '#6d37b9', padding: 5,height: 30}}>
                                            <p style={{fontSize: 13, color: '#fff', textAlign: 'center'}}>loremipsum@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div>
                                        <img src={avatar} style={{width: 80, height: 50, marginTop: 4}} />
                                    </div>
                                    <div>
                                        <p style={{fontSize: 12, marginBottom: 'unset'}}>Alan 5.08pm</p>
                                        <div style={{backgroundColor: '#6d37b9', padding: 5,height: 30}}>
                                            <p style={{fontSize: 13, color: '#fff', textAlign: 'center'}}>loremipsum@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div>
                                        <img src={avatar} style={{width: 80, height: 50, marginTop: 4}} />
                                    </div>
                                    <div>
                                        <p style={{fontSize: 12, marginBottom: 'unset'}}>Alan 5.08pm</p>
                                        <div style={{backgroundColor: '#6d37b9', padding: 5,height: 30}}>
                                            <p style={{fontSize: 13, color: '#fff', textAlign: 'center'}}>loremipsum@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{marginTop: 30}}>
                                    <div style={{display: 'flex'}}>
                                        <Form>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="email" placeholder="Type Here..." style={{borderRadius: 0}}/>
                                            </Form.Group>
                                        </Form>
                                        <Button variant="primary" type="submit" style={{height: 38, borderRadius: 0, backgroundColor: '#fff', borderColor: '#d3d3d3', boxShadow: 'none'}}><p><AiOutlineSend color="#000" size={22} style={{marginTop: -4}}/></p></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Paper> 
      </div>
    )
}
export default Widgets;