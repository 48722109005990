import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Row, Col, Button, Modal } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loader from '../loader/loader';
import * as FaIcons from "react-icons/fa";
import TimePicker from '@mui/lab/TimePicker';


const columns = [
  { id: '1', label: 'User', minWidth: 50,span: false },
  { id: '2', label: 'Date', minWidth: 50,span: false },
  { id: '4', label: 'Bill No.', minWidth: 50,span: false },
  { id: '5', label: 'Customer', minWidth: 50,span: false },
  { id: '6', label: 'Mobile number', minWidth: 50,span: false },
  { id: '7', label: 'Net Total', minWidth: 50,span: false },
  { id: '8', label: 'Action', minWidth: 50,span: false },
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

function PosReport(props) {

    const [value, setValue] = React.useState(new Date);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [loader, setLoader] = React.useState(false);
    const [userId, setUserid] = React.useState("");
    const [userType, setUsertype] = React.useState("");

    const [billDetails, setBillsetails] = React.useState([]);
    const [starttime, setStarttime] = React.useState(new Date);
    const [enddtime, setEndtime] = React.useState(new Date);
    const [endvalue, setEndvalue] = React.useState(new Date);
    const [type, setType] = React.useState("");
    const [totalsum, setTotalsum] = useState("")
    const [mobile, setMobile] = useState("")
    const [userList, setUserlist] = React.useState([])
    const [source, setSource] = React.useState([]);

  const startChange = (newValue) => {
    setStarttime(newValue);
  };

  const endChange = (newValue) => {
    setEndtime(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const TypeChange = (event) => {
    setType(event.target.value)
  }

  const userChange = (event) => {
    setUserid(event.target.value);
  };

  const getUser = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))    
    var data = {
      "companyid": company,
      "type": userdata.user_type,
      "branchid" : branch
    }
    fetch(global.url + "viewuser", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("user response", responseJson)
          setUserlist(responseJson.data)
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  useEffect(() => {
    getUser()
  }, [])

  // const getData = () => {
  //   setLoader(true)
  //   var userdata = JSON.parse(localStorage.getItem("user"))
  //   var branch = JSON.parse(localStorage.getItem("branch"))
  //   var company = JSON.parse(localStorage.getItem("company"))
  //   setUsercompanyid(company)
  //   setUserbranchid(branch)
  //   setUsertype(userdata.user_type)
  //   setUserid(userdata.user_id)
  //     var data = {
  //       "type":  userdata.user_type,
  //       "companyid": company,
  //       "branchid" : branch,          
  //     }
  //   fetch(global.url + "posReportView", {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type' : 'application/json'
  //       },
  //       body: JSON.stringify(data)
  //   })
  //   .then((response) => response.json())
  //   .then((responseJson) => {
  //     console.log("response12", responseJson)
  //     setLoader(false)
  //     if(responseJson.status == "false"){
  //       setSource(responseJson.data)
  //     }
  //   })
  // }



  const onfilterClick = () => {
    
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUserid(userdata.user_id)
    setUsertype(userdata.user_type)
      var data = {
        "usertype":  userdata.user_type,
        "companyid": company,
        "branchid" : branch,
        "userid" : userId,
        "type" : type,
        "sales_mob" : mobile,
        "sales_fromdate": value.toISOString().split('T')[0],
        "sales_fromtime": new Date(starttime.toISOString()).toLocaleTimeString('en',
        { timeStyle: 'short', hour12: false, timeZone: 'UTC' }),
        "sales_todate": endvalue.toISOString().split('T')[0],
        "sales_totime": new Date(enddtime.toISOString()).toLocaleTimeString('en',
        { timeStyle: 'short', hour12: false, timeZone: 'UTC' })
      }
      
    fetch(global.url + "posReportView", {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      console.log("response12", responseJson)
      setLoader(false)
      if(responseJson.error == 'false'){
        setSource(responseJson.data)
        setTotalsum(responseJson.total_sum)
      }
    })
  }

  const returnClick = (id) => {
    if (window.confirm('Are you sure you want to return the Product?')) {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))    
    var data = {
      "companyid": company,
      "userid": userdata.user_id,
      "branchid" : branch,
      "salesid" : id
    }
    fetch(global.url + "posReturn", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("return response", responseJson)
          if(responseJson.error == false){
            alert(responseJson.message)
            window.location.reload()
          }
      })
      .catch((error)=>{
          console.log(error)
      })}
  }


    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>POS Sales Report</p>
            </div>
            <div style={{margin: 20, marginTop: 10}}>
                <Row>
                    <Col xs={12} md={6} lg={2} style={{marginTop: 20}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="From"
                                value={value}
                                onChange={(newValue) => {
                                setValue(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Col>
                    <Col xs={12} md={6} lg={2} style={{marginTop: 20}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="To"
                                value={endvalue}
                                onChange={(newValue) => {
                                setEndvalue(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Col>
                    <Col sm={2} style={{marginTop: 20}}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <TimePicker
                                  label="Start Shift"
                                  value={starttime}
                                  onChange={startChange}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                          </LocalizationProvider>
                            
                      </Col>
                      <Col sm={2}  style={{marginTop: 20}}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                                label="End Shift"
                                value={enddtime}
                                onChange={endChange}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                      </Col>
                      <Col xs={12} sm={3} style={{marginTop: 20}}>
                        <FormControl sx={{width: '100%' }}>
                              <TextField  placeholder="Mobile Number Search" value={mobile} onChange={(e) => setMobile(e.target.value)}   id="outlined-basic" variant="outlined"  size="small"/>
                          </FormControl>
                      </Col>
                      <Col sm={3} style={{marginTop:20}}>
                            <FormControl sx={{  width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={type}
                                        label="Age"
                                        onChange={TypeChange}
                                        size="small"  
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                        <MenuItem value={"sales"}>Sales</MenuItem>
                                        <MenuItem value={"salesreturn"}>Sales Return</MenuItem>
                                    </Select>
                            </FormControl>
                        </Col>
                        {userType == 0 ?
                        <Col xs={12} md={3} style={{marginTop:20}}>
                            <FormControl sx={{width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">User</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={userId}
                                        label="Age"
                                        onChange={userChange}
                                        size="small"
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {userList.map((row, index) => {
                                        return (
                                        <MenuItem value={row.user_id}>{row.user_name}</MenuItem>
                                    )})}
                                    </Select>
                            </FormControl>
                        </Col>: null }
                    <Col xs={12} md={6} lg={3} style={{marginTop: 20}}>
                        <Button onClick={() => onfilterClick()} variant="primary">Filter</Button>
                    </Col>
                </Row>
            </div>
            <TableContainer sx={{ maxHeight: 440, marginTop: 3 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 3: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow  tabIndex={-1} >
                              
                              <TableCell >
                                  {row.user_name}
                              </TableCell>
                              <TableCell >
                                  {row.sales_date}
                              </TableCell>
                              <TableCell >
                                 {row.sales_invoice}
                              </TableCell>
                              <TableCell >
                                  {row.customer_name}
                              </TableCell>
                              <TableCell >
                                  {row.sales_mob_no}
                              </TableCell>
                              <TableCell >
                                  {row.sales_total}
                              </TableCell>
                              <TableCell >
                                {type == "sales" ?
                                  <Button onClick={() => returnClick(row.sales_id)} variant="primary" style={{padding: 5, fontSize: 10}}>Return Product</Button>:
                                null}
                              </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              }
            </TableContainer>
            <Row>
              <Col xs={12}>
                <div style={{float: 'right', marginRight: 130, marginTop: 20}}>
                    <p style={{fontWeight: "bold"}}>Total: </p>
                    <p  style={{fontWeight: "bold"}}> {totalsum}</p>
                  </div>
              </Col>
            </Row>
          </Paper> 
      </div>
    )
}
export default PosReport;