
import React, {useState, useEffect} from 'react';
import { Row, Col, Form  } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getFormGroupUtilityClass } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Map from '../../google-map/map';
import { MultiSelect } from "react-multi-select-component";
import Input from '@mui/material/Input';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import BtnLoader from '../../loader/btnLoader';
import Loader from '../../loader/loader';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";




function WizardFormFirstPage(props) {
    console.log("log",  props.repList)
   
  return (
        <div>
    
              
               <Row style={{padding: 10}}>
                 
                <div className="productbatchborder">
                <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Color
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Size
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Purchase price
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Sales rate
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    MRP
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Stock
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Images
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}} colSpan={2}>
                                    Actions
                                </TableCell>
                            </TableRow>
                            </TableHead>
                            
                            <TableBody>
                            {props.ecommerceproductloader ? 
                            <Row>
                                <Col xs={12}>

                                    <Loader />
                            </Col>
                            </Row> :
                            props.ecommerceproduct
                                .map((row) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.key}>
                                        <TableCell>
                                            {row.color_name}
                                        </TableCell>
                                        <TableCell >
                                            {row.size_name}
                                        </TableCell>
                                        <TableCell >
                                            {row.pe_purchaseprice}
                                        </TableCell>
                                        <TableCell >
                                            {row.pe_salesrate}
                                        </TableCell>
                                        <TableCell >
                                            {row.pe_mrp}
                                        </TableCell>
                                        <TableCell >
                                            {row.pe_stock}
                                        </TableCell>
                                        <TableCell >
                                          { row.pe_details
                                              .map((item) => {
                                                return(
                                                          <img src={item.pei_image} style={{width: 60, marginLeft: 10}}/>
                                                )}
                                              )
                                          }
                                        </TableCell>
                                        <TableCell >
                                            <a href="javascript:void(0)" onClick={() => props.deleteEcommerceproduct(row.pe_id)}>
                                                <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                            </a>
                                        </TableCell>
                                        <TableCell >
                                            <a href="javascript:void(0)" onClick={() => props.editProductecommerse(
                                                row.pe_id, 
                                                row.pe_color,
                                                row.pe_size,
                                                row.pe_purchaseprice,
                                                row.pe_salesrate,
                                                row.pe_mrp,
                                                row.pe_stock,
                                                row.pe_details
                                               )}>
                                                <FaIcons.FaEdit  color="blue" fontSize={20}  />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                );
                                })
                                }
                            </TableBody>
                            
                        </Table>
                    </TableContainer>
                 
                      <Row>
                        <div style={{marginTop: 10}}>
                          {props.productecommerceid == "" ? 
                              <p style={{marginBottom: 'unset', fontWeight: 'bold'}}>Add </p>: 
                              <p style={{marginBottom: 'unset',fontWeight: 'bold'}}>Edit</p>
                          }
                        </div>
                        <Col sm={1}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                  <InputLabel id="demo-simple-select-helper-label">Color</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-helper-label"
                                      id="demo-simple-select-helper"
                                      value={props.productcolor}
                                      label="Age"
                                      onChange={e => props.productcolorChange(e)}
                                      size="small"
                                      
                                    >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    {props.colorlist.map((item, index) => (
                                        <MenuItem value={item.color_id}>{item.color_name}</MenuItem>
                                      ))}
                                  </Select>
                            </FormControl>
                        </Col>
                        <Col sm={2}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                  <InputLabel id="demo-simple-select-helper-label">Size</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-helper-label"
                                      id="demo-simple-select-helper"
                                      value={props.productsize}
                                      label="Age"
                                      onChange={e => props.productsizechange(e)}
                                      size="small"
                                      
                                    >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    {props.sizelist.map((item, index) => (
                                        <MenuItem value={item.size_id}>{item.size_name}</MenuItem>
                                      ))}
                                  </Select>
                          </FormControl>
                        </Col>
                        <Col sm={2}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                  <Input 
                                  name="purchaseprice"
                                  value={props.productpurchaseprice} onChange={(e) => props.setProductpurchaseprice(e.target.value)}
                                   autoComplete="off" placeholder="Purchase price" type="number"  size="small"/>
                              </FormControl>
                        </Col>
                        <Col sm={2}>
                              <FormControl sx={{ mt: 4, width: '100%' }}>
                                  <Input name="salesrate"
                                 value={props.productsalesprice} onChange={(e) => props.setProductsalesprice(e.target.value)}
                                   autoComplete="off" id="outlined-basic" placeholder="Sales rate" variant="outlined" type="number"  size="small"/>
                              </FormControl>
                        </Col>
                        <Col sm={1}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                              <Input placeholder="MRP"name="mrp"
                                  value={props.productmrp} onChange={(e) => props.setProductmrp(e.target.value)}
                                  />
                            </FormControl>
                        </Col>
                        <Col sm={1}>
                            <FormControl sx={{ mt: 4, width: '100%' }}>
                                <Input placeholder="Stock" name="stock"
                                    value={props.productstock} onChange={(e) => props.setProductstock(e.target.value)}
                                    />
                              </FormControl>
                        </Col>
                        <Col sm={3}>
                                {
                                    props.productecommerceid == "" ? 
                                    <div>
                                    <label>Image</label>
                                    <input type="file" multiple onChange={(e) => props.fileSelectedHandler(e)}  style={{marginTop: 10}}/>
                                    </div> : 
                                    null
                                }
                        </Col>
                      
                      </Row>
                      <Row style={{marginTop: 20}}>
                          <Col sm={6} style={{display: 'inline-flex'}}>
                                { props.viewimage
                                      .map((item) => {
                                        return(
                                          <div className="cardimage" style={{position: 'relative'}}> 
                                                <img className="productImagelist" src={item.pei_image} />
                                                <Button onClick={() =>props.productImagedelete(item.pei_id) } className="imageDeletebtn" variant="contained">Delete</Button>
                                          </div>
                                        )}
                                      )
                                  }
                          </Col>
                          <Col sm={3}>
                          {
                                props.productecommerceid == "" ? null : 
                                <div>
                                    <label>Image</label>
                                    <input type="file"  onChange={(e) => props.fileSingleselectedHandler(e)}  style={{marginTop: 10}}/>
                                    <Button onClick={props.uploadimage} style={{marginTop: 20}}  variant="contained">Upload</Button>
                                </div>
                            }
                        </Col>
                        <Col sm={3} >
                                <div style={{float: 'right'}}>
                                    
                                    {
                                        props.productecommerceid == "" ? null : 
                                        <Button onClick={props.clearProductecommerce} style={{marginRight: 10}}  variant="contained">Clear</Button>
                                    }
                                    <Button onClick={props.addProductecommerse}  style={{float: 'right'}}  variant="contained">{props.productecommerceid == "" ? "Add": "Update"}</Button>
                                </div>
                        
                        </Col>
                      </Row>
                </div>
              </Row>
            
        </div>
  );
}

export default WizardFormFirstPage;
