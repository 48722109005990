import React, {Component, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { Container, Row, Col, Form, Table, Modal  } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as RiIcons from "react-icons/ri";
import { useParams } from 'react-router-dom';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";

import './receipt-voucher.css'
import { getDateRangePickerDayUtilityClass } from '@mui/lab';

function ReceiptVoucher(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [receiptvoucherId, setReceiptvoucherId] = React.useState('');
    const [registerDate, setRegisterDate] = React.useState(new Date);
    const [refDate, setRefdate] = React.useState(new Date);
    const [refno, setRefno] = React.useState('');
    const [remarks, setRemarks] = React.useState('');
    const [billType, setBilltype] = React.useState('');
    const [invoice, setInvoice] = React.useState('');
    const [invoNum, setInvoNu] = React.useState('')
    const [paymode, setPaymode] = React.useState('');
    const [userId, setUserid] = React.useState('');
    const [modal, setModal] = React.useState(false);
    const [allmodal, setAllmodal] = React.useState(false);
    const [againstmodal, setAgainstmodal] = React.useState(false);

    const [ledgercode, setLedgercode] = React.useState("");
    const [ledgername, setLedgername] = React.useState("");
    const [ledgerid, setLedgerid] = React.useState("");

    const [paymentcode, setPaymentcode] = React.useState("");
    const [paymentname, setPaymentname] = React.useState("");
    const [paymentamount, setPaymentamount] = React.useState("");
    const [paymentaccid, setPaymentaccid] = React.useState("");
    const [paymenttype, setPaymenttype] = React.useState("");
    const [totalAmount, setTotalamount] = React.useState(0);
    const [editmode, setEditmode] = React.useState(false);

    const [accountlist, setAccountlist] = React.useState([]);
    const [billtypelist, setBilltypelist] = React.useState([]);
    const [allaccountlist, setAllaccountlist] = React.useState([]);
    const {id} = useParams()
    const [saleslist, setSaleslist] = React.useState([]);

    const [salesid, setSalesid] = React.useState("");
    const [billinvoice, setBillinvoice] = React.useState("");
    
    const [type, setType] = React.useState("");

    const [tableRow, setTablerow] = React.useState([]);

    const billtypeChange = (event) => {
        setBilltype(event.target.value);
        setType(event.target.value)
        getInvoiceno()
      };

      useEffect(() => {
        getInvoiceno(billType)
        // console.log('billType', billType)
    },[billType])

    const paymodeChange = (event) => {
        setPaymode(event.target.value);
    };

    const selectAccount = () => {
        setModal(true)
        getAccountlist()
    }

    const getAccountlist = () => {
        const data = {
            "payment_mode": paymode,
        }
        fetch(global.url + "viewPaymentByMode", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response list7", responseJson)
            setAccountlist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
            
        })
    }

    useEffect(() => {
        console.log("id", id)
        
        getBilltype()
        if(id == "add") {
            // getInvoiceno()
            setEditmode(false)
        }
        else{
           getDetailsbyid(id)
           setEditmode(true)
        }
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
    }, []);


    const getDetailsbyid = (id) => {
        console.log("id", id)
        const data = {
            "reciept_id": id,
        }
        fetch(global.url + "viewReciept", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("payment head", responseJson)
            if(responseJson.status == "false") {
                console.log("payment head", responseJson.data)
                setBilltype(responseJson.data.reciept_bill_type)
                setInvoice(responseJson.data.reciept_invoice_no)
                var invoiceDate=new Date(responseJson.data.reciept_date).toISOString();
                var referenedate=new Date(responseJson.data.reciept_ref_date).toISOString();
                setRegisterDate(invoiceDate)
                setRefdate(referenedate)
                setRefno(responseJson.data.reciept_ref_no)
                setPaymode(responseJson.data.reciept_payment_mode)
                setLedgercode(responseJson.data.ledger_code)
                setLedgername(responseJson.data.ledger_name)

                console.log("payment table", responseJson.details)
                setTablerow(responseJson.details)
                setRemarks(responseJson.data.reciept_remarks)
                setReceiptvoucherId(responseJson.data.reciept_id)
                setLedgerid(responseJson.data.reciept_account_id_fk)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
  }

    useEffect(() => {
        calculateTotal()
    }, [tableRow]);

    const calculateTotal = () => {
        var totalValues = 0
        for(var i = 0; i < tableRow.length; i++) {
            console.log("amount", tableRow[i].rvd_amount)
            totalValues += parseInt(tableRow[i].rvd_amount)
        }
        setTotalamount(totalValues)
    }

    const deleteClick = (index, id, invoice) => {
        deleteFromApi(id, invoice)
        var input = [...tableRow]
        input.splice(index, 1)
        setTablerow(input)
    }

    const deleteFromApi = (id, invoice) => {
            if(window.confirm('Are you Want to delete this ? It will be deleted from your database')){
            var userdata = JSON.parse(localStorage.getItem("user"))
            const data = {
                "rvd_id" : id,
                "invoice": invoice,
                "userid": userdata.user_id
            }
            console.log("data detid", data)
            fetch(global.url + "deleteRecieptinEdit", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("del respo", responseJson)
                if(responseJson.error == true) {
                    alert(responseJson.message)
                }
                else{
                    alert(responseJson.message)
                }
            })
            .catch((error)=>{
                console.log(error)
            })
        }else{
            window.location.reload()
        }
    }

      const getInvoiceno = (id) => {
        var data = {
            "id" : billType
        }
        console.log('type', data)
        fetch(global.url + "billset_invoice", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            //   console.log("list response", responseJson)
              if(responseJson.error == false){
                setInvoice(responseJson.data)
                setInvoNu(responseJson.data)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const getBilltype = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
  
        var data = {
          "type":  userdata.user_type,
          "companyid": company,
          "id" : 3
        }
        fetch(global.url + "billsett_tranNames", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("list response", responseJson)
              setBilltypelist(responseJson.data)
              setBilltype(responseJson.data[0].billset_id)
          })
          .catch((error)=>{
              console.log(error)
          })
      }
      


      const selectedAcc = (name, code, id) => {
          console.log("click")
            setLedgercode(code)
            setLedgername(name)
            setLedgerid(id)
            setModal(false)
      }

      const addRow = () => {
          if(paymentcode == "") {
              return alert("Code requred")
          }

          if(paymentamount == "") {
            return alert("Amount requred")
          }

        setTablerow([...tableRow, {reciept_code: paymentcode, rvd_account_code: paymentcode, rvd_account_id_fk: paymentaccid, rvd_amount: paymentamount, pay_account_name: paymentname, rvd_type: paymenttype, rvd_sales_fk : salesid}])
        setPaymentcode("")
        setPaymentname("")
        setPaymenttype("")
        setPaymentaccid("")
        setPaymentamount("")
        setSalesid("")
        // setTotal(paymentamount)
    }

    const tableselectedAccount = (code, name, id, type) => {
        console.log("click")
        setPaymentcode(code)
        setPaymentname(name)
        setPaymenttype(type)
        setPaymentaccid(id)
        setAllmodal(false)
        setPaymentamount("")
        setSalesid("")
    }

    const addCode = () => {
        getAllaccountlist()
        setAllmodal(true)
        console.log("click")
    }

    const getAllaccountlist = () => [
        fetch(global.url + "allTransctionList", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("list response", responseJson)
              setAllaccountlist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
          })
    ]

    const onSave = () =>{   
        var paymentdate;
        var refdate;
        if(editmode == true) {
            paymentdate = registerDate.split('T')[0]
            refdate = refDate.split('T')[0]
        }
        else{
            paymentdate = registerDate.toISOString().split('T')[0]
            refdate = refDate.toISOString().split('T')[0]
        } 
        const data = {
            "reciept_id": receiptvoucherId,
            "reciept_user_id":userId,
            "invoice_no": invoice,
            "reference_no": refno,
            "reciept_date": paymentdate,
            "reference_date": refdate,
            "reciept_id": receiptvoucherId,
            "reciept_bill_type": billType,
            "payment_mode": paymode,
            "reciept_remarks": remarks,
            "reciept_table": tableRow,
            "reciept_code": ledgercode,
            "reciept_name": ledgername,
            "reciept_account_id_fk": ledgerid,
            "reciept_branchid" : userbranchid,
            "reciept_companyid" : usercompanyid,
            "rvd_account_code" : ledgercode
        }
        console.log("receipt data", data)
        fetch(global.url + "updateReciept", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response", responseJson)
            if(responseJson.error == true) {
                alert(responseJson.data)
            }
            else{
                alert(responseJson.message)
                window.location.reload();
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getBill = () => {
        setAgainstmodal(true)
        console.log("paymentaccid", paymentaccid)
        const data = {
            "against_id": paymentaccid,
            "type": "customer",
        }
        console.log("invoice_no", data)
        fetch(global.url + "againstReciept", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("bill details", responseJson)
            if(responseJson.error == false){
                setSaleslist(responseJson.data)
            }
            else{
                alert("No Record Found")
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const billSelect = (total, id, bill) => {
        setPaymentamount(total)
        setSalesid(id)
        setBillinvoice(bill)
        setAgainstmodal(false)
    }
    
    const upClick = () => {
        setInvoice(preCount => preCount + 1)
    }

    const downClick = () => {
        if(invoice > 1){
            setInvoice(preCount => preCount - 1)
        }
    }
    const purchaseDetails = () => {
        getPurchaseDetails()
    }
    const getPurchaseDetails = () => {
        var data = {
            'invoice_no' : invoice
          }
          console.log("data", data)
          fetch(global.url + "RecieptdetailByInvoiceno", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson) => {
            //   console.log("responseJson invoice", responseJson)
              if(responseJson.error == false){
                setBilltype(responseJson.data.billtype)
                setRefno(responseJson.data.refno)
                setRefdate(responseJson.data.refdate)
                setRegisterDate(responseJson.data.date)
                setPaymode(responseJson.data.paymode)
                setLedgercode(responseJson.data.code)
                setLedgername(responseJson.data.account)
                if(responseJson.data.details){
                    setTablerow(responseJson.data.details)
                }
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    const valueEdit = (id, index) => {
        var input  = [...tableRow]
        input[index].rvd_amount = id
        setTablerow(input)
    }

  return (
        <div className="main-content">
            <div style={{background: '#6d37b9', width: '100%', padding: 10, display: 'flex'}}>
                <img src={require("../../../assets/images/icons/payment-voucher.svg").default} />
                <p style={{marginBottom: 'unset', color: '#fff', fontWeight: 'bold', marginLeft: 10}}>Reciept Voucher</p>
            </div>
            <Row>
                <Col sm={6}>
                    <Row>
                        <Col sm={2} style={{marginTop:30}}>
                            <p className="label">Bill Type</p>
                        </Col>
                        <Col sm={4} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Bill Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={billType}
                                        label="Age"
                                        onChange={billtypeChange}
                                        size="small"
                                        
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {billtypelist.map((row, index) => {
                                return (
                                    <MenuItem value={row.billset_id}>{row.billset_formtype}</MenuItem>
                                )})}
                                    </Select>
                            </FormControl>
                        </Col>

                        <Col sm={2} style={{marginTop:30}}>
                            <p className="label">SL No</p>
                        </Col>
                        <Col sm={4} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={invoice} onChange={(e) => setInvoice(e.target.value)} id="outlined-basic" label="SL No" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        {/* <Col sm={1}>
                            <div style={{marginTop: 22}}>
                            {invoNum == invoice ?
                                <Button style={{backgroundColor: '#6d37b9', padding: 0}} variant="contained"><FaAngleUp  color="White" fontSize={15}  /></Button>:
                                <Button onClick={upClick} style={{backgroundColor: '#6d37b9', padding: 0}} variant="contained"><FaAngleUp  color="White" fontSize={15}  /></Button>}
                                <Button onClick={downClick} style={{backgroundColor: '#6d37b9', padding: 0}} variant="contained"><FaAngleDown  color="White" fontSize={15} /></Button>
                            </div>
                        </Col> */}
                    </Row>
                    <Row>
                        <Col sm={2} style={{marginTop:20}}>
                            <p className="label">Date</p>
                        </Col>
                        <Col sm={3} style={{marginTop:20}}>
                            <FormControl sx={{  width: '100%' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                    size="small"
                                        label="Date"
                                        value={registerDate}
                                        onChange={(newValue) => {
                                        setRegisterDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Col>
                        <Col sm={2} style={{marginTop:20}}>
                            <p className="label">Pay Mode</p>
                        </Col>
                        <Col sm={4} style={{marginTop:20}}>
                            <FormControl sx={{  width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Pay Mode</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={paymode}
                                        label="Age"
                                        onChange={paymodeChange}
                                        size="small"   
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                        <MenuItem value={"Cash"}>Cash Account</MenuItem>
                                        <MenuItem value={"Bank"}>Bank Account</MenuItem>
                                    </Select>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={2} style={{marginTop:20}}>
                            <p className="label">Ref No</p>
                        </Col>
                        <Col sm={4} style={{marginTop:20}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={refno}  onChange={(e) => setRefno(e.target.value)} id="outlined-basic" label="Ref No" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={2} style={{marginTop:20}}>
                            <p className="label">Ref Date</p>
                        </Col>
                        <Col sm={4} style={{marginTop:20}}>
                            <FormControl sx={{  width: '100%' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                        size="small"
                                            label="Date"
                                            value={refDate}
                                            onChange={(newValue) => {
                                            setRefdate(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                </LocalizationProvider>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3} style={{marginTop:20}}>
                            <FormControl component="fieldset">
                                <TextField  disabled value={ledgercode} id="outlined-basic" label="Code" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={3} style={{marginTop:20}}>
                            <FormControl component="fieldset">
                                <TextField  disabled value={ledgername} id="outlined-basic" label="Account" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={6} style={{marginTop:20}}>
                            <Button onClick={selectAccount} variant="outlined">SELECT ACCOUNT</Button>
                        </Col>
                     </Row>
                </Col>
            </Row>
            {/* <Row style={{marginTop: 30}}>
                {salesid != "" ? 
                    <p style={{fontSize: 10, color: 'green'}}>Against bill - {billinvoice}</p> : null
                    }
            </Row> */}
            <Row style={{marginTop: 30}}>
                <Col sm={2} style={{marginTop:20}}>
                    <FormControl component="fieldset">
                        <TextField onClick={addCode} value={paymentcode} disabled id="outlined-basic" label="Code" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                <Col sm={3} style={{marginTop:20}}>
                    <FormControl component="fieldset" style={{width: '100%'}}>
                        <TextField id="outlined-basic"  disabled value={paymentname} label="Account" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                <Col sm={2} style={{marginTop:20}}>
                    <FormControl component="fieldset">
                        <TextField id="outlined-basic" type="number" value={paymentamount} onChange={(e) => setPaymentamount(e.target.value)} label="Amount" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                {/* <Col sm={2} style={{marginTop:20}}>
                    <Button onClick={getBill} variant="outlined">Against bill</Button>
                </Col> */}
                <Col sm={2} style={{marginTop:20}}>
                    <Button onClick={addRow} variant="outlined">ADD ACCOUNT</Button>
                </Col>
            </Row>
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <Table bordered>
                        <thead>
                            <tr>
                            <th>SlNo</th>
                            <th style={{width: '20%'}}>Account Code</th>
                            <th style={{width: '50%'}}>Account</th>
                            <th>Amount</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableRow.map((row, index) => {
                                return (
                                    <tr>
                                        <td>{index+1}</td>
                                        <td>{row.rvd_account_code}</td>
                                        <td>{row.pay_account_name}</td>
                                        <td><TextField id="outlined-basic" type="number" value={row.rvd_amount} onChange={(e) => valueEdit(e.target.value, index)} variant="outlined"  size="small"/></td>
                                        <td >
                                            <a href="javascript:void(0)" onClick={() => deleteClick(index, row.rvd_id, row.reciept_invoice_no)}>
                                                <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                            </a>
                                        </td>
                                    </tr>
                                )}
                                )}
                            <tr>
                                <td colSpan="3" style={{textAlign: 'right', fontWeight: 'bold'}}>Total</td>
                                <td>{totalAmount}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <TextareaAutosize 
                    value={remarks}  onChange={(e) => setRemarks(e.target.value)}
                        minRows={3}
                        placeholder="Remarks"
                        style={{background: 'transparent', width: '100%'}}
                    />
                </Col>
            </Row>
            
            <Row style={{marginTop: 20}}>
                <Col xs={12} >
                    <ul className="list-inline">
                        <li><Button onClick={onSave} variant="outlined"><IconName.BsDownload style={{marginRight: 10}}/>Save</Button></li>
                        <li><Button onClick={purchaseDetails} variant="outlined"><GI.GiBinoculars style={{marginRight: 10}}/>Find</Button></li>
                        <li><Button variant="outlined"><BI.BiPrinter style={{marginRight: 10}}/>Print</Button></li>
                        <li><Button variant="outlined"><RI.RiDeleteBin6Line style={{marginRight: 10}}/>Delete</Button></li>
                        <li><Button variant="outlined"><RI.RiCloseFill style={{marginRight: 10}}/>Close</Button></li>
                    </ul>
                </Col>
            </Row>

            <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                     Select Account
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <ul>
                            {accountlist.map((row, index) => {
                                return (
                                    <a style={{textDecoration: 'none', listStyle: 'none'}} href="javascript:void(0)" onClick={() =>selectedAcc(row.ledger_name, row.ledger_code, row.ledger_id)}>
                                        <li className="pay-lists" style={{color: '#000'}}>{row.ledger_name}</li>
                                    </a>
                                )}
                                )
                                }
                            </ul>
                        </Col>
                        
                    </Row>
                </Modal.Body>
            </Modal>


            <Modal
                size="lg"
                show={allmodal}
                onHide={() => setAllmodal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                     Select Account
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <ul>
                            {allaccountlist.map((row, index) => {
                                return (
                                    <a style={{textDecoration: 'none', listStyle: 'none'}} href="javascript:void(0)" onClick={() =>tableselectedAccount(row.common_code, row.common_name, row.common_id, row.type)}>
                                        <li className="pay-lists" style={{color: '#000'}}>{row.common_name} - {row.type}</li>
                                    </a>
                                )})
                                }
                            </ul>
                        </Col>
                        
                    </Row>
                </Modal.Body>
            </Modal>


            <Modal
                size="lg"
                show={againstmodal}
                onHide={() => setAgainstmodal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                     Select Bill
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <ul>
                            {saleslist.map((row, index) => {
                                return (
                                    <a style={{textDecoration: 'none', listStyle: 'none'}} href="javascript:void(0)" onClick={() => billSelect(row.sales_total, row.sales_id, row.sales_invoice)}>
                                        <li className="pay-lists" style={{color: '#000',  marginTop: 5, fontSize: 15, cursor: 'pointer'}}>{row.sales_invoice} - {row.sales_date} - {row.sales_total}</li>
                                    </a>
                                )}
                                )
                                }
                            </ul>
                        </Col>
                        
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
  );
}

export default ReceiptVoucher;
