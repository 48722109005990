
import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Row, Col, Button } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as FaIcons from "react-icons/fa";

function UserAttendance(props) {
    const [year, setYear] = React.useState('');
    const [month, setMonth] = React.useState('');
    const [employee, setEmployee] = React.useState('');

    const SelectYear = (event) => {
        setYear(event.target.value);
      };
    const SelectMonth = (event) => {
        setMonth(event.target.value);
      };
    const SelectEmployee = (event) => {
        setEmployee(event.target.value);
      };

    return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>User Attendance</p>
            </div>
            <div style={{margin: 20, marginTop: 10}}>
                <Row>
                    <Col xs={12} md={6} lg={3} style={{marginTop: 20}}>
                    <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Year:</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={year}
                                    label="Branch"
                                    onChange={SelectYear}
                                    size="small"
                                >
                                <MenuItem value={10}>Lorem</MenuItem>
                                <MenuItem value={20}>Lorem</MenuItem>
                                <MenuItem value={30}>Lorem</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Col>
                    <Col xs={12} md={6} lg={3} style={{marginTop: 20}}>
                    <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Month:</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={month}
                                    label="Branch"
                                    onChange={SelectMonth}
                                    size="small"
                                >
                                <MenuItem value={10}>Lorem</MenuItem>
                                <MenuItem value={20}>Lorem</MenuItem>
                                <MenuItem value={30}>Lorem</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Col>
                    <Col xs={12} md={6} lg={5} style={{marginTop: 20}}>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Employee:</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={employee}
                                    label="Branch"
                                    onChange={SelectEmployee}
                                    size="small"
                                >
                                <MenuItem value={10}>Lorem</MenuItem>
                                <MenuItem value={20}>Lorem</MenuItem>
                                <MenuItem value={30}>Lorem</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Col>
                </Row>
                <div style={{marginTop: 20}}>
                    <Button variant="primary">Show</Button>
                </div>
            </div>  
          </Paper> 
        </div>
    )
}
export default UserAttendance;