
import React, {Component, useEffect, useState, useRef} from 'react';
import './purchaseprint.css';
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';


function Purchaseprint() {

    const {id} = useParams();
    const componentRef = useRef();
    const [details, setDetails] = useState([])
    const [data, setData] = useState({})

    const [netTotal, setNettotal] = useState("")
    
    useEffect(() => {
        getData()
    },[])
    
    const getData = () => {
        var data = {
            "purchase_id": id
        }
    
        fetch(global.url + "purchasePrint" ,{
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("data", responseJson )
              setDetails(responseJson.data.details)
              setData(responseJson.data)
        })
    }
    
    // const print = () => {
    //     window.print()
    // }

    useEffect(() => {
        var net = parseFloat(data.total) - parseFloat(data.purchase_discount)
        setNettotal(net)
    }, [netTotal])

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
      });

  return (
    <div>
         <Container fluid >
                <Row >
                    <Col xs={12} style={{marginTop: 20}}> 
                    <Button onClick={handlePrint} variant="primary">Print</Button>
                        <div className="invoice-bx section-to-print" ref={componentRef} >
                            <div className="invoice-insd">
                                <p style={{textAlign: 'center', fontSize: 18, fontWeight: 'bold'}}>Dekanet Beirut</p>
                                <p style={{textAlign: 'center', fontSize: 14, fontWeight: 'bold'}}>Purchase Invoice</p>
                                <div>
                                    <Table bordered className='invoicetable'>
                                        <tbody>
                                            <tr>
                                                <td colSpan={4} rowSpan={4}>
                                                    <p style={{fontSize: 12, fontWeight: 'bold',}}>تفاصيل المورد< br />Supplier Details</p>
                                                    <p style={{fontSize: 12, marginBottom: 'unset'}}>{data.supplier_name}</p> 
                                                    <p style={{fontSize: 12, marginBottom: 'unset'}}>{data.supplier_address}</p>  
                                                    <p style={{fontSize: 12, marginBottom: 'unset'}}>{data.supplier_phoneno}</p>   
                                                </td>
                                                <td colSpan={4}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>:رقما لقسيمة<br/>Voucher No: {data.purchase_invoice}</p></td>
                                            </tr>
                                            <tr>
                                            <th colSpan={4}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>رقم<br/>LPO No: {data.purchase_lpo_no}</p></th>
                                            </tr>
                                            <tr>
                                            <th colSpan={4}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>تاريخ<br/>Date: {data.purchase_date}</p></th>
                                            </tr>
                                            <tr>  
                                            <th colSpan={2}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>فاتورة المورد<br/>Supplier Inv: {data.purchase_supplier_invoice}</p></th>
                                            <th colSpan={2}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>تاريخ الفاتورة<br/>Inv Date: {data.purchase_supplier_date}</p> </th>  
                                            </tr>
                                            {/* <tr>
                                                <th colSpan={4} rowSpan={2}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>(إحضار العنوان)المرسل إليه السفينة<br />Consignee Ship To(Company Address)</p></th>
                                                <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}> إيفاد من خلال<br/>Dispatch Through:Qatar</p></th>
                                                <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}> شروط التوصيل<br/>Terms of Delivery:Qatar</p> </th>
                                            </tr> */}
                                            {/* <tr> 
                                               <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>طريقة / مدة الدفع<br/>Mode/Term of Payment:{data.mode_of_payment}</p></th>
                                               <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>مرجع آخر<br/>Other Reference:{data.other_references}</p></th>
                                                
                                            </tr> */}
                                            {/* <tr>
                                                <th  colSpan={4}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>تفاصيل المورد<br/>Supplier Details</p></th>
                                                <th  colSpan={4}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>{data.supplier_name}<br/>{data.supplier_address1}</p></th>  
                                               
                                           </tr> */}
                                          

                                        <tr>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>عدد<br/>SLNo</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>-وصف<br/>Barcode</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>الشفرة<br/>Code</p></th>
                                            {/* <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>-وصف<br/>Name</p></th> */}
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>كمية<br/>Qty</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>وحدة<br/>Unit</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>مجموع<br/>Total</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>سعر المبيعات<br/>Sales Price</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>تاريخ انتهاء الصلاحية<br/>Exp Date</p></th>
                                        </tr>

                                    {details.map((row, index) => (
                                        <>
                                            <tr>
                                                <td><p style={{fontSize: 12}}>{index+1}</p></td>
                                                <td><p style={{fontSize: 12}}>{row.pb_inter_barcode}</p></td>
                                                <td><p style={{fontSize: 12}}>{row.code}</p></td>
                                                {/* <td><p style={{fontSize: 12}}>{row.productname}</p></td> */}
                                                <td><p style={{fontSize: 12}}>{row.qty}</p></td>
                                                <td><p style={{fontSize: 12}}>{row.unit}</p></td>
                                                <td><p style={{fontSize: 12}}>{parseFloat(row.pqd_total_amount)?.toFixed(2)}</p></td>
                                                <td><p style={{fontSize: 12}}>{parseFloat(row.price)?.toFixed(2)}</p></td>
                                                <td><p style={{fontSize: 12}}>{row.expirydate}</p></td>
                                            </tr>
                                            <tr>
                                                <td colSpan={8}><p style={{fontSize: 12}}>{row.productname}</p></td>
                                            </tr>
                                        </>
                                        
                                    ))}

                                           
                                        <tr>
                                            <th colSpan={5} rowSpan={4}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>{data.total_in_words}</p> </th>
                                            <th colSpan={2}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>المجموع الفرعي<br/>Total</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>{parseFloat(data.total)?.toFixed(2)}</p></th>
                                        </tr> 
                                        <tr>    
                                            <th colSpan={2}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>خصم<br/>Discount</p></th>
                                            <th ><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>{parseFloat(data.purchase_discount)?.toFixed(2)}</p></th>
                                        </tr>   
                                        <tr>    
                                            <th colSpan={2}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>رسوم أخرى<br/>Other Charges</p></th>
                                            <th ><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>{parseFloat(data.othercharges)?.toFixed(2)}</p></th>
                                        </tr>   
                                        <tr>    
                                            <th colSpan={2}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>صافي الإجمالي<br/>Net Total</p></th>
                                            <th ><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>{parseFloat(data.total)?.toFixed(2)}</p></th>
                                        </tr>   
                                        <tr>
                                            <th colSpan={3} style={{height: 80}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>Checked By</p></th>
                                            <th colSpan={3} style={{height: 80}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>Prepared By</p></th>
                                            <th colSpan={2} style={{height: 80}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>Authorised Signatory</p> </th>
                                        </tr> 
                                        <tr>
                                            
                                            <th colSpan={8} style={{height: 80}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>Terns & Conditions</p></th>
                                            
                                        </tr> 
                                        </tbody>   
                                    </Table>
                                </div>
                                
                                
                            </div>
                        </div>
                    </Col>
                    </Row>
            </Container>
    </div>
  )
}

export default Purchaseprint