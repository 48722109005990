import React, {useState, useEffect} from 'react';
import { Form  } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Map from '../google-map/map';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../loader/loader'
import { DatasetController } from 'chart.js';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Radio from '@mui/material/Radio';


const columns = [
    { id: '1', label: 'From Date', minWidth: 50,span: false },
    { id: '2', label: 'To Date', minWidth: 50,span: false },
    {
      id: 'Action',
     
      minWidth: 100,
      span: true
    },
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

function FinantialYear(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = useState("")

    const [toggle,  setToggle] = useState(false)
  const [modal, setModal] = useState(false);
  const [yearmodal, setYearmodal] = useState(false);
  const [age, setAge] = React.useState('');

  const [source, setSource] = React.useState([]);
  

  const [loader, setLoader] = React.useState(false);
  const [routeId, setRouteid] = React.useState("");
  const [routename, setRoutename] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [blocked, setBlocked] = React.useState(0)

  const [ledgerid, setLedgerid] = React.useState("");
  const [settingsid, setSettingsid] = React.useState("");
  const [settingstype, setSettingstype] = React.useState("");
  const [settingsname, setSettingsname] = React.useState("");
  const [ledgerlist, setLedgerlist] = React.useState([]);

  const [todate, setTodate] = React.useState(new Date);
  const [fromdate, setFromdate] = React.useState(new Date);
  const [todate1, setTodate1] = React.useState(new Date);
  const [fromdate1, setFromdate1] = React.useState(new Date);
  const [finId, setFinID] = React.useState("");

  function setData(childData) {
    setToggle(childData)
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  useEffect(() => {
      getData()
    }, []);

    const getData = () => {
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(branch)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)
        setLoader(true)
        const data = {
            "companyid": company,
        }
    fetch(global.url + "getFinancialYear", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response financial", responseJson)
            if(responseJson.error == false){
                setLoader(false)
                setSource(responseJson.data)
            }
            else{
                setLoader(false)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const editClick = (
        id,
        start, 
        end
      ) => {
        setYearmodal(true)
        setFromdate1(start)
        setTodate1(end)
        setFinID(id)
    }


    const dateSubmit = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
            'fromdate' : fromdate.toISOString().split('T')[0],
            'todate' : todate.toISOString().split('T')[0],
            'companyid' : company,
            'branchid' : branch,
            'userid': userdata.user_id,
            'fin_id' : finId
        }
      console.log("data", data)
        fetch(global.url + "addFinancialYear", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("responsejson save settings", responseJson);
            if(responseJson.error == false) {
              getData()
              alert(responseJson.message)
              setYearmodal(false)
            }
            if(responseJson.error == true) {
              alert(responseJson.message)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const editdateSubmit = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
            'fromdate' : fromdate1,
            'todate' : todate1,
            'companyid' : company,
            'branchid' : branch,
            'userid': userdata.user_id,
            'fin_id' : finId
        }
      console.log("data", data)
        fetch(global.url + "addFinancialYear", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("responsejson save settings", responseJson);
            if(responseJson.error == false) {
              getData()
              alert(responseJson.message)
              setYearmodal(false)
            }
            if(responseJson.error == true) {
              alert(responseJson.message)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const blockChange = (id) => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
            'companyid' : company,
            'branchid' : branch,
            'userid': userdata.user_id,
            'fin_id' : id,
            'active' : 1
        }
      console.log("data", data)
        fetch(global.url + "activateFinancialYear", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.error == false) {
              getData()
              alert(responseJson.message)
            }
            if(responseJson.error == true) {
              alert(responseJson.message)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    return(
        <div className="main-content">
          
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Set Financial year Date</p>
               <div>
                    <Row>
                        <Col xs={12} md={3} style={{marginTop: 25}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="From Date"
                                    value={fromdate}
                                    onChange={(newValue) => {
                                        setFromdate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={3} style={{marginTop: 25}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="To Date"
                                    value={todate}
                                    onChange={(newValue) => {
                                        setTodate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={6} style={{marginTop: 25}}>
                            <Button onClick={() => dateSubmit()} variant="contained">Add</Button>
                        </Col>
                    </Row>
                </div>
            </div>
            

            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                    colSpan={column.span == true ? 2: 1}
                                >
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            
                            <TableBody>
                            {
                            source
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.code}>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                            {row.fin_startdate}
                                        </TableCell>
                                        <TableCell >
                                            {row.fin_enddate}
                                        </TableCell>
                                        <TableCell >
                                            {row.fin_isactive == 1 ? 
                                                <p style={{marginTop: 5, fontSize: 15, fontWeight: 'bold'}}>Activated</p>   :
                                                <Button variant="contained" onClick={() => blockChange(row.fin_id)}>Active</Button> 
                                            }  
                                        </TableCell>
                                        <TableCell >
                                            <a href="javascript:void(0)" onClick={() => editClick(
                                                row.fin_id,
                                                row.fin_startdate,
                                                row.fin_enddate
                                            )}>
                                                <FaIcons.FaEdit fontSize={20} color="blue" />
                                            </a>
                                        </TableCell>

                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
            }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
           <Modal
              size="lg"
              show={yearmodal}
              onHide={() => setYearmodal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Set Financial year Date
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} md={3} style={{marginTop: 25}}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                  label="From Date"
                                  value={fromdate1}
                                  onChange={(newValue) => {
                                    setFromdate1(newValue);
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                              />
                          </LocalizationProvider>
                      </Col>
                      <Col xs={12} md={3} style={{marginTop: 25}}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                  label="To Date"
                                  value={todate1}
                                  onChange={(newValue) => {
                                    setTodate1(newValue);
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                              />
                          </LocalizationProvider>
                      </Col>
                      <Col xs={12} md={6} style={{marginTop: 25}}>
                        <Button onClick={() => editdateSubmit()} variant="contained">Save</Button>
                      </Col>
                  </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default FinantialYear;