import { Container, Row, Col, Button, Form  } from "react-bootstrap";
import { BsPersonFill, BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";
import Loader from "react-loader-spinner";

function loader() {
  return (
        <Loader
            type="Oval"
            color="7e77fe"
            height={30}
            width={30}
            style={{float: 'right'}}
        />
  );
}

export default loader;
