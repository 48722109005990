import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Table , Row, Col,Modal,Button  } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import * as FaIcons from "react-icons/fa";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useHistory, useParams } from "react-router-dom";
import Loader from '../loader/loader'

function SalesApproval(props) {
    let history = useHistory();
    // let { id } = useParams();
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [source, setSource] = useState([])
    const [modal, setModal] = React.useState(false);
    const [date1, setDate1] = React.useState(new Date);
    const [date2, setDate2] = React.useState(new Date);
    const [invoice, setInvoice] = useState("");
    const [refcode, setRefcode] = useState("");
    const [barcode, setBarcode] = useState("");
    const [executiveid, setExecutiveid] = useState("");
    const [executiveList, setExecutivelist] = useState([]);
    const [mobile, setMobile] = useState("");
    const [salesmanid, setSalesmanid] = useState("");
    const [userlist, setUserlist] = useState([]);

    const [billDetails, setBilldetails] = useState([])
    const productlist = [{id: 1},{id: 2},{id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}]
    const [blocked, setBlocked] = React.useState(0);
    const [array, setArray] = React.useState([])
    const [input, setInput] = useState(0);
    const [filt, setFilt] = useState(false)
    const [loader, setLoader] = React.useState(false);

    const [frontend, setFrontend] = React.useState(0)
    const [showUnasigned, setShowunasigned] = React.useState(0)

    const [approvalsalesman, setApprovalsalesman] = React.useState("")

    const userChange = (event) => {
        setSalesmanid(event.target.value)
        getData(event.target.value)
    }

    const approvalsalesmanChange = (event) => {
        setApprovalsalesman(event.target.value)
    }

    const frontendOrderChange = (e) => {
        let isChecked = e.target.checked;
        console.log(isChecked)
        if(isChecked == true) {
            setFrontend(1)
        }
        else{
            setFrontend(0)
        }
    }

    const unasignChange = (e) => {
        let isChecked = e.target.checked;
        console.log(isChecked)
        if(isChecked == true) {
            setShowunasigned(1)
        }
        else{
            setShowunasigned(0)
        }
    }
    const blockChange = (e,id) => {
        let isChecked = e.target.checked;
        console.log(isChecked)
        if(isChecked == true) {
            var input  = [...array]
            input.push(id)
            console.log("input", input)
            setArray(input)
        }
        else{

            var testArr = [1, 2, 3, 4, 5];
            const val = 3; // Value to remove

            for (let i = 0; i < array.length; i++) {
                if (array[i] === id) {
                    const removedElements = array.splice(i, 1);
                    console.log(removedElements); // Outputs [3]
                    i--; // Since the indexes of elements following this index get updated after removal
                }
            }
        }
        console.log("array",  array)
    }

    const getUser = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        console.log('user',userdata )
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
          "companyid": company,
          "branchid" : branch
        }
        fetch(global.url + "viewDeliveryboy", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            //   console.log("user response", responseJson)
              setUserlist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

    useEffect(() => {
        getUser()
        getdata1()
      }, [frontend, showUnasigned, input]);

      const getdata1 = () => {
        setLoader(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "page" : input*10,
            "limit": 10,
            "type": userdata.user_type,
            "branchid" : branch,
            // "frontendorders": frontend,
            // "emptydeliveryboys": showUnasigned
          }
          fetch(global.url + "displayDigitalWarehouse", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                setLoader(false)
                console.log("warehouse response", responseJson)
                setSource(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
      }
      const decrement = () => {
        setInput(prevCount => prevCount - 1);
        
      }
  
     const increment = () => {
        setInput(prevCount => prevCount + 1);
      
      }
      const filter = () => {
        setFilt(true)  
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "refcode": refcode,
            "invoice" : invoice,
            "barcode": barcode,
            "deliveryboy" : salesmanid,
            "type": userdata.user_type,
            "branchid" : branch
          }
          console.log('datasj177', data)
          fetch(global.url + "displayDigitalWarehouse", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                // console.log("warehouse response", responseJson)
                setSource(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
      }
      const filterDate = () => {
        setFilt(true) 
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "date1" : date1.toISOString().split('T')[0],
            "date2" : date2.toISOString().split('T')[0],
            "type": userdata.user_type,
            "branchid" : branch
          }
          console.log('datasj177', data)
          fetch(global.url + "displayDigitalWarehouse", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("warehouse response", responseJson)
                setSource(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const getdata2 = (search) => {
        setMobile(search)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "mobile": search,
            "frontendorders": frontend,
            "emptydeliveryboys": showUnasigned,
            "type": userdata.user_type,
            "branchid" : branch
        }
        fetch(global.url + "displayDigitalWarehouse", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("user response", responseJson)
            setSource(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
      }

      const getdata3 = (search) => {
        setInvoice(search)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "invoice": search,
            "frontendorders": frontend,
            "emptydeliveryboys": showUnasigned,
            "type": userdata.user_type,
            "branchid" : branch
          }
          fetch(global.url + "displayDigitalWarehouse", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("user response", responseJson)
                setSource(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const getData = (id) => {
        setMobile("")
        setInvoice("")
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        console.log("id", id)
        var data = {
            "companyid": company,
            "salesmanid": id,
            "frontendorders": frontend,
            "emptydeliveryboys": showUnasigned,
            "type": userdata.user_type,
            "branchid" : branch
          }
        //   console.log('data delboy', data)
          fetch(global.url + "displayDigitalWarehouse", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("user response", responseJson)
                setSource(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const returnClick = (id) => {
        // console.log("id", id)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        // console.log('user id', userdata)
        var data = {
            "sdw_id": id,
            "companyid": company,
            "userid" : userdata.user_id,
            "branchid": branch
          }
          console.log('sdw_id', data)
          fetch(global.url + "returnSalesReplace", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("user response", responseJson)
                alert(responseJson.message)
                getdata1(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
      }


    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
            {
                   usertype == 1 || usertype == 2 || usertype == 3 || usertype == 4 ? 
                <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Sales Order Report</p> :
                <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Digital WareHouse</p>
            }
            {/* {
                usertype == 1 || usertype == 2 || usertype == 3 || usertype == 4 ? 
                null: 
                <div>
                    <FormControl style={{marginRight: 10}} sx={{width: '50%' }}>
                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Delivery Boy</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={approvalsalesman}
                                label="Age"
                                onChange={approvalsalesmanChange}
                                size="small"
                            >
                            <MenuItem value="">
                                <em>All</em>
                            </MenuItem>
                            {userlist.map((row, index) => {
                                return (
                                <MenuItem value={row.user_id}>{row.user_name}</MenuItem>
                            )})}
                            </Select>
                    </FormControl>
                    <Button onClick={() => updatedeliveryboy()} variant="primary" style={{padding: 5, fontSize: 15, marginRight: 15}}>Update Delivery Boy</Button>
                    <Button onClick={() => orderSubmit()} variant="primary" style={{padding: 5, fontSize: 15, marginRight: 15, marginTop: 20}}>Order Approve</Button>
                    <Button onClick={() => onPrint()} variant="primary" style={{padding: 5, fontSize: 15, marginTop: 20}}>Print</Button>
                </div>
            } */}
               
            </div>
            <Row>
                <Col sm={12} >
                    <Row>
                        <Col sm={3} style={{marginTop:30}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="From"
                                    value={date1}
                                    onChange={(newValue) => {
                                        setDate1(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col sm={3} style={{marginTop:30}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="To"
                                    value={date2}
                                    onChange={(newValue) => {
                                        setDate2(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={3} style={{marginTop: 25}}>
                            <Button onClick={filterDate} style={{width: '50%'}} variant="primary">Filter</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3} style={{marginTop:30}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => setRefcode(e.target.value)} placeholder="Ref Code" value={refcode} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={3} style={{marginTop:30}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => setInvoice(e.target.value)} placeholder="Invoice" value={invoice} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={3} style={{marginTop:30}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => setBarcode(e.target.value)} placeholder="Barcode" value={barcode} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={3}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <InputLabel id="demo-simple-select-helper-label">Delivery Boy</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    label="Age"
                                    value={salesmanid}
                                    onChange={userChange}
                                    size="small"
                                    
                                    >
                                    <MenuItem value="">
                                    <em>None</em>
                                    </MenuItem>
                                    {userlist.map((item, index) => (
                                        <MenuItem value={item.user_id}>{item.user_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={3} style={{marginTop: 25}}>
                            <Button onClick={filter} style={{width: '50%'}} variant="primary">Filter</Button>
                        </Col>
                    </Row>

                </Col>
            </Row> 
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"/>
                    <Table responsive id="table-to-xls" bordered className="section-to-print">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Delivery Boy</th>
                                <th>Invoice</th>
                                <th>Barcode</th>
                                <th>product_name</th>
                                <th>color</th>
                                <th>size</th>
                                <th>Refcode</th>
                                <th colSpan="2">Action</th>
                            </tr>
                        </thead>
                        {loader ? <Loader /> :
                        <tbody>
                        {source.map(result => {
                            return (
                            <tr>
                                <td>{result.sdw_date}</td>
                                <td>{result.deliveryboy}</td>
                                <td>{result.sdw_invoice}</td>
                                <td>{result.sdw_barcode}</td>
                                <td>{result.sdw_product_name}</td>
                                <td>{result.sdw_product_color}</td>
                                <td>{result.sdw_product_size}</td>
                                <td>{result.sdw_product_refcode}</td>

                                <td className="print_btn">
                                    <Button onClick={()=> returnClick(result.sdw_id)} variant="primary" style={{padding: 5, fontSize: 10}}>Add To Stock</Button>
                                </td>
                                {/* <td  className="print_btn"><Button onClick={() => viewBillclick(result.salesorder_id)} variant="primary" style={{padding: 5, fontSize: 10}}>View Bill</Button></td> */}
                                
                            </tr>
                            )
                            })}
                        </tbody>
                        }
                    </Table>
                </Col>
            </Row>
            <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                  Sales Order Bill
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table  bordered hover responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product code</th>
                            <th>Product name</th>
                            <th>QTY</th>
                            <th>Unit</th>
                            <th>Price</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {billDetails.map((item, index) => (
                              <tr>
                                <td>{index+1}</td>
                                <td>{item.code}</td>
                                <td>{item.productname}</td>
                                <td>{item.sd_qty}</td>
                                <td>{item.unit}</td>
                                <td>{item.price}</td>
                                <td>{item.sd_total_amount}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                </Modal.Body>
            </Modal>
          </Paper> 
          {filt == true ? null :
          <div style={{marginTop: 20}}>
                <Button onClick={increment} style={{float: 'right', backgroundColor: '#1976d2', color: '#ffffff'}}>Next</Button>
                {input == 0 ? null :
                  <Button onClick={decrement} style={{marginRight: 40, float: 'right'}} className='stock-prev' variant="light">Previous</Button>
                }
            </div>
          }
      </div>
    )
}
export default SalesApproval;