import React, {useState, useEffect} from 'react';
import { Form  } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Map from '../google-map/map';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../loader/loader'


const columns = [
    { id: '1', label: 'Sl No.', minWidth: 50,span: false },
    { id: '2', label: 'Counter', minWidth: 50,span: false },
    { id: '3', label: 'Description', minWidth: 50,span: false },
    { id: '4', label: 'Blocked', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];

const source = [
    {'data' : 'test'}
]
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

function Counter(props) {
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = React.useState('');

  const [source, setSource] =  useState([])
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [blocked, setBlocked] = React.useState(0)
  const [counterid, setCounterid] = React.useState(0)


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const checkChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
      setBlocked(1)
    }
    else {
      setBlocked(0)
    }
    // do whatever you want with isChecked value
  }

    const onAdd = () => {
        setModal(true)
        setCounterid("")
        setName("")
        setDescription("")
        setBlocked(0)
    }
    const editClick = (
      id,
      name,
      description,
      blocked
    ) => {
          setModal(true)
          setCounterid(id)
          setName(name)
          setDescription(description)
          setBlocked(blocked)
      }

    useEffect(() => {
      getData()
    }, [])

    const getData = () => {
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(branch)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)
      setLoader(true)
      var data = {
        "type":  userdata.user_type,
        "companyid": company
      }
      fetch(global.url + 'viewCashCounter', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log('responseJson', responseJson);
        setLoader(false)
        setSource(responseJson.data)
      })
      .catch((error) => {
        console.log(error)
      })
    }

    const onSave = () => {
      var data = {
        "cc_id": counterid,
        "cc_name": name,
        "cc_description": description,
        "cc_isblocked": blocked,
        "cc_branchid_fk" : userbranchid,
        "cc_companyid_fk" : usercompanyid,
        "cc_userid": userId
    }
      fetch(global.url + "updateCashCounter", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("responsejson", responseJson);
          if(responseJson.error == false) {
            getData()
            setModal(false)
            alert(responseJson.message)
          }
          if(responseJson.error == true) {
            alert(responseJson.message)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    return(
        <div className="main-content">
          
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Counter</p>
               <Button variant="contained" onClick={onAdd}>Add</Button>
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                
                <TableBody>
                  { source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell>
                                  {index + 1}
                              </TableCell>
                              <TableCell >
                                  {row.cc_name}
                              </TableCell>
                              <TableCell >
                                  {row.cc_description}
                              </TableCell>
                              <TableCell >
                                  { row.cc_isblocked == "0" ? "No" : "Yes" }
                              </TableCell>
                              <TableCell >
                                    <a href="javascript:void(0)" onClick={() => 
                                        editClick(
                                          row.cc_id,
                                          row.cc_name,
                                          row.cc_description,
                                          row.cc_isblocked
                                        )}>
                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                    </a>
                              </TableCell>
                              {/* <TableCell >
                                  <a href="javascript:void(0)">
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
  }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Counter Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField id="outlined-basic" onChange={(e) => setName(e.target.value)} value={name} label="Counter" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField id="outlined-basic" onChange={(e) => setDescription(e.target.value)} value={description} label="Description" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={5}>
                      <FormControl sx={{ mt: 4, width: '100%' }}>
                            <FormControlLabel control={
                              <Checkbox checked={blocked == 0? false : true} onChange={e => checkChange(e)} />
                            } label="Blocked" />
                        </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default Counter;