import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Loader from '../loader/loader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const columns = [
    { id: '1', label: 'Sl No#', minWidth: 50,span: false },
    { id: '1', label: 'Invoice', minWidth: 50,span: false },
    { id: '6', label: 'Mobile', minWidth: 50,span: false },
    { id: '8', label: 'Item, Colour, Size, Quantity, Barcode', minWidth: 20,span: false },  
  ];

  const source = [
      {},{}
  ]

  function Cancelledproduct(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [userId, setUserid] = useState("")
    const [source, setSource] = React.useState([]);
    const [total, setTotal] = React.useState()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [dateFrom, setDatefrom] = React.useState(new Date);
    const [dateTo, setDateTo] = React.useState(new Date);
    const [starttime, setStarttime] = React.useState(new Date);
    const [enddtime, setEndtime] = React.useState(new Date);
    const [loader, setLoader] = React.useState(false);
    const [salesmanid, setSalesmanid] = useState("")
    const [userlist, setUserlist] = useState([])
    const [combo, setCombo] = useState([])
    const [comboview, setComboview] = useState(false)
    const [invoice, setInvoice] = React.useState('');
    const [input, setInput] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    
    useEffect(() => {
        getData()
        getUser()
    }, [input])
    const userChange = (event) => {
        setSalesmanid(event.target.value)   
    }
    const startChange = (newValue) => {
        setStarttime(newValue);
      };
    
      const endChange = (newValue) => {
        setEndtime(newValue);
      };
      
    const getUser = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
    
        
        var data = {
          "companyid": company,
          "branchid" : branch
        }
        fetch(global.url + "viewDeliveryboy", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("user response", responseJson)
              setUserlist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

    const getData = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var company = JSON.parse(localStorage.getItem("company"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        setLoader(true)
        var data = {
          "type" :  userdata.user_type,
          "companyid" : company,
          "page" : input*10,
          "limit": 10,
          "branchid" : branch,

        }

        console.log('set data', data)
        fetch(global.url + "salesOrderProductReport", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("response ledger", responseJson)
              setLoader(false)
              if(responseJson.error == false){
                  console.log('respon', responseJson.data.notcombo)
                setSource(responseJson.data)
                // setCombo(responseJson.data.sales_combo[0])
                setComboview(true)
              }else{
                setSource([])
                setComboview(false)
                setCombo([])
               
              }
          })
          .catch((error)=>{
              console.log(error)
          })

    }
    const filter = () => {
        getData();
    }
    const decrement = () => {
        setInput(prevCount => prevCount - 1);
        
      }
  
     const increment = () => {
        setInput(prevCount => prevCount + 1);
      
      }
      return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <div style={{margin: 20,}}>
                    <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Cancelled Product Report</p>
                </div>
                <div style={{margin: 20,}}>
                {loader ? <Loader /> :
                    <TableContainer sx={{ maxHeight: 440, marginTop: 2 }}>
                    <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"/>
                        <Table id="table-to-xls" stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                        colSpan={column.span == true ? 2: 1}
                                    >
                                        {column.label} 
                                    </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                            source.map((row, index) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.code}>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {index+1}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.salesorder_invoice}
                                        </TableCell>
                                        
                                        <TableCell style={{fontWeight: 'bold'}}>
                                        {row.customer_mobile}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold', width: "48%"}}>
                                        {row.notcombo.map((i, k) => {
                                            return(
                                                <ul >
                                                    <li>
                                                    {i.product_refcode}-[{i.color_name} - {i.size_name} - {i.sod_qty} - {i.pb_inter_barcode}]
                                                    </li>
                                                    
                                                </ul>
                                            )
                                        })}
                                        {row.combo.map((i, k) => {
                                            return(
                                                <ul>
                                                    <li>
                                                    {i.product_refcode} - 
                                                    [
                                                    {
                                                        i.comboproducts.map((item, index) => {
                                                            return(
                                                                <span>
                                                                    {item[0].product_refcode} - {item[0].color_name} - {item[0].size_name}- {item[0].pb_inter_barcode}
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    ]
                                                    </li>
                                                    
                                                </ul>
                                            )
                                        })}
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                         }
                </div>
            </Paper> 
            <div style={{marginTop: 20}}>
                <Button onClick={increment} style={{float: 'right', backgroundColor: '#1976d2', color: '#ffffff'}}>Next</Button>
                {input == 0 ? null :
                  <Button onClick={decrement} style={{marginRight: 40, float: 'right'}} className='stock-prev' variant="light">Previous</Button>
                }
            </div>
        </div>
      );
  }

  export default Cancelledproduct;
