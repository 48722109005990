import React, { useState, useEffect } from "react";
import '../Onboard/Onboard.css';
import {Container, Row, Col, Button, Form} from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
  } from "react-router-dom";
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Avatar from '@mui/material/Avatar';



function Onboard() {
    const [source, setSource] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        fetch(global.url + "viewAllCompany", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log('responseJson', responseJson)
            setLoading(false)
            setSource(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
	}, []);
    
    return(
        <div className="App">
            <header className="onboard-component">
                <Container fluid>
                    <Row>
                        <Col xs={12} style={{marginTop: 20}}>
                            <div style={{textDecoration: "unset", cursor: 'pointer'}}>
                                <div className="onborad-box">
                                    <div className="onboardbox-inside">
                                        <h1 className="onboardbox-head">NIGC</h1>
                                        <p className="onboardbox-para">Nas International Group of Companies</p>
                                    </div>
                                </div>
                            </div>
                            <Container style={{marginTop: 50}}>
                                <p className="onboard-companies">OUR COMPANIES</p>
                                {loading == false  ?
                                <Row>
                                    {source.map((item, index) => (
                                        <Col xs={12} lg={6}>
                                        <a href={'/login/' + item.company_id} style={{color:'unset', textDecoration: 'none'}}>
                                        <div className="second-box">
                                            <Row>
                                                <Col xs={12} sm={2}>
                                                    <img alt="ss" src={global.images + "company_logo/" + item.company_logo} style={{width: 50, marginTop: 40}} />
                                                </Col>
                                                <Col xs={12} sm={10}>
                                                    <p className="box-para1">{item.company_name}</p>
                                                    <Row>
                                                        <Col xs={6}>
                                                            <p className="box-para3"><FaPhoneAlt color="#000" size={15} style={{marginRight: 5}}/>Contact No.</p>
                                                            <p className="box-para3"><MdLocationOn color="#000" size={22} style={{marginRight: 1, marginTop:-3}}/>Location</p>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <p className="box-para3">{item.company_mobile2}</p>
                                                            <p className="box-para3">{item.company_address1}</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                        </a>
                                    </Col>
                                    ))}
                                    
                                    <Col xs={12} lg={6}>
                                        <div className="second-box2">
                                            <p className="comingsoon">COMING SOON</p>
                                        </div>
                                    </Col>
                                </Row>:

                                <Row>
                                    <Col sm={6}>
                                        <Box sx={{ width: '100%' }}>
                                            <Skeleton animation="wave" style={{height: 250}}>
                                                    
                                            </Skeleton>
                                           
                                        </Box>
                                    </Col>
                                    <Col sm={6}>
                                        <Box sx={{ width: '100%' }} >
                                            <Skeleton animation="wave" style={{height: 250}}>
                                                    
                                            </Skeleton>
                                           
                                        </Box>
                                    </Col>
                                </Row>
                                }
                            </Container>
                        </Col>
                    </Row>
                    
                </Container> 
            </header>
        </div>
    )
}

export default Onboard;