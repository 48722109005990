import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as GoIcons from "react-icons/go";
import * as SiIcons from "react-icons/si";
import * as HiIcons from "react-icons/hi";
import * as MdIcons from "react-icons/md";
import * as CgIcons from "react-icons/cg";
import * as FiIcons from "react-icons/fi";
import * as BiIcons from "react-icons/bi";


export const POS = [
	{
		title: "POS",
		path: "/Sales/pos",
		icon: <MdIcons.MdSettingsSystemDaydream />,
	},
	{
		title: "POSSummary",
		path: "/Sales/possummary",
		icon: <MdIcons.MdSettingsSystemDaydream />,
	},
];
