import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Table , Row, Col } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";

function PurchaseApproval(props) {
    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Purchase Approval</p>
            </div>
            <Row>
                <Col sm={12} lg={8}>
                    <Row>
                        <Col sm={6} lg={2} style={{marginTop:30}}>
                            <p className="label">From</p>
                        </Col>
                        <Col sm={6} lg={4} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                        size="small"
                                            label="Date"
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                </LocalizationProvider>
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={2} style={{marginTop:30}}>
                            <p className="label">To</p>
                        </Col>
                        <Col sm={6} lg={4} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                        size="small"
                                            label="Date"
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                </LocalizationProvider>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} lg={2} style={{marginTop:20}}>
                            <p className="label">Doc No</p>
                        </Col>
                        <Col sm={6} lg={4} style={{marginTop:20}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField id="outlined-basic" label="Doc No" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row>
                </Col>
            </Row> 
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>SlNo</th>
                                <th>Code</th>
                                <th>Account</th>
                                <th>Debit</th>
                                <th>Credit</th>
                                <th>Narration</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>test</td>
                                <td><input autocomplete="off"  type="number" /></td>
                                <td><input autocomplete="off"  type="number" /></td>
                                <td><input autocomplete="off"  type="text" /></td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
          </Paper> 
      </div>
    )
}
export default PurchaseApproval;