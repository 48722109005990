import React, {Component, useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Loader from '../loader/loader';

const columns = [
  { id: '1', label: 'Company', minWidth: 50,span: false },
  { id: '2', label: 'Ledger', minWidth: 50,span: false },
  { id: '3', label: 'address', minWidth: 50,span: false },
  { id: '4', label: 'GSTIN', minWidth: 50,span: false },
  { id: '5', label: 'Mobile', minWidth: 50,span: false },
  { id: '6', label: 'Email', minWidth: 50,span: false },
  { id: '7', label: 'Website', minWidth: 50,span: false },
  { id: '8', label: 'Whatsap No', minWidth: 50,span: false },
  { id: '9', label: 'Mobile Prefix', minWidth: 50,span: false },
  { id: '10', label: 'Location', minWidth: 50,span: false },
  { id: '11', label: 'Caption', minWidth: 50,span: false },
];

function State(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userid, setUserid] = useState("")
  

  const [source, setSource] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
      getData()
  }, []);

  const getData = () => {
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(branch)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)
      setLoader(true)
      console.log("userdata id", userdata.user_id)
      if(userdata.user_id == 0) {
          var data = {
            "user_id": "",
            "type": userdata.user_type
          }
          console.log("company data1", data)
          fetch(global.url + "viewcompany", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            console.log("data", responseJson)
              setSource(responseJson.data)
              setLoader(false)
          })
          .catch((error)=>{
              console.log(error)
          })
      }
      if(userdata.user_id != 0) {
          var data = {
            "user_id": userdata.user_id,
            "type": userdata.user_type
          }

          console.log("company data2", data)
          fetch(global.url + "viewcompany", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            console.log("data", responseJson)
              setSource(responseJson.data)
              setLoader(false)
          })
          .catch((error)=>{
              console.log(error)
          })
      }
      
      
  }

  return (
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Company Lists</p>
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}} >
                                  {row.company_name}
                              </TableCell>
                              <TableCell >
                                  {row.company_ledger}
                              </TableCell>
                              <TableCell >
                                  {row.company_address1}
                              </TableCell>
                              <TableCell >
                                  {row.company_gstin}
                              </TableCell>
                              <TableCell >
                                  {row.company_mobile}
                              </TableCell>
                              <TableCell >
                                  {row.company_email}
                              </TableCell>
                              <TableCell >
                                  {row.company_website}
                              </TableCell>
                              <TableCell >
                                  {row.company_whatsapp}
                              </TableCell>
                              <TableCell >
                                  {row.company_mobile2}
                              </TableCell>
                              <TableCell >
                                  
                              </TableCell>
                              <TableCell >
                                  {row.company_caption}
                              </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
  );
}

export default State;
