import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Table , Row, Col,Modal,Button  } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

function DeliveryboyAssign(props) {
   
    const [source, setSource] = useState([])
    let { id, invoivefrom,  invoiceto } = useParams();
    

    useEffect(() => {
        getdata();
    })

    const getdata = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var company = JSON.parse(localStorage.getItem("company"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var data = {
            "companyid": company,
            "invoicedrom": invoivefrom,
            "invoiceto": invoiceto,
            "salesmanid": id,
            "type" : userdata.user_type, 
            "branchid" : branch
        }
        console.log('data', data)
        fetch(global.url + "viewSalesOrderBySalesman", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            })
            .then((response) => response.json())
            .then((responseJson)=>{
                console.log("user response", responseJson)
                setSource(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
  }
    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
                <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Delivery Boy Assign Report</p>
            </div>
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"/>
                    <Table responsive id="table-to-xls" bordered className="section-to-print">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Invoice</th>
                                <th>Date</th>
                                <th>Customer</th>
                                <th>Mobile</th>
                                <th>Building</th>
                                <th>Street</th>
                                <th>zone</th>
                                <th>Area</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                        {source.map(result => {
                            return (
                            <tr>
                                <td>{result.user_name}</td>
                                <td>{result.salesorder_invoice}</td>
                                <td>{result.salesorder_date}</td>
                                <td>{result.customer_name}</td>
                                <td>{result.customer_mobile}</td>
                                <td>{result.customer_building}</td>
                                <td>{result.customer_street}</td>
                                <td>{result.salesorder_zone}</td>
                                <td>{result.customer_area}</td>
                                <td>{result.salesorder_total}</td>                                
                            </tr>
                            )
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
          </Paper> 
      </div>
    );
}

export default DeliveryboyAssign;

