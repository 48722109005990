import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Map from '../google-map/map';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../loader/loader'

const columns = [
    { id: '1', label: 'Branch name', minWidth: 50,span: false },
    { id: '2', label: 'Address 1', minWidth: 50,span: false },
    // { id: '3', label: 'Country', minWidth: 50,span: false },
    // { id: '4', label: 'State', minWidth: 50,span: false },
    { id: '5', label: 'GSTIN', minWidth: 50,span: false },
    { id: '6', label: 'Mobile', minWidth: 50,span: false },
    { id: '7', label: 'Email', minWidth: 50,span: false },
    { id: '8', label: 'Website', minWidth: 50,span: false },
    { id: '8', label: 'Whatsapp No.', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];



function Branch(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")

  const [toggle,  setToggle] = useState(false)
  const [modal, setModal] = useState(false);
  const [age, setAge] = React.useState('');
  const [source, setSource] = React.useState([]);
  const [branchId, setBranchid] = React.useState(0);
  const [branchName, setBranchname] = React.useState("");
  const [address1, setAddress1] = React.useState("");
  const [address2, setAddress2] = React.useState("");
  const [address3, setAddress3] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [countrylist, setCountrylist] = React.useState([]);
  const [state, setState] = React.useState("");
  const [statelist, setStatelist] = React.useState([]);
  const [gstin, setGstin] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [whatsap, setWhatsap] = React.useState("");
  const [mobilepre, setMobilepre] = React.useState("");
  const [latitude, setLatitude] = React.useState(25.3548);
  const [longitude, setLongitude] = React.useState(51.1839);
  const [code, setCode] = React.useState("");
  const [caption, setCaption] = React.useState("");
  const [blocked, setBlocked] = React.useState(0);
  const [company, setCompany] = React.useState("");
  const [userId, setUserid] = React.useState("");
  const [companylist, setCompanylist] = React.useState([]);

  const [loader, setLoader] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    
    getData()
  }, []);


  const checkChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
      setBlocked(1)
    }
    else {
      setBlocked(0)
    }
    // do whatever you want with isChecked value
  }

  const childvalue = (latvalue, longvalue) => {
      mapValues(latvalue, longvalue)
  }

  const mapValues = (lat, long) => {
    setLatitude(lat)
    setLongitude(long)
  }

  const getData = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        console.log("companyid", company)
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        setLoader(true)

        const data = {
          "companyid": company,
          "type": userdata.user_type
        }
        console.log("data", data)
        fetch(global.url + "viewBranch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
          console.log("responseBranch", responseJson)
          if(responseJson.status == "false"){
            setLoader(false)
            setSource(responseJson.data)
          }
          else{
            setLoader(false)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const deleteClick = (id) =>  {
      console.log("id", id)
      const data = {
        "branchid": id,
      }
      fetch(global.url + "deleteBranch", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("response", responseJson)
          alert(responseJson.data)
          getData()
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const editClick = (
      branch_id, 
      branch_name,
      branch_company_id,
      branch_user_id,
      branch_address1,
      branch_address2,
      branch_address3,
      branch_country,
      branch_state,
      branch_gstin,
      branch_mobile,
      branch_email,
      branch_website,
      branch_whatsapp,
      branch_code,
      branch_caption,
      branch_isactive,
      latitude,
      longitude,
    ) => {
      setModal(true)
      setBranchid(branch_id)
      setBranchname(branch_name)
      setAddress1(branch_address1)
      setAddress2(branch_address2)
      setAddress3(branch_address3)
      setCountry(branch_country)
      setState(branch_state)
      setGstin(branch_gstin)
      setMobile(branch_mobile)
      setWhatsap(branch_whatsapp)
      setCaption(branch_caption)
      setCode(branch_code)
      setEmail(branch_email)
      setWebsite(branch_website)
      setBlocked(branch_isactive)
      setCompany(branch_company_id)
      setUserid(branch_user_id)
      setLatitude(parseFloat(latitude))
      setLongitude(parseFloat(longitude))
      getState(branch_country)
      getCountry()
      getCompany()
  }

  const getState = (id) => {
    const data = {
      "countryid": id,
    }
    fetch(global.url + "viewState", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      console.log("response",responseJson)
      if(responseJson.status == "false") {
        setStatelist(responseJson.data)
      }
       
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const getCountry = () => {
    fetch(global.url + "viewCountry", {
        method: "GET",
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        setCountrylist(responseJson.data)
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const getCompany = () => {
    const data = {
      "type": usertype,
      "user_id": userId,
    }
    console.log("view company data", data)
    fetch(global.url + "viewcompany", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      console.log("responsejson data", responseJson)
        setCompanylist(responseJson.data)
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const countryChange = (event) => {
    console.log("select", event.target.value)
    setCountry(event.target.value);
    getState(event.target.value)
  };

  const companyChange = (event) => {
    console.log("select", event.target.value)
    setCompany(event.target.value);
  };

  const stateChange = (event) => {
    setState(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onAdd = () => {
    setModal(true)
    setBranchid("")
    setBranchname("")
    setAddress1("")
    setAddress2("")
    setAddress3("")
    setCountry("")
    setState("")
    setGstin("")
    setMobile("")
    setWhatsap("")
    setCaption("")
    setCode("")
    setEmail("")
    setWebsite("")
    setBlocked(0)
    setCompany("")
    getState("")
    getCountry()
    getCompany()
    setLatitude(25.3548)
    setLongitude(51.1839)
}

  

  const onSave = () => {
    console.log("branch_id", branchId)
    console.log("branch_name", branchName)
    console.log("branch_company_id", company)
    console.log("branch_user_id", userId)
    console.log("branch_address1", address1)
    console.log("branch_address2", address2)
    console.log("branch_address3", address3)
    console.log("branch_country", country)
    console.log("branch_state", state)
    console.log("branch_gstin", gstin)
    console.log("branch_mobile", mobile)
    console.log("branch_email", email)
    console.log("branch_website", website)
    console.log("branch_whatsapp", whatsap)
    console.log("branch_latitude", latitude)
    console.log("branch_longitude", latitude)
    console.log("branch_code", code)
    console.log("branch_caption", caption)
    console.log("branch_isactive", blocked)

    const formData = new FormData();
    formData.append("branch_id", branchId)
    formData.append("branch_name", branchName)
    formData.append("branch_company_id", company)
    formData.append("branch_user_id", userId)
    formData.append("branch_address1", address1)
    formData.append("branch_address2", address2)
    formData.append("branch_address3", address3)
    formData.append("branch_country", country)
    formData.append("branch_state", state)
    formData.append("branch_gstin", gstin)
    formData.append("branch_mobile", mobile)
    formData.append("branch_email", email)
    formData.append("branch_website", website)
    formData.append("branch_whatsapp", whatsap)
    formData.append("branch_latitude", latitude)
    formData.append("branch_longitude", longitude)
    formData.append("branch_code", code)
    formData.append("branch_caption", caption)
    formData.append("branch_isactive", blocked)

    var userdata = JSON.parse(localStorage.getItem("user"))

    if(userdata.user_id == 0) {
      formData.append("branch_companyid", company)
    }
    else{
      formData.append("branch_companyid", usercompanyid)
    }
    
    fetch(global.url + "updateBranch", {
        method: "POST",
        body: formData,
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        console.log("responsejson", responseJson);
        if(responseJson.status == "false") {
          getData()
          setModal(false)
          alert(responseJson.data)
        }
        if(responseJson.status == "true") {
          alert(responseJson.data)
        }
    })
    .catch((error)=>{
        console.log(error)
        alert(error)
    })
  }
    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Branch List</p>
               <Button variant="contained" onClick={onAdd}>Add</Button>
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.branch_name}
                              </TableCell>
                              <TableCell >
                                  {row.branch_address1}
                              </TableCell>
                              <TableCell >
                                  {row.branch_gstin}
                              </TableCell>
                              <TableCell >
                                  {row.branch_mobile}
                              </TableCell>
                              <TableCell >
                                  {row.branch_email}
                              </TableCell>
                              <TableCell >
                                  {row.branch_website}
                              </TableCell>
                              <TableCell >
                                  {row.branch_whatsapp}
                              </TableCell>
                              <TableCell >
                                  <a href="javascript:void(0)" onClick={() => 
                                  editClick(
                                    row.branch_id, 
                                    row.branch_name, 
                                    row.branch_company_id, 
                                    row.branch_user_id, 
                                    row.branch_address1, 
                                    row.branch_address2, 
                                    row.branch_address3, 
                                    row.branch_country,
                                    row.branch_state,
                                    row.branch_gstin,
                                    row.branch_mobile,
                                    row.branch_email,
                                    row.branch_website,
                                    row.branch_whatsapp,
                                    row.branch_code,
                                    row.branch_caption,
                                    row.branch_isactive,
                                    row.branch_latitude,
                                    row.branch_longitude,
                                  )}>
                                    <FaIcons.FaEdit fontSize={20} color="blue" />
                                  </a>
                              </TableCell>
                              {/* <TableCell >
                                  <a href="javascript:void(0)" onClick={() => 
                                  deleteClick(row.branch_id)} >
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Branch Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={branchName} onChange={(e) => setBranchname(e.target.value)} id="outlined-basic" label="Branch name" variant="outlined"  size="small"/>
                        </FormControl>
                        <label style={{fontSize: 10}}>Required</label>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={address1} onChange={(e) => setAddress1(e.target.value)} id="outlined-basic" label="Address1" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={address2} onChange={(e) => setAddress2(e.target.value)} id="outlined-basic" label="Address2" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={address3} onChange={(e) => setAddress3(e.target.value)} id="outlined-basic" label="Address3" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12}>
                      <FormControl sx={{ mt: 3, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">Company</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={company}
                                label="Age"
                                onChange={companyChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {companylist.map((item, index) => (
                                  <MenuItem value={item.company_id}>{item.company_name}</MenuItem>
                                ))}
                            </Select>
                      </FormControl>
                      <label style={{fontSize: 10}}>Required</label>
                    </Col>
                    <Col xs={12}>
                      <FormControl sx={{ mt: 3, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">Country</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={country}
                                label="Age"
                                onChange={countryChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {countrylist.map((item, index) => (
                                  <MenuItem value={item.country_id}>{item.country_name}</MenuItem>
                                ))}
                            </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12}>
                      <FormControl sx={{ mt: 3, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">State</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={state}
                                label="Age"
                                onChange={stateChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {statelist.map((item, index) => (
                                  <MenuItem value={item.state_id}>{item.state_name}</MenuItem>
                                ))}
                            </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={gstin} onChange={(e) => setGstin(e.target.value)} id="outlined-basic" label="GSTIN" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={mobile} onChange={(e) => setMobile(e.target.value)} id="outlined-basic" label="Mobile" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={email} onChange={(e) => setEmail(e.target.value)} id="outlined-basic" label="Email" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={website} onChange={(e) => setWebsite(e.target.value)} id="outlined-basic" label="Website" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={whatsap} onChange={(e) => setWhatsap(e.target.value)} id="outlined-basic" label="Whats App No" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    {/* <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField inputProps={{ maxLength: 3 }} value={mobilepre} onChange={(e) => setMobilepre(e.target.value)} id="outlined-basic" label="Mobile Prefix (+91)" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col> */}
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={code} onChange={(e) => setCode(e.target.value)} id="outlined-basic" label="Branch Code" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={caption} onChange={(e) => setCaption(e.target.value)} id="outlined-basic" label="Caption" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>

                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <FormControlLabel control={
                            <Checkbox checked={blocked == 0? false : true} onChange={e => checkChange(e)} />
                            } label="Blocked" />
                        </FormControl>
                    </Col>

                    {/* <div style={{marginBottom: 70, marginTop: 30}}>
                        <Map
                            google={props.google}
                            center={{lat: latitude, lng: longitude}}
                            height='300px'
                            zoom={15}
                            parentCallback={childvalue}
                        />
                    </div> */}
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default Branch;