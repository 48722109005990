
import React, {useState, useEffect} from 'react';
import { Row, Col, Form  } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getFormGroupUtilityClass } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Map from '../../google-map/map';




function WizardFormFirstPage(props) {
    const childvalue = (latvalue, longvalue) => {
      props.mapValues(latvalue, longvalue)
    }
   
  return (
        <div>
            <Row>
                <Col xs={12} >
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="customercode"
                        onChange={props.updateform}
                        value={props.form.customercode} disabled id="outlined-basic" label="Code" variant="outlined"  size="small"/>
                    </FormControl>
                    <label style={{fontSize: 10}}>Required</label>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="customername"
                        onChange={props.updateform}
                        value={props.customername} id="outlined-basic" label="Customer name" variant="outlined"  size="small"/>
                    </FormControl>
                    <label style={{fontSize: 10}}>Required</label>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            {/* <InputLabel id="demo-simple-select-helper-label">Sub Group</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.ledger}
                                label="Age"
                                onChange={props.ledgerChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.ledgerList.map((item, index) => (
                                  <MenuItem value={item.group_id}>{item.group_name}</MenuItem>
                                ))}
                            </Select> */}
                            <TextField disabled value={props.subgroupname} id="outlined-basic" label="Sub Group" variant="outlined"  size="small"/>
                    </FormControl>
                    {/* <label style={{fontSize: 10}}>Required</label> */}
                    
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="address1"
                        onChange={props.updateform}
                        value={props.form.address1} id="outlined-basic" label="Address1" variant="outlined"  size="small"/>
                    </FormControl>
                    <label style={{fontSize: 10}}>Required</label>
                    
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="address2"
                        onChange={props.updateform}
                        value={props.form.address2} id="outlined-basic" label="Address2" variant="outlined"  size="small"/>
                    </FormControl>
                    <FormControl sx={{ mt: 4, width: '100%' }}>
                        <TextField name="address3"
                        onChange={props.updateform}
                        value={props.form.address3} id="outlined-basic" label="Address 3" variant="outlined"  size="small"/>
                    </FormControl>

                    {/* <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Representative</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.representative}
                                label="Age"
                                onChange={props.repChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.repList.map((item, index) => (
                                  <MenuItem value={item.rep_id}>{item.rep_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl> */}

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Price level</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.pricelevel}
                                label="Age"
                                onChange={props.pricelevelChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.pricelevellist.map((item, index) => (
                                  <MenuItem value={item.pricelevel_id}>{item.pricelevel_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>

                    

                    {/* <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Route</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.route}
                                label="Age"
                                onChange={props.routeChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.routeList.map((item, index) => (
                                  <MenuItem value={item.route_id}>{item.route_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl> */}

                    {/* <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Area</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.area}
                                label="Age"
                                onChange={props.areaChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.areaList.map((item, index) => (
                                  <MenuItem value={item.area_id}>{item.area_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl> */}

                    <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Country</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.country}
                                label="Age"
                                onChange={props.countryChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.countryList.map((item, index) => (
                                  <MenuItem value={item.country_id}>{item.country_name}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>
                    <label style={{fontSize: 10}}>Required</label>

                    {/* <FormControl sx={{ mt: 4, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">State</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.state}
                                label="Age"
                                onChange={props.stateChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.stateList.map((item, index) => (
                                  <MenuItem value={item.state_id}>{item.state_name}</MenuItem>
                                ))}
                            </Select>
                      </FormControl> */}

                      {/* <FormControl sx={{ mt: 4, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">District</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={props.district}
                                label="Age"
                                onChange={props.districtChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {props.districtList.map((item, index) => (
                                  <MenuItem value={item.district_id}>{item.district_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl> */}

                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField name="gst"
                        onChange={props.updateform}
                        value={props.form.gst} id="outlined-basic" label="Tax No." variant="outlined"  size="small"/>
                        </FormControl>
                        <label style={{fontSize: 10}}>Required</label>


                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField name="mobile"
                        onChange={props.updateform}
                        value={props.form.mobile} id="outlined-basic" label="Mobile" variant="outlined"  size="small"/>
                        </FormControl>
                        <label style={{fontSize: 10}}>Required</label>

                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField name="email"
                        onChange={props.updateform}
                        value={props.form.email} id="outlined-basic" label="Email" variant="outlined"  size="small"/>
                        </FormControl>
                        <label style={{fontSize: 10}}>Required</label>

                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField name="website"
                        onChange={props.updateform}
                        value={props.form.website} id="outlined-basic" label="Website" variant="outlined"  size="small"/>
                        </FormControl>

                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField name="whatsapp"
                        onChange={props.updateform}
                        value={props.form.whatsapp} id="outlined-basic" label="Whatsap" variant="outlined"  size="small"/>
                        </FormControl>

                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField name="openbalance"
                        onChange={props.updateform}
                       
                        value={props.form.openbalance}  type="number" id="outlined-basic" label="Opening Balance" variant="outlined"  size="small"/>
                        </FormControl>

                        <FormControl sx={{ mt: 4}} component="fieldset">
                        <RadioGroup
                            defaultValue="credit"
                            name="radio-buttons-group"
                            onClick={props.radioChange}
                        >
                            <FormControlLabel value="credit"  control={<Radio checked={props.decr == 0?  false: true}/>} label="Credit" color = '#999'  />
                            <FormControlLabel value="debit" color= '#999' control={<Radio checked={props.decr == 0?  true: false}/>} label="Debit" />
                        </RadioGroup>
                    </FormControl>

                        <FormControl sx={{ width: '100%' }}>
                            <FormControlLabel control={
                            <Checkbox checked={props.blocked == 0? false : true} onChange={e =>props.checkchange(e)} />
                            } label="Blocked" />
                        </FormControl>

                        <FormControl sx={{ width: '100%' }}>
                            <FormControlLabel control={
                            <Checkbox checked={props.enableBill == 0? false : true} onChange={e =>props.enableBillchange(e)} />
                            } label="Enable billwise entry" />
                        </FormControl>

                        <FormControl sx={{ mt: 4, width: '100%' }}>
                                <TextField name="creditLimit"
                            onChange={props.updateform}
                            value={props.form.creditLimit} type="number" id="outlined-basic" label="Credit Bill" variant="outlined"  size="small"/>
                        </FormControl> 
                        {/* <div style={{marginBottom: 70, marginTop: 30}}>
                            <Map
                                google={props.google}
                                center={{lat: props.latitude, lng: props.longitude}}
                                height='300px'
                                zoom={15}
                                parentCallback={childvalue}
                            />
                        </div> */}
                </Col>
                
            </Row>
        </div>
  );
}

export default WizardFormFirstPage;
