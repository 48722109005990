
import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Form  } from "react-bootstrap";
import Map from '../../google-map/map';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';





function Productothers(props) {
    const [value, setvalue] = React.useState('');
    
console.log("tablerow", props.tableRow)
  return (
        <div>
            {/* <Row>
                <Col xs={12} md={2} style={{marginTop: 30}}>
                    <FormControl sx={{width: '100%' }}>
                        <InputLabel id="demo-simple-select-helper-label">Discount Unit</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={props.discountUnit}
                            label="Age"
                            onChange={props.discountUnitchange}
                            size="small"
                            
                        >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {props.unitList.map((item, index) => (
                            <MenuItem value={item.unit_id}>{item.unit_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>
                <Col xs={12} md={2} style={{marginTop: 30}}>
                        <FormControl >
                            <TextField  value={props.discount} onChange={(e) => props.setDiscount(e.target.value)} id="outlined-basic" label="Discount" variant="outlined"  size="small"/>
                        </FormControl>
                </Col>
                <Col xs={12} md={3} style={{marginTop: 30}}>
                    <FormControl >
                        <TextField  value={props.purchaseprice} onChange={(e) => props.setPurchaseprice(e.target.value)} id="outlined-basic" label="Purchase price" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                <Col xs={12} md={2} style={{marginTop: 30}}>
                    <FormControl >
                        <TextField  value={props.salesPrice} onChange={(e) => props.setSalesprice(e.target.value)} id="outlined-basic" label="Sales price" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                <Col xs={12} md={3} style={{marginTop: 30}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            value={props.expiryDate}
                            onChange={(newValue) => {
                                props.setExpirydate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Col>
            </Row> */}
            {/* <Row>
                <Col md={2} xs={12} style={{marginTop: 30}}>
                    <Button onClick={props.addOthers} style={{float: 'right'}} variant="contained">Add</Button>
                </Col>
            </Row> */}
            <Row style={{marginTop: 20}} className="productbatchborder">
                <Col xs={12} >
                    <h4>Supplier List</h4>
                <div>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight: 'bold'}}>
                                   Supplier code
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Supplier name
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Supplier contact number
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    GSTIN
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Actions
                                </TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {props.supplierList
                                .map((row, index) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.key}>
                                        <TableCell >
                                            {row.supplier_code}
                                        </TableCell>
                                        <TableCell >
                                            {row.supplier_name}
                                        </TableCell>
                                        <TableCell >
                                            {row.supplier_mobile}
                                        </TableCell>
                                        <TableCell >
                                            {row.supplier_gstin}
                                        </TableCell>
                                        <TableCell >
                                            <a href="javascript:void(0)" onClick={() => props.deleteSupplier(row.psupplier_id)}>
                                                <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Row>
                        
                        <Col xs={12} md={4} style={{marginTop: 30}}>
                            <FormControl sx={{width: '100%' }}>
                                    <InputLabel id="demo-simple-select-helper-label">Supplier Select</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={props.mainsupplier}
                                        label="Age"
                                        onChange={props.mainsupplierChange}
                                        size="small"
                                        
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {props.mainsupplierlist.map((item, index) => (
                                        <MenuItem value={item.supplier_id}>{item.supplier_name}</MenuItem>
                                        ))}
                                    </Select>
                            </FormControl>
                        </Col>
                        <Col md={2} xs={12} style={{marginTop: 30}}>
                            <Button onClick={props.addSupplier} style={{float: 'right'}} variant="contained">Add</Button>
                        </Col>
                        <Col md={2} xs={12} style={{marginTop: 30}}>
                            <a href="">
                                <Button  style={{float: 'right'}} variant="contained">Add Supplier</Button>
                            </a>
                        </Col>
                    </Row>
                    
                    {/* <Row>
                        <Col xs={12} md={2} style={{marginTop: 30}}>
                            <FormControl >
                                <TextField  value={props.supplierCode} onChange={(e) => props.setDiscount(e.target.value)} id="outlined-basic" label="Base Qty" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row> */}
                </div>
                </Col>
            </Row>
        </div>
  );
}

export default Productothers;
