
import React, {useState, useEffect} from 'react';
import { Row, Col, Form  } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getFormGroupUtilityClass } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Map from '../../google-map/map';
import { MultiSelect } from "react-multi-select-component";
import Input from '@mui/material/Input';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import BtnLoader from '../../loader/btnLoader';
import Loader from '../../loader/loader';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";




function Defaultprice(props) {
   console.log("defaulttablerow", props.defaulttablerow)
  return (
        <div>
            <Row className="productbatchborder">
                {props.defaulttablerow != undefined ? 
                 <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                               
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Color
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Size
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Image
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Expiry Date
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Purchase price
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Sales rate
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    MRP
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Stock
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}} colSpan={2}>
                                    Actions
                                </TableCell>
                            </TableRow>
                            </TableHead>
                            
                            <TableBody>
                            {props.batchlistloader ? 
                            <Row>
                                <Col xs={12}>

                            <Loader />
                            </Col>
                            </Row> :
                                    <TableRow  tabIndex={-1} >
                                        <TableCell >
                                            {props.defaulttablerow.color_name}
                                        </TableCell>
                                        <TableCell >
                                            {props.defaulttablerow.size_name}
                                        </TableCell>
                                        <TableCell >
                                        { props.defaulttablerow.pe_details
                                              .map((item) => {
                                                return(
                                                          <img src={item.pei_image} style={{width: 60, marginLeft: 10}}/>
                                                )}
                                              )
                                          }
                                        </TableCell>
                                        <TableCell >
                                            {props.defaulttablerow.pb_expiry_date}
                                        </TableCell>
                                        <TableCell >
                                            {props.defaulttablerow.pb_purchaseprice}
                                        </TableCell>
                                        <TableCell >
                                            {props.defaulttablerow.pb_salesrate}
                                        </TableCell>
                                        <TableCell >
                                            {props.defaulttablerow.pb_mrp}
                                        </TableCell>
                                        <TableCell >
                                            {props.defaulttablerow.pb_stock}
                                        </TableCell>
                                        <TableCell >
                                            <a href="javascript:void(0)" onClick={() => props.deleteBatch(props.defaulttablerow.pb_id)}>
                                                <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                            </a>
                                        </TableCell>
                                        <TableCell >
                                            <a href="javascript:void(0)" onClick={() => props.editbatch(
                                                props.defaulttablerow.pb_id, 
                                                props.defaulttablerow.pb_batch,
                                                props.defaulttablerow.pb_unit,
                                                props.defaulttablerow.pb_color_fk,
                                                props.defaulttablerow.pb_size_fk,
                                                props.defaulttablerow.pb_image,
                                                props.defaulttablerow.pb_expiry_date,
                                                props.defaulttablerow.pb_purchaseprice,
                                                props.defaulttablerow.pb_salesrate,
                                                props.defaulttablerow.pb_mrp,
                                                props.defaulttablerow.pb_stock)}>
                                                <FaIcons.FaEdit  color="blue" fontSize={20}  />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer> :
                    <Row>
                    <Col xs={12} md={2} style={{marginTop: 20}}>
                        <FormControl >
                            <TextField autoComplete="off" type="number"  value={props.purchaseprice} onChange={(e) => props.setPurchase(e.target.value)} id="outlined-basic" label="P.rate" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={1} style={{marginTop: 20}}>
                        <FormControl >
                            <TextField autoComplete="off" type="number" value={props.salesrate} onChange={(e) => props.setSales(e.target.value)} id="outlined-basic" label="S.rate" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={1} style={{marginTop: 20}}>
                        <FormControl >
                            <TextField autoComplete="off" type="number" value={props.mrp} onChange={(e) => props.setMrp(e.target.value)} id="outlined-basic" label="MRP" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={1} style={{marginTop: 20}}>
                        <FormControl >
                            <TextField autoComplete="off" type="number" value={props.stock} onChange={(e) => props.setStock(e.target.value)} id="outlined-basic" label="Stock" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
    
                    <Col sm={1}>
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">Color</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={props.batchcolor}
                                    label="Age"
                                    onChange={props.batchcolorChange}
                                    size="small"
                                    
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {props.colorlist.map((item, index) => (
                                    <MenuItem value={item.color_id}>{item.color_name}</MenuItem>
                                    ))}
                                </Select>
                        </FormControl>
                    </Col>
                    <Col sm={1}>
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">Size</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={props.batchsize}
                                    label="Age"
                                    onChange={props.batchsizeChange}
                                    size="small"
                                    
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {props.sizelist.map((item, index) => (
                                    <MenuItem value={item.size_id}>{item.size_name}</MenuItem>
                                    ))}
                                </Select>
                        </FormControl>
                    </Col>
                    
                    <Col sm={2} style={{marginTop: 20}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Expiry Date"
                                value={props.batchexpirydate}
                                onChange={(newValue) => {
                                props.setBatchexpirydate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Col>
                    <Col sm={3}>
                    {
                        props.batchid == "" ? 
                        <div>
                            <label>Image</label>
                            <input type="file" multiple  onChange={(e) => props.batchfile(e)}  style={{marginTop: 10}}/>
                        </div>
                        : 
                        null
                    }
                        {/* <img style={{marginTop: 10, width: 40}} src={props.file? URL.createObjectURL(props.file) : null} /> */}
                    </Col>
                    <Col md={9} xs={12} style={{marginTop: 20}}>
                        {/* {props.batchbtnloader ? 
                            <BtnLoader  style={{float: 'right'}}/>:
                            <div style={{float: 'right'}}>
                                    
                                {
                                    props.batchid == "" ? null : 
                                    <Button onClick={props.clearBatch} style={{marginRight: 10}}  variant="contained">Clear</Button>
                                }
                                <Button onClick={props.addBatch}  variant="contained">{props.batchid == "" ? "Add": "Update"}</Button>
                                
                            </div>
                        } */}
                    </Col>
                    <Col sm={6} style={{display: 'inline-flex'}}>
                        { props.viewimage
                                .map((item) => {
                                return(
                                    <div className="cardimage" style={{position: 'relative'}}> 
                                        <img className="productImagelist" src={item.pei_image} />
                                        <Button onClick={() =>props.productImagedelete(item.pei_id) } className="imageDeletebtn" variant="contained">Delete</Button>
                                    </div>
                                )}
                                )
                            }
                    </Col>
                    <Col sm={3}>
                    {
                        props.batchid == "" ? null : 
                        <div>
                            <label>Image</label>
                            <input type="file"  onChange={(e) => props.fileSingleselectedHandler(e)}  style={{marginTop: 10}}/>
                            <Button onClick={props.uploadimage} style={{marginTop: 20}}  variant="contained">Upload</Button>
                        </div>
                    }
                </Col>
                <Col sm={3} >
                {props.batchbtnloader ? 
                        <BtnLoader  style={{float: 'right'}}/>:
                        <div style={{float: 'right'}}>
                                
                            {
                                props.batchid == "" ? null : 
                                <Button onClick={props.clearBatch} style={{marginRight: 10}}  variant="contained">Clear</Button>
                            }
                            <Button onClick={() => props.addBatch("single")}  variant="contained">{props.batchid == "" ? "Add": "Update"}</Button>
                            
                        </div>
                    }
                </Col>
                    </Row>
                }
                {
                   props.viewshow ?
               
                 <Row>
                    <Col xs={12} md={2} style={{marginTop: 20}}>
                        <FormControl >
                            <TextField autoComplete="off" type="number"  value={props.purchaseprice} onChange={(e) => props.setPurchase(e.target.value)} id="outlined-basic" label="P.rate" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={1} style={{marginTop: 20}}>
                        <FormControl >
                            <TextField autoComplete="off" type="number" value={props.salesrate} onChange={(e) => props.setSales(e.target.value)} id="outlined-basic" label="S.rate" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={1} style={{marginTop: 20}}>
                        <FormControl >
                            <TextField autoComplete="off" type="number" value={props.mrp} onChange={(e) => props.setMrp(e.target.value)} id="outlined-basic" label="MRP" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={1} style={{marginTop: 20}}>
                        <FormControl >
                            <TextField autoComplete="off" type="number" value={props.stock} onChange={(e) => props.setStock(e.target.value)} id="outlined-basic" label="Stock" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
    
                    <Col sm={1}>
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">Color</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={props.batchcolor}
                                    label="Age"
                                    onChange={props.batchcolorChange}
                                    size="small"
                                    
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {props.colorlist.map((item, index) => (
                                    <MenuItem value={item.color_id}>{item.color_name}</MenuItem>
                                    ))}
                                </Select>
                        </FormControl>
                    </Col>
                    <Col sm={1}>
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">Size</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={props.batchsize}
                                    label="Age"
                                    onChange={props.batchsizeChange}
                                    size="small"
                                    
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {props.sizelist.map((item, index) => (
                                    <MenuItem value={item.size_id}>{item.size_name}</MenuItem>
                                    ))}
                                </Select>
                        </FormControl>
                    </Col>
                    
                    <Col sm={2} style={{marginTop: 20}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Expiry Date"
                                value={props.batchexpirydate}
                                onChange={(newValue) => {
                                props.setBatchexpirydate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Col>
                    <Col sm={3}>
                    {
                        props.batchid == "" ? 
                        <div>
                            <label>Image</label>
                            <input type="file" multiple  onChange={(e) => props.batchfile(e)}  style={{marginTop: 10}}/>
                        </div>
                        : 
                        null
                    }
                        {/* <img style={{marginTop: 10, width: 40}} src={props.file? URL.createObjectURL(props.file) : null} /> */}
                    </Col>
                    <Col md={9} xs={12} style={{marginTop: 20}}>
                        {/* {props.batchbtnloader ? 
                            <BtnLoader  style={{float: 'right'}}/>:
                            <div style={{float: 'right'}}>
                                    
                                {
                                    props.batchid == "" ? null : 
                                    <Button onClick={props.clearBatch} style={{marginRight: 10}}  variant="contained">Clear</Button>
                                }
                                <Button onClick={props.addBatch}  variant="contained">{props.batchid == "" ? "Add": "Update"}</Button>
                                
                            </div>
                        } */}
                    </Col>
                    <Col sm={6} style={{display: 'inline-flex'}}>
                        { props.viewimage
                                .map((item) => {
                                return(
                                    <div className="cardimage" style={{position: 'relative'}}> 
                                        <img className="productImagelist" src={item.pei_image} />
                                        <Button onClick={() =>props.productImagedelete(item.pei_id) } className="imageDeletebtn" variant="contained">Delete</Button>
                                    </div>
                                )}
                                )
                            }
                    </Col>
                    <Col sm={3}>
                    {
                        props.batchid == "" ? null : 
                        <div>
                            <label>Image</label>
                            <input type="file"  onChange={(e) => props.fileSingleselectedHandler(e)}  style={{marginTop: 10}}/>
                            <Button onClick={props.uploadimage} style={{marginTop: 20}}  variant="contained">Upload</Button>
                        </div>
                    }
                </Col>
                <Col sm={3} >
                {props.batchbtnloader ? 
                        <BtnLoader  style={{float: 'right'}}/>:
                        <div style={{float: 'right'}}>
                                
                            {
                                props.batchid == "" ? null : 
                                <Button onClick={props.clearBatch} style={{marginRight: 10}}  variant="contained">Clear</Button>
                            }
                            <Button onClick={() => props.addBatch("single")}  variant="contained">{props.batchid == "" ? "Add": "Update"}</Button>
                            
                        </div>
                    }
                </Col>
                    </Row>
                     : null
                     }
                
               
                
            </Row>

        </div>
  );
}

export default Defaultprice;
