import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Form  } from "react-bootstrap";
import Map from '../../google-map/map';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import WizardFormFirstPage from './FirstPage';
import WizardFormSecondPage from './SecondPage';
import Loader from '../../loader/loader';

const columns = [
    { id: '1', label: 'Code', minWidth: 50,span: false },
    { id: '3', label: 'Ledger Name', minWidth: 50,span: false },
    { id: '4', label: 'Name', minWidth: 50,span: false },
    { id: '7', label: 'Address', minWidth: 50,span: false },
    { id: '8', label: 'GSTIN', minWidth: 50,span: false },
    { id: '10', label: 'Mobile', minWidth: 50,span: false },
    { id: '11', label: 'Email', minWidth: 50,span: false },
    { id: '12', label: 'Website', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];
    
  const rows = [
    {
      company: 'Lorem Ipsum',

    },
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
  ];

  const dates = [
      {
        company: 'Lorem Ipsum'
      },
      {
        company: 'Lorem Ipsum'
      }
  ]

function Supplier(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [modal, setModal] = useState(false);
    const [editMode, setEditmode] = useState(false);
    const [value, setValue] = React.useState('1');
    const [ledgerId, setLedgerid] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [form, setForm] = useState({
        suppliercode: "",
        suppliername: "",
        address1: "",
        address2: "",
        address3: "",
        gst: "",
        mobile: "",
        whatsapp: "",
        email: "",
        website: "",
        creditLimit: 0,
        openbalance: 0,
        license: ""
      })
    const [blocked, setBlocked] = useState(0)
    const [enableBill, setEnablebill] = useState(0)
    const [count, setCount] = useState(1)
    const [supplierId, setSupplierid] = useState("")
    const [customer, setCustomer] = useState("")
    const [source, setSource] = React.useState([]);
    const [userId, setUserid] = React.useState('');
    const [loader, setLoader] = React.useState(false);
    const [state, setState] = React.useState("");
    const [stateList, setStatelist] = React.useState([]);
    const [district, setDistrict] = React.useState("");
    const [districtList, setDistrictlist] = React.useState([]);
    const [ledger, setLedger] = React.useState("");
    const [ledgerList, setledgerlist] = React.useState([]);
    const [subgroupname, setSubgroupname] = React.useState("");
    const [country, setCountry] = React.useState('');
    const [countryList, setCountryList] = React.useState([]);
    const [area, setArea] = React.useState("");
    const [areaList, setArealist] = React.useState([]);
    const [route, setRoute] = React.useState("");
    const [routeList, setRoutelist] = React.useState([]);
    const [promoter, setPromoter] = React.useState("");
    const [promoterList, setpromoterList] = React.useState([]);
    const [latitude, setLatitude] = React.useState(25.3548);
    const [longitude, setLongitude] = React.useState(51.1839);
    const [decr, setDecr] = React.useState(0);
    const [date, setDate] = React.useState(null);
    const [expirydate, setExpirydate] = React.useState(new Date);
    const [billNo, setBillno] = React.useState("");
    const [recievable, setRecievable] = React.useState("");
    const [payable, setPayable] = React.useState("");
    const [tableRow, setTableRow] = React.useState([]);

    const [docmodal, setDocmodal] = React.useState(false);
    const [supplierlic, setSupplierlic] = React.useState("");

    

    

    const [image, setImage] = React.useState();

    const addRow = () => {
      console.log("form.ledgerId", supplierId)
      var data = {
        "supplier_id":  supplierId,
        "bill_date":  date.toISOString().split('T')[0],
        "supplier_user_id":  userId,
        "reference_number":  billNo,
        "bill_payable":  payable,
        "bill_receivable":  recievable,
      }
      console.log("data", data)
      fetch(global.url + "updateSupplierBill", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response", responseJson)
            if(responseJson.status == "false") {
              getSupplierbill(supplierId)
              setPayable("")
              setRecievable("")
              setBillno("")
              console.log("response", responseJson.data)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const radioChange = (event) => {
    console.log(event.target.value, "event.target.value")
    if(event.target.value == "credit") {
       setDecr(1)
    }
    else {
      setDecr(0)
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const updateForm = (e) => {
    setForm({
    ...form,
    [e.target.name]: e.target.value,
    })
  }

  const checkChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
        setBlocked(1)
    }
    else {
        setBlocked(0)
    }
  }

  const enableBillchange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
        setEnablebill(1)
    }
    else {
        setEnablebill(0)
    }
  }

  useEffect(() => {
    getData()
    getCountry()
    getPromoter()
    getLedger()
    getRoute()
    getArea()
    getSuppliercode()

  }, []);

  const getSuppliercode = () =>{
      fetch(global.url + "getSuppliercode", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("response customer", responseJson)
          // setLoader(false)
          if(responseJson.error == false) {
            const newValues = { ...form }
            newValues['suppliercode']= responseJson.data.suppliercode
            newValues['suppliername']= ""
            newValues['address1']= ""
            newValues['address2']= ""
            newValues['address3']= ""
            newValues['gst']= ""
            newValues['mobile']= ""
            newValues['whatsapp']= ""
            newValues['email']= ""
            newValues['website']= ""
            newValues['creditLimit']= 0
            newValues['openbalance']= 0
            setForm(newValues)
          }
        
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getLedger = () =>{
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      "companyid":  company,
      "type":  userdata.user_type
    }
    fetch(global.url + "viewGroup", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          if(responseJson.status == "false") {
            setledgerlist(responseJson.data)
            setLedger(responseJson.data[13].group_id)
            setSubgroupname(responseJson.data[13].group_name)
            // console.log("response", responseJson.data)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getRoute = () => {
    fetch(global.url + "viewRoute", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        if(responseJson.status == "true") {
          setRoutelist([])
        }
        else{
          setRoutelist(responseJson.data)
        }
        
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const getArea = () => {
    fetch(global.url + "viewArea", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        if(responseJson.status == "true") {
          setArealist([])
        }
        else{
          setArealist(responseJson.data)
        }
        
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const getPromoter = () => { 
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUserid(userdata.user_id)
    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewPromoter", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          if(responseJson.status == "false") {
            setpromoterList(responseJson.data)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getData = () => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewSupplier", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          setLoader(false)
          if(responseJson.Error == "false") {
            setSource(responseJson.data)
          }
          if(responseJson == "true"){
            alert(responseJson.data)
            
          }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

    const countryChange = (event) => {
      setCountry(event.target.value);
      getState(event.target.value)
    };

    const ledgerchange = (event) => {
      setLedger(event.target.value);
    };

    const routeChange = (event) => {
      setRoute(event.target.value);
    };

    const areaChange = (event) => {
      setArea(event.target.value);
    };

    const promoterChange = (event) => {
      setPromoter(event.target.value);
    };

    const stateChange = (event) => {
      setState(event.target.value);
      getDistrict(event.target.value)
    };

    const districtChange = (event) => {
      setDistrict(event.target.value);
    };

    const getDistrict = (id) => {
      var data = {
        "countryid": id
      }
      fetch(global.url + "viewDistrict", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        if(responseJson.status == "false") {
          setDistrictlist(responseJson.data)
        }
        
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    const getState = (id) => {
      var data = {
        "countryid": id
      }
      fetch(global.url + "viewState", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          if(responseJson.status == "false") {
            setStatelist(responseJson.data)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
    }

  const getCountry = () => {
    fetch(global.url + "viewCountry", {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        },
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      if(responseJson.status == "false") {
        setCountryList(responseJson.data)
      }
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const onNext = () => {
    if(count == 1 && editMode == false) {
        var data = {
          "user_id": userId,
          "supplier_id": supplierId,
          "supplier_code": form.suppliercode,
          "ledger_group": ledger,
          "supplier_name": form.suppliername,
          "supplier_address1": form.address1,
          "supplier_address2": form.address2,
          "supplier_address3": form.address3,
          "supplier_country": country,
          "supplier_state": state,
          "supplier_district": district,
          "supplier_gstin": form.gst,
          "supplier_mobile": form.mobile,
          "supplier_email": form.email,
          "supplier_website": form.website,
          "supplier_whatsapp": form.whatsapp,
          "supplier_promoter": promoter,
          "supplier_route": route,
          "supplier_area": area,
          "supplier_open_balance": form.openbalance,
          "supplier_decr": decr,
          "supplier_credit_limit": form.creditLimit,
          "supplier_isbillwise_entry": enableBill,
          "supplier_isactive": blocked,
          "supplier_latitude": latitude,
          "supplier_longitude": longitude,
          "supplier_branchid" : userbranchid,
          "supplier_companyid" : usercompanyid,
          "supplier_expirydate": expirydate
        }
        console.log("data", data)

        fetch(global.url + "updateSupplier", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            if(responseJson.error == false) {
              setCount(count + 1)
              setSupplierid(responseJson.data) 
              alert(responseJson.message)
              getData()
              setModal(false)
            }
            if(responseJson.Error == "true") {
              alert(responseJson.message)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    else if(editMode == true) {
      setCount(count + 1)
    }
  }

  const onSuppliersave = () => {
    var data = {
      "user_id": userId,
      "supplier_id": supplierId,
      "supplier_code": form.suppliercode,
      "supplier_ledger": ledger,
      "supplier_name": form.suppliername,
      "supplier_address1": form.address1,
      "supplier_address2": form.address2,
      "supplier_address3": form.address3,
      "supplier_country": country,
      "supplier_state": state,
      "supplier_district": district,
      "supplier_gstin": form.gst,
      "supplier_mobile": form.mobile,
      "supplier_email": form.email,
      "supplier_website": form.website,
      "supplier_whatsapp": form.whatsapp,
      "supplier_promoter": promoter,
      "supplier_route": route,
      "supplier_area": area,
      "supplier_open_balance": form.openbalance,
      "supplier_decr": decr,
      "supplier_credit_limit": form.creditLimit,
      "supplier_isbillwise_entry": enableBill,
      "supplier_isactive": blocked,
      "supplier_latitude": latitude,
      "supplier_longitude": longitude,
      "user_branch_id" : userbranchid,
      "user_company_id" : usercompanyid
    }
    console.log("data", data)

    fetch(global.url + "updateSupplier", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        if(responseJson.Error == "false") {
          alert(responseJson.data)
          getData()
        }
        if(responseJson.Error == "true") {
          alert(responseJson.message)
        }
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const mapValues = (lat, long) => {
    setLatitude(lat)
    setLongitude(long)
  }

  const uploadImage = (e) => {
    console.log("files", e.target.files[0])
    setImage(e.target.files[0])
  }

  const editClick = (
    supplierid, 
    suppliercode,
    customer,
    supplierledger,
    suppliername,
    address1,
    address2,
    address3,
    country,
    state,
    district,
    gst,
    mobile,
    email,
    website,
    whatsap,
    promoter,
    route,
    area,
    openbalance,
    customertype,
    creditlimit,
    billwise,
    isactive,
    latitude,
    longitude,
  ) => {
    setModal(true)
    setSupplierid(supplierid)
    const newValues = { ...form }
    newValues['suppliercode']= suppliercode
    newValues['suppliername']= suppliername
    newValues['address1']= address1
    newValues['address2']= address2
    newValues['address3']= address3
    newValues['gst']= gst
    newValues['mobile']= mobile
    newValues['whatsapp']= whatsap
    newValues['email']= email
    newValues['website']= website
    newValues['creditLimit']= creditlimit
    newValues['openbalance']= openbalance
    getState(country)
    getDistrict(state)
    setForm(newValues)
    setEditmode(true)
    setCountry(country)
    setState(state)
    setDistrict(district)
    setEnablebill(billwise)
    setDecr(customertype)
    setBlocked(isactive)
    setPromoter(promoter)
    setLatitude(parseFloat(latitude))
    setLongitude(parseFloat(longitude)) 
    setRoute(route)
    setArea(area)
    setCustomer(customer)
    setLedger(supplierledger)
    getSupplierbill(supplierid)
    
    
  }

  const onAdd = () => {
    setModal(true)
    setSupplierid("")
    const newValues = { ...form }
    newValues['suppliercode']= ""
    newValues['suppliername']= ""
    newValues['address1']= ""
    newValues['address2']= ""
    newValues['address3']= ""
    newValues['gst']= ""
    newValues['mobile']= ""
    newValues['whatsapp']= ""
    newValues['email']= ""
    newValues['website']= ""
    newValues['creditLimit']= 0
    newValues['openbalance']= 0
    getState()
    setSupplierid("")
    setForm(newValues)
    setEditmode(false)
    setCountry("")
    setState("")
    setDistrict("")
    setEnablebill(0)
    setDecr(0)
    setBlocked(0)
    setPromoter("")
    setLatitude(25.3548)
    setLongitude(51.1839)
    setRoute("")
    setArea("")
    setLedger("")
    getSuppliercode()
  }

  const uploadDoc = () => {
    const formData = new FormData();
    formData.append('licenseno', image);
    formData.append('supplier_id', supplierId);
    fetch(global.url + "addSupplierLicenseno", {
      method: "POST",
      body: formData,
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        if(responseJson.error == false){
          alert(responseJson.message)
          setDocmodal(false)
        }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getSupplierbill = (id) => {
    console.log("supplier_bill_id", id)
      var data = {
        "supplier_bill_id":  id
      }
      fetch(global.url + "viewSupplierBill", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            // console.log("bill response", responseJson)
            // setLoader(false)
            if(responseJson.status == "false") {
              setTableRow(responseJson.data)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
  }

 

  const onhide = () => {
    setEditmode(false)
    setModal(false)
    setCount(1)
    setTableRow([])
  }

  const deleteClick = (id) =>  {
    var data = {
      "supplier_id": id,
      "user_id": userId
    }
    fetch(global.url + "deleteSupplier", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        alert(responseJson.data)
        getData()
    })
    .catch((error)=>{
        console.log(error)
    })
  }
  
  const opendocmodal = (id, doc) => {
    setDocmodal(true)
    setSupplierid(id)
    setSupplierlic(doc)
  }

  const deleteBill = (id) => {
      var data = {
        "supplier_bill_id": id,
        "user_id": userId
      }
      fetch(global.url + "deleteSupplierBill", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          alert(responseJson.data)
          getSupplierbill(supplierId)
          
      })
      .catch((error)=>{
          console.log(error)
      })
  }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Supplier List</p>
               {
                        usertype == "superadmin" ? 
                        null : 
                      <Button variant="contained" style={{backgroundColor: '#7e7e7e'}} onClick={onAdd}>Add</Button>
              }
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.supplier_code}
                              </TableCell>
                              <TableCell >
                                  {row.ledger_name}
                              </TableCell>
                              <TableCell >
                                  {row.supplier_name}
                              </TableCell>
                              <TableCell >
                                  {row.supplier_address1}
                              </TableCell>
                              <TableCell >
                                  {row.supplier_gstin}
                              </TableCell>
                              <TableCell >
                                  {row.supplier_mobile}
                              </TableCell>
                              <TableCell >
                                  {row.supplier_email}
                              </TableCell>
                              <TableCell >
                                  {row.supplier_website}
                              </TableCell>
                              <TableCell >
                              {
                                usertype == "superadmin" ? 
                                null : 
                                <a href="javascript:void(0)" onClick={() => 
                                    editClick(
                                        row.supplier_id, 
                                        row.supplier_code, 
                                        row.group_id,
                                        row.supplier_ledger_fk, 
                                        row.supplier_name, 
                                        row.supplier_address1,
                                        row.supplier_address2, 
                                        row.supplier_address3,
                                        row.supplier_country,
                                        row.supplier_state,
                                        row.supplier_district,
                                        row.supplier_gstin,
                                        row.supplier_mobile,
                                        row.supplier_email,
                                        row.supplier_website,
                                        row.supplier_whatsapp,
                                        row.supplier_promoter_fk,
                                        row.supplier_route_fk,
                                        row.supplier_area_fk,
                                        row.supplier_open_balance,
                                        row.supplier_decr,
                                        row.supplier_credit_limit,
                                        row.supplier_isbillwise_entry,
                                        row.supplier_isactive,
                                        row.supplier_latitude,
                                        row.supplier_longitude,
                                    )}>
                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                    </a>
                                }
                              </TableCell>
                              <TableCell >
                                {
                                  row.supplier_licenseno != "" &&  row.supplier_licenseno != null ? 
                                  <Button variant="contained" onClick={() => opendocmodal(row.supplier_id, row.supplier_licenseno)}>View LIC Document</Button>: 
                                  <Button variant="contained" onClick={() => opendocmodal(row.supplier_id, row.supplier_licenseno)}>Add LIC Document</Button>
                                }
                              
                              </TableCell>
                              {/* <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.supplier_id)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={onhide}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Supplier Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                      <form
                        className="col-12 form"
                        onSubmit={() =>
                          alert(
                            `Submitted Email: ${form.email} Name: ${form.name} Password: ${form.password}`
                          )
                        }
                      >
                        {count === 1 ? (
                          <WizardFormFirstPage 
                          
                                form={form} 
                                blocked = {blocked} 
                                enableBill = {enableBill}
                                enableBillchange = {enableBillchange}
                                countryChange = {countryChange}
                                stateChange ={stateChange}
                                districtChange ={districtChange}
                                ledgerChange ={ledgerchange}
                                routeChange ={routeChange}
                                areaChange ={areaChange}
                                promoterChange ={promoterChange}
                                state ={state}
                                stateList= {stateList}
                                district = {district}
                                districtList= {districtList}
                                ledger = {ledger}
                                subgroupname={subgroupname}
                                ledgerList ={ledgerList}
                                promoter = {promoter}
                                promoterList ={promoterList}
                                route = {route}
                                routeList ={routeList}
                                area = {area}
                                areaList ={areaList}
                                country ={country}
                                countryList ={countryList}
                                updateform = {updateForm} 
                                checkchange = {checkChange} 
                                decr = {decr}
                                radioChange = {radioChange}
                                latitude = {latitude}
                                setLatitude = {setLatitude}
                                longitude = {longitude}
                                setLongitude = {setLongitude}
                                mapValues = {mapValues}

                                expirydate={expirydate}
                                setExpirydate = {setExpirydate}
                                uploadImage ={uploadImage}
                                uploadDoc = {uploadDoc}
                          />
                        ) : null}
                        {count === 2 ? (
                          <WizardFormSecondPage 
                                date={date} 
                                billNo = {billNo} 
                                recievable = {recievable} 
                                payable = {payable} 
                                tableRow = {tableRow} 
                                setDate ={setDate}
                                setBillno= {setBillno}
                                setRecievable= {setRecievable}
                                setPayable={setPayable}
                                setTableRow={setTableRow}
                                addRow = {addRow}
                                deleteBill = {deleteBill}
                          />
                        ) : null}
                      </form>
                      <Col xs ={12} style={{marginTop:30}}>
                          { count == 1 && editMode == false ? 
                            <Button onClick={onNext}  style={{float: 'right'}} variant="contained">Next</Button> :
                            count == 1 && editMode == true ?
                            <div>
                              <Button onClick={onNext}  style={{float: 'right'}} variant="contained">Next</Button>
                              <Button onClick={onSuppliersave}  style={{float: 'right', marginRight: 20}} variant="contained">Save</Button>
                            </div>
                             : null
                          }
                        <Button onClick={() => setCount(count - 1)}
                        disabled={count < 2} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Back</Button>
                    </Col>
                </Box>
              </Modal.Body>
           </Modal>

           {/* uploaddocuments */}

           <Modal
              size="lg"
              show={docmodal}
              onHide={() => setDocmodal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Upload Documents
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                      <div style={{marginTop: 30}}>
                          <Row>
                              <Col xs={12}>
                                    <img src={global.images + "ecommerce_photos/" + supplierlic} style={{height: 20, width: 20}}/>
                              </Col>
                              <Col xs={12} md={3} style={{marginTop: 20}}>
                                  <input type="file"  onChange={(e) => uploadImage(e)}  name="file"  />
                              </Col>
                               <Col xs={12} md={3} style={{marginTop: 20}}>
                                    <Button variant="contained" onClick={() => uploadDoc()}>Add document</Button>
                                     
                              </Col>
                          </Row> 
                      </div>
                </Box>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default Supplier;