import React, {useState, useEffect} from 'react';
import { Form  } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const columns = [
    { id: '1', label: 'Sl No.', minWidth: 50,span: false },
    { id: '2', label: 'Promotion Name', minWidth: 50,span: false },
    { id: '3', label: 'Session Name', minWidth: 50,span: false },
    { id: '4', label: 'No of Coupon', minWidth: 50,span: false },
    { id: '5', label: 'Status', minWidth: 50,span: false },
    { id: '6', label: 'Expired On', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];
  const head = [
    { id: '1', label: 'Sl No.', minWidth: 50,span: false },
    { id: '2', label: 'Code', minWidth: 50,span: false },
    { id: '3', label: 'Name', minWidth: 50,span: false },
  ];

const source = [
    {'data' : 'test'}
]
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

function Promotion(props) {
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = React.useState(false);
  const [blocked, setBlocked] = React.useState(0)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const checkChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
      setBlocked(1)
    }
    else {
      setBlocked(0)
    }
    // do whatever you want with isChecked value
  }

    const onAdd = () => {
        setModal(true)
    }
    const editClick = () => {
          setModal(true)
      }

    return(
        <div className="main-content">
          
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Promotion List</p>
               <Button variant="contained" onClick={onAdd}>Add</Button>
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                
                <TableBody>
                  { source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell>
                                  1
                              </TableCell>
                              <TableCell >
                                  {row.data}
                              </TableCell>
                              <TableCell >
                                  {row.data}
                              </TableCell>
                              <TableCell >
                                  {row.data}
                              </TableCell>
                              <TableCell >
                                  {row.data}
                              </TableCell>
                              <TableCell >
                                  {row.data}
                              </TableCell>
                              <TableCell >
                                    <a href="javascript:void(0)" onClick={() => 
                                        editClick()}>
                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                    </a>
                              </TableCell>
                              <TableCell >
                                  <a href="javascript:void(0)">
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Promotion
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField id="outlined-basic" label="Promotion Name" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField id="outlined-basic" label="Promotion Code" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt:4, width: '100%' }}>
                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Promotion Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                label="Age"
                                size="small"
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormControl sx={{  width: '100%', mt:4 }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                    size="small"
                                        label="Start Time"
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                            </LocalizationProvider>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormControl sx={{  width: '100%', mt:4 }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                    size="small"
                                        label="From Time"
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                            </LocalizationProvider>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField id="outlined-basic" label="Minimum Bill Amount" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={6} md={4} lg={3}>
                        <p style={{marginTop: 20}}>Apply Type</p>
                        <FormControl component="fieldset">
                            <RadioGroup
                                defaultValue="%"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value="%"  control={<Radio checked={props.decr == 0?  false: true}/>} label="%" color = '#999'  />
                                <FormControlLabel value="Amount" color= '#999' control={<Radio checked={props.decr == 0?  true: false}/>} label="Amount" />
                            </RadioGroup>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField id="outlined-basic" label="Amount/Percentage" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt:4, width: '100%' }}>
                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Promotion Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                label="Age"
                                size="small"
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={5}>
                      <FormControl sx={{ mt: 4, width: '100%' }}>
                            <FormControlLabel control={
                              <Checkbox checked={blocked == 0? false : true} onChange={e => checkChange(e)} />
                            } label="Active" />
                        </FormControl>
                    </Col>
                    <Col xs={12}>
                        <p style={{marginTop: 20}}>Apply On</p>
                        <FormControl component="fieldset">
                            <RadioGroup
                                defaultValue="%"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value="product"  control={<Radio checked={props.decr == 0?  false: true}/>} label="Product" color = '#999'  />
                                <FormControlLabel value="category" color= '#999' control={<Radio checked={props.decr == 0?  true: false}/>} label="Category" />
                                <FormControlLabel value="customer" color= '#999' control={<Radio checked={props.decr == 0?  true: false}/>} label="Customer" />
                            </RadioGroup>
                        </FormControl>
                    </Col>
                    <Col xs={12}>
                        <TableContainer sx={{ maxHeight: 440, mt: 4 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                <TableRow>
                                    {head.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                        colSpan={column.span == true ? 2: 1}
                                    >
                                        {column.label}
                                        
                                    </TableCell>
                                    ))}
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {source
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                    return (
                                        <TableRow  tabIndex={-1} key={row.code}>
                                            <TableCell style={{fontWeight: 'bold'}}>
                                                1
                                            </TableCell>
                                            <TableCell >
                                                test
                                            </TableCell>
                                            <TableCell >
                                               test
                                            </TableCell>
                                        </TableRow>
                                    );
                                    })}
                                </TableBody>
                            </Table>
                            </TableContainer>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button style={{float: 'right'}} variant="contained">Submit</Button>
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default Promotion;