import React, {useState, useEffect} from 'react';
import { Form  } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Map from '../google-map/map';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../loader/loader'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';




const columns = [
    { id: '1', label: 'Voucherdate', minWidth: 50,span: false },
    { id: '2', label: 'Voucher No', minWidth: 50,span: false },
    { id: '3', label: 'Voucher Type', minWidth: 50,span: false },
    { id: '4', label: 'Code', minWidth: 50,span: false },   
    { id: '5', label: 'Product name', minWidth: 50,span: false },
    { id: '6', label: 'Batch', minWidth: 50,span: false },
    { id: '7', label: 'Godown', minWidth: 50,span: false },
    { id: '8', label: 'Unit', minWidth: 50,span: false },
    { id: '9', label: 'Opening', minWidth: 50,span: false },
    { id: '10', label: 'In', minWidth: 50,span: false },
    { id: '10', label: 'Out', minWidth: 50,span: false },
    { id: '10', label: 'Cost', minWidth: 50,span: false },
    { id: '10', label: 'Total', minWidth: 50,span: false },
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

const source = [
    {},{}
  ]


function Notification(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = useState("")

    const [toggle,  setToggle] = useState(false)
  const [modal, setModal] = useState(false);
  const [age, setAge] = React.useState('');

  const [source, setSource] = React.useState([]);
  

  const [loader, setLoader] = React.useState(false);
  const [routeId, setRouteid] = React.useState("");
  const [routename, setRoutename] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [blocked, setBlocked] = React.useState(0)
  const [dateFrom, setDatefrom] = React.useState(new Date)
  const [dateTo, setDateTo] = React.useState(new Date)

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

//   const checkChange = (e) => {
//     let isChecked = e.target.checked;
//     if(isChecked == true) {
//       setBlocked(1)
//     }
//     else {
//       setBlocked(0)
//     }
//     // do whatever you want with isChecked value
//   }

//   useEffect(() => {
//       getData()
//     }, []);

//     const getData = () => {
//         var userdata = JSON.parse(localStorage.getItem("user"))
//         var branch = JSON.parse(localStorage.getItem("branch"))
//         var company = JSON.parse(localStorage.getItem("company"))
//         setUsercompanyid(company)
//         setUserbranchid(branch)
//         setUsertype(userdata.user_type)
//         setUserid(userdata.user_id)
//         setLoader(true)
//         var data = {
//           "type":  userdata.user_type,
//           "companyid": company
//         }
//         fetch(global.url + "stockDetailReport", {
//             method: "POST",
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(data)
//           })
//           .then((response)=> response.json())
//           .then((responseJson)=>{
//               console.log("response route", responseJson)
//               if(responseJson.error == false){
//                 setLoader(false)
//                 setSource(responseJson.data)
//               }
//           })
//           .catch((error)=>{
//               console.log(error)
//           })
//     }



    



    return(
        <div className="main-content">
          
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Notification</p>
            </div>

            <Row className='a12' style={{padding:6}}>
                  <Col xs={12} md={3} style={{marginTop: 25}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                              label="From"
                              value={dateFrom}
                              onChange={(newValue) => {
                                  setDatefrom(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                          />
                      </LocalizationProvider>
                  </Col>
                  <Col xs={12} md={3} style={{marginTop: 25}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                              label="To"
                              value={dateTo}
                              onChange={(newValue) => {
                                  setDateTo(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                          />
                      </LocalizationProvider>
                  </Col>

                  <Col xs={12} md={3} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%'}}>
                            <TextField name="Zone"
                            // onChange={(e) => setBatchcode(e.target.value)}
                            autoComplete="off" id="outlined-basic" label="User name" variant="outlined"  size="small"/>
                        </FormControl>
                  </Col>
                  <Col xs={12} md={3} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%'}}>
                            <TextField name="Zone"
                            // onChange={(e) => setBatchcode(e.target.value)}
                            autoComplete="off" id="outlined-basic" label="Invoice no" variant="outlined"  size="small"/>
                        </FormControl>
                  </Col>
            </Row>
            <Row className='a12' style={{padding:6}}>
                  <Col xs={12} md={3} style={{marginTop: 25}}>
                        <FormControl sx={{ width: '100%'}}>
                            <TextField name="Zone"
                            // onChange={(e) => setBatchcode(e.target.value)}
                            autoComplete="off" id="outlined-basic" label="Edit" variant="outlined"  size="small"/>
                        </FormControl>
                  </Col>
                  <Col xs={12} md={3} style={{marginTop: 25}}>
                    <Button   style={{width: '50%'}} variant="contained">Filter</Button>
                  </Col>
            </Row>
            
            <TableContainer sx={{ maxHeight: 440 }}>
           
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                
                <TableBody>
                  {
                  source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  ihhfhhrgui
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  rdutihg
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  yufxjkkhi
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  vjhhkhjlk
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  itufhhvih
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  ooyuibku
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  uhiuibuiyyi
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  uyyfut8yh
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  fwwerew
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  fwrfrwgth
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  r3gewev
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  knfhero 
                              </TableCell>

                              
                              
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
  
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
        
      </div>
    )
}
export default Notification;