import '../Tax-Settings/TaxSettings.css';
import React, {Component, useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import { Row, Col, Table, Modal } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { HiOutlineSave, HiSave } from "react-icons/hi";
import { AiOutlineClose, AiFillMinusCircle } from "react-icons/ai";

const columns = [
    { id: '1', label: 'SI No.', minWidth: 50,span: false },
    { id: '2', label: 'Tax Name', minWidth: 50,span: false },
    { id: '3', label: 'Tax %', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];

  const inputP = {maxLength: 30}
    
  const rows = [
    {
      company: 'Lorem Ipsum',

    },
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
  ];


  const selectTransaction = [
    {id: '1'}, {id: '2'}, {id: '3'}, {id: '4'}, {id: '5'}, {id: '6'}, {id: '7'}, {id: '8'}, {id: '9'}, {id: '10'}, {id: '11'}, {id: '12'}, {id: '13'}, {id: '14'}
  ]

function State(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [age, setAge] = React.useState('');
    const [date, setDate] = React.useState(null);

    const [billtype, setBilltype] = React.useState('');
    const [userId, setUserid] = React.useState('');
    const [branch, setBranch] = React.useState('');
    const [branchList, setBranchlist] = React.useState([]);
    const [transactionList, setTransactionlist] = React.useState([]);
    const [transaction, setTransaction] = React.useState('');
    const [formType, setFormtype] = React.useState('');
    const [suffix, setSuffix] = React.useState('');
    const [number, setNumber] = React.useState('8');
    const [prefix, setPrefix] = React.useState('');

    const [source, setSource] = React.useState([]);
    const [transactionName, setTransactionName] = React.useState('');
    // const [selectTransaction, setSelectTrans] = React.useState([]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const billTypeChange = (event) => {
    setBilltype(event.target.value);
  };

  const branchChange = (event) => {
    setBranch(event.target.value);
  };

  const transactioChange = (event) => {
    setTransaction(event.target.value);
  };

  useEffect(() => {
    getData()
  }, []);

  useEffect(() => {
    getBranch()
    getTransaction()
  }, []);

  const getData = () => {
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(branch)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)

      var data = {
        "type":  userdata.user_type,
        "companyid": company
      }
      fetch(global.url + "viewBillSetting", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("list response", responseJson)
          setSource(responseJson.data)
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getBranch = () =>{
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    console.log("companyid", company)
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
    

    const data = {
      "companyid": company,
      "type": userdata.user_type
    }
    fetch(global.url + "viewBranch", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        console.log("response", responseJson)
        setBranchlist(responseJson.data)
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const getTransaction = () =>{
    fetch(global.url + "lastinvoiceNames", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        console.log("response trans", responseJson)
        setTransactionlist(responseJson.data)
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const onSave = () => {
    var data = {
        "user_id": userId,
        "formtype": formType,
        "billtype": billtype,
        "branch_id": branch,
        "transaction_id": transaction,
        "suffix": suffix,
        "number": number,
        "prefix": prefix,
        "billset_branchid" : userbranchid,
        "billset_companyid" : usercompanyid
    }
    console.log("vdsfbgsd", data)
      fetch(global.url + "updateBillSetting", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("responsejson", responseJson);
          if(responseJson.error == false) {
            getData()
            alert(responseJson.message)
            setBranch("")
            setBilltype("")
            setTransaction("")
            setFormtype("")
            setSuffix("")
            setNumber("")
            setPrefix("")
          }
          if(responseJson.status == "true") {
            alert(responseJson.message)
          }else{
            alert(responseJson.message)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const onClear = () => {
    setBranch("")
    setBilltype("")
    setTransaction("")
    setFormtype("")
    setSuffix("")
    setNumber("")
    setPrefix("")
  }

  const nameLists = () => {
   if(transactionName == ""){
    alert('Please enter transaction name')
   }else {
          setModal2(true)
          setModal(false)
   }
  }

  const submitTrans = (id) => {
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
      "companyid": company,
      "type": id,
      "name" : transactionName
    }
    console.log('data', data)
       fetch(global.url + "billset_transactions_update", {
         method: "POST",
         headers: {
             'Content-Type': 'application/json',
         },
         body: JSON.stringify(data),
     })
     .then((response)=> response.json())
     .then((responseJson)=>{
         console.log("responsejson", responseJson)
         setModal2(false)
         if(responseJson.error == false){
            alert(responseJson.message)
            getTransaction()
         }else{
            alert(responseJson.message)
         }
     })
     .catch((error)=>{
         console.log(error)
     })
   }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Bill Settings</p>
               <div>
                   <Row style={{marginTop: 50}}>
                       <Col xs={12} md={6}>
                            <FormControl sx={{width: '100%' }}>
                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Bill Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={billtype}
                                    label="Age"
                                    onChange={billTypeChange}
                                    size="small"
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={"B2B"}>B2B</MenuItem>
                                <MenuItem value={"B2C"}>B2C</MenuItem>
                                </Select>
                            </FormControl>
                       </Col>
                       <Col xs={12} md={6}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => setFormtype(e.target.value)} value={formType} id="outlined-basic" variant="outlined"  size="small" label="Form Type"/>
                            </FormControl>
                       </Col>
                   </Row>
                   <Row style={{marginTop: 30}}>
                       <Col xs={12} md={6}>
                            <FormControl sx={{width: '100%' }}>
                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Branch</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={branch}
                                    label="Age"
                                    onChange={branchChange}
                                    size="small"
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {branchList.map((item, index) => (
                                    <MenuItem value={item.branch_id}>{item.branch_name}</MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                       </Col>
                       <Col xs={12} md={4}>
                            <FormControl sx={{width: '100%' }}>
                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Transaction</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={transaction}
                                    label="Age"
                                    onChange={transactioChange}
                                    size="small"
                                >
                                 <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {transactionList.map((item, index) => (
                                    <MenuItem value={item.lin_name}>{item.lin_name}</MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                       </Col>
                       <Col xs={12} md={2}>
                          <Button onClick={() => setModal(true)} style={{backgroundColor: '#6d37b9'}} variant="contained">Add</Button>
                       </Col>
                   </Row>
                   <Row style={{marginTop: 30}}>
                        <Col xs={12} md={4}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField inputProps={inputP} id="outlined-basic" variant="outlined"  size="small" label="Prefix" onChange={(e) => setPrefix(e.target.value)} value={prefix} />
                            </FormControl>
                       </Col>
                       <Col xs={12} md={4}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => setNumber(e.target.value)} value={number} id="outlined-basic" variant="outlined"  size="small" label="Number"/>
                            </FormControl>
                       </Col>
                       <Col xs={12} md={4}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => setSuffix(e.target.value)} value={suffix} id="outlined-basic" variant="outlined"  size="small" label="Suffix" />
                            </FormControl>
                       </Col>
                   </Row>
                   <Row style={{marginTop: 30}}>
                       
                       <Col xs={12} md={4}>
                            <Button onClick={onSave} style={{backgroundColor: '#6d37b9', borderRadius: 8}} variant="contained"><HiSave  color="White" fontSize={20} style={{marginRight: 10}} />Save</Button>
                            <Button onClick={onClear} style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 8}} variant="contained"><AiOutlineClose color="White" fontSize={20} style={{marginRight: 10}} />Clear</Button>
                       </Col>
                   </Row>
                    <Row style={{marginTop: 30}}>
                        <Col xs={12}>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>SlNo</th>
                                        <th>Bill Type</th>
                                        <th>Transactions</th>
                                        <th>Bill No.</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                {source.map((row, index) => {
                                    return (
                                        <tr>
                                            <td>{index+1}</td>
                                            <td>{row.billset_billtype}</td>
                                            <td>{row.billset_transactions}</td>
                                            <td>{row.billset_prefix}{row.billset_billno}{row.billset_suffix}</td>
                                            
                                        </tr>  
                                    )})
                                    } 
                                </tbody>
                            </Table>
                        </Col>
                    </Row> 
                </div>
            </div>
            <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
              >
              <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                  Add Transaction
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                      <Col xs={12} md={6}>
                          <FormControl sx={{width: '100%' }}>
                              <TextField id="outlined-basic" variant="outlined" onChange={(e) => setTransactionName(e.target.value)} value={transactionName}  size="small" label="Transaction" />
                          </FormControl>
                      </Col>
                      <Col xs ={12} style={{marginTop:30}}>
                        <Button onClick={nameLists} style={{float: 'right'}} variant="contained">Submit</Button>
                        <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                  </Row>
              </Modal.Body>
          </Modal>
          <Modal
                size="lg"
                show={modal2}
                onHide={() => setModal2(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                     Select Type
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <ul>
                            {selectTransaction.map((row, index) => {
                                return (
                                    <a style={{textDecoration: 'none', listStyle: 'none'}} onClick={() => submitTrans(row.id)}>
                                        <li className="pay-lists" style={{color: '#000'}}>
                                          {row.id == 1 ? 'Sales Order' : row.id == 2 ? 'Payment' : row.id == 3 ? 'Receipt' : row.id == 4 ? "Purchase Request" :
                                          row.id == 5 ? "Purchase Order" : row.id == 6 ? "Purchase" : row.id == 7 ? "Sales" : row.id == 8 ? "Journal" : row.id == 9 ? 'POS' : 
                                          row.id == 10 ? 'Purchase Single Return' : row.id == 11 ? 'Sales Single Return' : row.id ==12 ? "Wastege" : row.id == 13 ? "Transfer In" : row.id == 14 ? "Transfer Out" : row.id }
                                        </li>
                                    </a>
                                )})
                                }
                            </ul>
                        </Col>
                        
                    </Row>
                </Modal.Body>
            </Modal>
          </Paper> 
      </div>
    )
}
export default State;