import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form  } from "react-bootstrap";
import { BsPersonFill, BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";
import { FaArrowCircleRight } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import Loader from '../loader/loader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import './login.css'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [branchlist, setBranchlist] = useState([]);
    const [branch, setBranch] = useState("");
    let history = useHistory();
    const { id } = useParams();
    const [loader, setLoader] = useState(false);
    const [checked, setChecked] = useState(true);

    const [finyear, setFineyear] = useState("");
    const [open, setOpen] = React.useState(false);
    const [errorMessage, setErrormessage] = React.useState(false);

    const vertical = "top"
    const horizontal = "center"
  


    const onSubmit = () => {
        setLoader(true)
        var data = {
            "user_name": username,
            "user_password": password,
            "branch_id": branch,
            "company_id": id
        }
        fetch(global.url + "login", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setLoader(false)
            if(responseJson.error == true) {
                // alert(responseJson.message)
                setOpen(true)
                setErrormessage(responseJson.message)
            }
            else{
                localStorage.setItem("isAuthenticated", true);
                localStorage.setItem("company", id);
                localStorage.setItem("user", JSON.stringify(responseJson.data))
                
                history.push('/dashboard')
               
            }
             console.log("login Response", responseJson)
        })
        .catch((error)=>{
            console.log(error)
        })

    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    useEffect(() => {
        
        const data = {
            "company_id": id
        }
        fetch(global.url + "viewAllBranch", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("branch list response", responseJson)
            setBranchlist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
    }, []);

    const branchselect = (event) => {
        console.log("clicked")
        setBranch(event.target.value)
        localStorage.setItem("branch", event.target.value)
    }

    const yearChange = (event) => {
        setFineyear(event.target.value)
    }

    const checkChange = (e) => {
        let isChecked = e.target.checked;
        if(isChecked == true) {
           setChecked(true)
        }
        else {
           setChecked(false)
        }
      }



  return (
    <div className="App">
      <header className="Login-component">
            <Container fluid>
                <Row >
                    <Col xs={12} style={{marginTop: 20}}> 
                        <div className="login-box">
                            <div className="box-inside">
                                <div className="login-title">
                                    <strong>NASSCRIPT
                                        
                                    </strong>
                                </div>
                                <div class="frm-title">
                                    Welcome back! Please login to continue
                                </div>
                                <Form>
                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField className="login-textfield" value={username} onChange={(e) => setUsername(e.target.value)} id="outlined-basic" label="Username" variant="outlined"  size="small"/>
                                        <BsPersonFill color="#999"  style={{position: 'absolute', top:0, left: 0, marginTop: 10, marginLeft: 10}}/>
                                    </FormControl>

                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <TextField className="login-textfield" value={password} onChange={(e) => setPassword(e.target.value)} id="outlined-basic" label="Password" variant="outlined" type="password"  size="small"/>
                                        <BsFillLockFill color="#999"  style={{position: 'absolute', top:0, left: 0, marginTop: 10, marginLeft: 10}}/>
                                    </FormControl>
                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                            <InputLabel id="demo-simple-select-helper-label">Financial Year</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={finyear}
                                                label="Age"
                                                onChange={yearChange}
                                                size="small"
                                                
                                            >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                                <MenuItem value={"2021-2022"}>2021-2022</MenuItem>
                                            </Select>
                                    </FormControl>
                                    <FormControl sx={{ mt: 3, width: '100%' }}>
                                            <InputLabel id="demo-simple-select-helper-label">Select Branch</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={branch}
                                                label="Age"
                                                onChange={branchselect}
                                                size="small"
                                                
                                            >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {branchlist.map((item, index) => (
                                                <MenuItem value={item.branch_id}>{item.branch_name}</MenuItem>
                                                ))}
                                            </Select>
                                    </FormControl>

                                    {/* <Row style={{marginTop: 10}}>
                                        <Col xs={6}>
                                            <FormControl sx={{ width: '100%' }}>
                                                <FormControlLabel control={
                                                <Checkbox checked={checked} onChange={e => checkChange(e)} />
                                                } label="Remember me" />
                                            </FormControl>
                                        </Col>
                                        <Col xs={6} >
                                            <div className="forgotpassword">
                                                <BsFillUnlockFill color="#999" size={12} className="unlockicon"/>
                                                <p className="forgot-text">Forgot password?</p>
                                            </div>
                                        </Col>
                                    </Row> */}
                                 {loader ? <Loader /> :
                                        <a href="javascript:void(0)" onClick={() => {
                                            
                                                onSubmit()
                                        
                                            
                                            }}  variant="primary" type="submit" style={{marginTop: 20, background: '#8a4cff', borderColor: '#8a4cff', color: '#fff', textDecoration: 'none', width: 100, padding: 5, borderRadius: 5}}>
                                            Login
                                            <FaArrowCircleRight color="#fff" size={12} className="lefticon"/>
                                        </a>
                                        }
                                </Form>
                                <div class="frm-footer"><span className="nastext">NASScript</span> © 2021.</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            
      </header>
      <Snackbar anchorOrigin={{vertical, horizontal}} open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Login;
