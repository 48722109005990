import React, {Component, useEffect, useState, useRef} from 'react';
import Paper from '@mui/material/Paper';
import { Table , Row, Col, Modal, Container} from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import BtnLoader from '../../components/loader/btnLoader';
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import { AiOutlineClose, AiFillMinusCircle } from "react-icons/ai";

function StockAdjustment(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [userId, setUserid] = useState("")
    const [bulkfile, setBulkfile] = React.useState();

    const [tableRow,setTableRow] = React.useState([{key:"",sa_productbatch_fk:"",product_id: "", pbexp_id: '', code: "", productname: "", pricelevel: "", sa_quantity: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: 0, sd_id: "",color: "", size: "",colorsize: [], fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: ""}] }])
    const inputRef = useRef();
    const resultsRef = useRef();
    const [code, setCode] = useState("");
    const [textArray, setTextarray] = React.useState([{key: "", value: ""}])
    const [selectedIndex, setSelectedindex] = React.useState("")
    const [productlist, setProductlist] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    const [discount, setDiscount] = React.useState('');
    const [otherCharges, setOthercharge] = React.useState(10);
    const [advance, setAdvance] = React.useState('');
    const [bulkloader, setBulkloader] = React.useState(false);
    const [expirydatelist, setExpirydatelist] = React.useState([]);

    const [docno, setDocno] = React.useState('');
    const [date, setDate] = React.useState(new Date);
    const [refno, setRefno] = React.useState('');
    // const [advance, setAdvance] = React.useState('');

    const [narration, setNarration] = React.useState('')

    const hasResults = code.length > 0;

    const handleOnChange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].code = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setCode(event.currentTarget.value);
        getSearch(event.currentTarget.value, type)
    }

    const listClickrefcode = (index, productid, productrefcode,productname,products, iscombo) => {
        var input = [...tableRow]
        input[index].key = index
        input[index].products_id = productid
        input[index].code = productrefcode
        input[index].sales_iscombo = iscombo
        input[index].productname = productname
        input[index].colorsize = products
        setSelectedindex(index)
        setTableRow([...tableRow, input])
        setTableRow([...tableRow, {key:"",sa_productbatch_fk:"",product_id: "", code: "", productname: "", pricelevel: "", sa_quantity: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: 0, pb_stock: "", sales_iscombo: "",sd_id: "",color: "", size: "",colorsize: [],  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
        setCode("")
    }

    const listClickcode = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount, percentage, stock, iscombo, color, size, colorsize1) => {
        console.log("color1", colorsize1)
        
        var input = [...tableRow]
        input[index].key = index
        input[index].sa_productbatch_fk = batchid
        input[index].code = code
        input[index].pb_stock = stock
        input[index].productname = name
        input[index].sales_iscombo = iscombo
        input[index].sa_quantity = qty
        input[index].unit = unit
        input[index].color = color
        input[index].size = size
        
        console.log("no customer")
            
        console.log("no customer no per")
        input[index].price = salesrate
        input[index].calcprice = calcprice
        input[index].discount = discount
        if(discount) {
        
            var totalcalc = (calcprice/100) * discount
        }
        else {
        
            var totalcalc = 0
        }
        input[index].pricelevel = pricelevel
        input[index].sd_total_amount = calcprice - totalcalc

        
        
        
        setTableRow([...tableRow, input])
        setTableRow([...tableRow, {key:"",sa_productbatch_fk:"",product_id: "", code: "", productname: "", pricelevel: "", sa_quantity: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: 0, pb_stock: "", sales_iscombo: "",sd_id: "",color: "", size: "",colorsize: [],  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
        setCode("")
        
        var total1 = total + totalcalc
        console.log("totalcalc", total1)
        setTotal(total1)
  }

  const changeBulkfile =(e) => {
    setBulkfile(e.target.files[0])
  }

  useEffect(() => {
    getDocno()
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
  }, []);

  const getDocno = () => {
    var company = JSON.parse(localStorage.getItem("company"))
      var data = {
        "companyid" : company
      }
    fetch(global.url + "getStockAdjustmentVoucherno", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("stockdocno", responseJson.data)
          if(responseJson.error == false) {
            setDocno(responseJson.data)
          }
          else{
            
          }
          
      })
      .catch((error)=>{
          console.log(error)
      })
  }

    const getSearch = (text, type) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        if(type == "code") {
            var data = {
                "type" : type,
                "product_code":  text,
                "companyid" : company,
                "branchid" : branch
            }
        }
        else {
            var data = {
                "type" : type,
                "refcode":  text,
                "companyid" : company,
                "branchid" : branch
            }
        }
        console.log("searchdata", data)
        
        fetch(global.url + "getProductbySearchpc", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response", responseJson)
            setProductlist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
        
    }

    const colorChange = (event) => {
        var input=[...tableRow]
        console.log("selectedIndex", selectedIndex)
        input[selectedIndex].sa_productbatch_fk = event.target.value
        setTableRow(input)
        getExpiry(event.target.value)
        getProductbybatch1(event.target.value, selectedIndex)
      //   setColor(event.target.value)
    }

    const dateChange = (event) => {
        var input=[...tableRow]
        console.log("selectedIndex", selectedIndex)
        input[selectedIndex].pbexp_id = event.target.value
        setTableRow(input)
    }

    const getExpiry = (id) => {
        var data = {
            "pb_id" : id,
        }
        console.log("data", data)
        fetch(global.url + "getExpiry", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("expiry", responseJson)
            setExpirydatelist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getProductbybatch1 = (id, index) => {
   
        var data = {
            "pb_id" : id,
        }
        console.log("data", data)
        fetch(global.url + "getProductBatchById", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
  
            setDetails(id, index, responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
      }

    const setDetails = (id, index, responseJson) => {

        var input = [...tableRow]
        input[index].key = index
        input[index].sa_productbatch_fk = id
        
        input[index].pb_stock = responseJson.pb_stock

        
        
        input[index].sa_quantity = 1
        input[index].unit = responseJson.unit_code
        input[index].color = responseJson.color_name
        input[index].size = responseJson.size_name
        
        input[index].price = responseJson.pb_salesrate
        input[index].calcprice = responseJson.pb_salesrate
        input[index].discount = 0

        input[index].sd_total_amount = responseJson.pb_salesrate 

            
            
            console.log("input", input)
            setTableRow(input)
            // setTableRow([...tableRow, {key:"",sa_productbatch_fk:"",product_id: "", code: "", productname: "", pricelevel: "", sa_quantity: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: 0, pb_stock: "", sales_iscombo: "",sd_id: "",color: "", size: "",colorsize: [],  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
            // setCode("")
            
            // var total1 = total + responseJson.pb_salesrate
            // console.log("totalcalc", total1)
            // setTotal(total1)
    }

    const qtyChange = (event, index) => {
        var textArray1 = [...tableRow]
        console.log("price", textArray1[index].price)
        var totalprice = textArray1[index].price * event.target.value
        textArray1[index].sa_quantity = event.target.value
        textArray1[index].calcprice = totalprice
        textArray1[index].sd_total_amount = totalprice
        setTableRow(textArray1)
    
    }

    const addRow = () => {
        setTableRow([...tableRow, {key:"",sa_productbatch_fk:"", pbexp_id: "", code: "", productname: "", pricelevel: "", sa_quantity: "", unit: "", price: "", calcprice: "", discount: "", sd_total_amount: "", pb_stock: "", sales_iscombo: "", sd_id: "",color: "", size: "",colorsize: [], fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "",  stock: ""}]}])
    }

    const deleteRow = (index, price) => {
        console.log("index", index)
        setTotal(total-price)
        var input = [...tableRow]
        input.splice(index, 1)
        console.log("input", input)
        setTableRow(input)
      }

    const disountChange = (event, index) => {
        var input = [...tableRow]
        input[index].discount = event.currentTarget.value
        var disc = (input[index].calcprice/100) * input[index].discount
        input[index].sd_total_amount = input[index].calcprice - disc
        setTableRow(input)
    }

    const onSubmit = (type) => {
        if(type == "addstock") {
            const exampleArray = []
            for(var i = 0 ; i< tableRow.length; i++) {
                if(tableRow[i].sa_productbatch_fk == "" || tableRow[i].sales_isedit == 1) {
                    // tableRow.splice(i, 1)
                }
                else{
                    exampleArray.push(tableRow[i])
                } 
            }
            console.log("tableRow", exampleArray)
            var data = {
                "type" : "add",
                "companyid":  usercompanyid,
                "branchid":  userbranchid,
                "userid":  userId,
                "narration":  narration,
                "bill_type":  "",
                "doc_date":  date,
                "ref_no":  refno,
                "doc_no":  docno,
                "adjustment_table":  exampleArray,
            }
            console.log("data", data)
            fetch(global.url + "stockAdjust", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("response", responseJson)
                if(responseJson.error == true) {
                    alert(responseJson.data)
                }
                else{
                    alert(responseJson.message)
                    window.location.reload()
                }
            })
            .catch((error)=>{
                console.log(error)
            })
        }
        else{
            const exampleArray = []
            for(var i = 0 ; i< tableRow.length; i++) {
                if(tableRow[i].sa_productbatch_fk == "" || tableRow[i].sales_isedit == 1) {
                    // tableRow.splice(i, 1)
                }
                else{
                    exampleArray.push(tableRow[i])
                } 
            }
            console.log("tableRow", exampleArray)
            var data = {
                "type" : "minus",
                "companyid":  usercompanyid,
                "branchid":  userbranchid,
                "userid":  userId,
                "narration":  narration,
                "bill_type":  "",
                "doc_date":  date,
                "ref_no":  refno,
                "doc_no":  docno,
                "adjustment_table":  exampleArray,
            }
            console.log("data", data)
            fetch(global.url + "stockAdjust", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("response", responseJson)
                if(responseJson.error == true) {
                    alert(responseJson.data)
                }
                else{
                    alert(responseJson.message)
                    window.location.reload()
                }
            })
            .catch((error)=>{
                console.log(error)
            })
        }
    }

    const onBulksubmit = () => {
        setBulkloader(true)
        const formData = new FormData();
        formData.append('upload_file', bulkfile);
        formData.append('user_id', userId);
        formData.append('branch_id', userbranchid);
        formData.append('company_id', usercompanyid);
        
        fetch(global.url + "excelImportCountedStock", {
          method: "POST",
          body: formData,
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("responsejson", responseJson.message)
            if(responseJson.error == false) {
              setBulkloader(false)
              alert(responseJson.message)
              window.location.reload()
            }
            else{
              setBulkloader(false)
              alert(responseJson.message)
            }
          })
          .catch((error)=>{
              console.log(error)
          })
      }


    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Stock Adjustment</p>
               
               <Row>
                    <Col xs={12} >
                            <label style={{width: '100%'}}>Select File</label>
                            <input type="file" onChange={(e) => changeBulkfile(e)}  style={{marginTop: 10}}/>
                            {bulkloader ? 
                                <BtnLoader />:
                                <Button onClick={() => onBulksubmit()}  style={{float: 'right'}} variant="contained">Submit</Button>
                            }
                    </Col>
                </Row>
            </div>
            <Row>
                <Col sm={12}>
                    <Row>
                        <Col sm={6} lg={1} style={{marginTop:30}}>
                            <p className="label">Bill Type</p>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Bill Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        label="Age"
                                        size="small"
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    </Select>
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={1} style={{marginTop:30}}>
                            <p className="label">Doc No.</p>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={docno} disabled id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>

                    </Row>
                    <Row>
                        <Col sm={6} lg={1} style={{marginTop:30}}>
                            <p className="label">Doc Date</p>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            size="small"
                                            value={date}
                                                onChange={(newValue) => {
                                                setDate(newValue);
                                                }}
                                            label="Date"
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                </LocalizationProvider>
                            </FormControl>
                        </Col>
                        <Col sm={6} lg={1} style={{marginTop:30}}>
                            <p className="label">Ref No.</p>
                        </Col>
                        <Col sm={6} lg={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={refno} onChange={(e) => setRefno(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row>
                </Col>
            </Row> 
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <Table bordered responsive>
                        <thead>
                            <tr>
                                <th>SlNo</th>
                                <th>Code</th>
                                <th>color-size</th>
                                <th>Product name</th>
                                <th>Expiry Date</th>
                                <th>Qty</th>
                                <th>Unit</th>
                                <th>Price</th>
                                <th>Calc Price</th>
                                <th>Discount(%)</th>
                                <th>Amount</th>
                                <th>Close</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableRow.map((item, index) => {
                                return (
                                    <tr>
                                        <td >{index+1}</td>
                                            <td>
                                            {
                                                item.sales_isedit == 1 ?
                                                <input autocomplete="off" disabled ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} />:
                                                <input autocomplete="off" ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} />
                                            }
                                        
                                            {hasResults && selectedIndex == index && (
                                                <div className="autocomplete">
                                                    <ul ref={resultsRef} className="people scrolling-div">
                                                        {productlist.map(item => {
                                                            return (
                                                                item.product_fetchiscombo == 0 ? 
                                                                item.products.map(result => {
                                                                    return (
                                                                    <li key={item.url}>
                                                                        <a href="javascript:void(0)" onClick={() => listClickrefcode(
                                                                            index, 
                                                                            item.products_id,
                                                                            item.product_refcode,
                                                                            item.product_name,
                                                                            item.products,
                                                                            
                                                                            0
                                                                            )}>
                                                                        {item.product_refcode} - { item.product_name }
                                                                        </a>
                                                                    </li>
                                                                )
                                                                }) : 
                                                                <li key={item.url}>
                                                                    <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                        index, 
                                                                        item.products_id,
                                                                        item.product_refcode,
                                                                        item.product_name,
                                                                        item.pricelevel_name,
                                                                        "1",
                                                                        item.unit_name,
                                                                        item.product_comboprice,
                                                                        item.product_comboprice,
                                                                        0,
                                                                        item.product_comboprice,
                                                                        item.pricelevel_percentage,
                                                                        item.product_combostock,
                                                                        1
                                                                        )}>
                                                                    {item.product_refcode} - { item.product_name }
                                                                    </a>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            )}
                                            </td>
                                            
                                        <td>
                                                <FormControl sx={{width: '100%' }}>
                                                        <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Select</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-helper-label"
                                                            id="demo-simple-select-helper"
                                                            value={item.sa_productbatch_fk}
                                                            label="Age"
                                                            onChange={colorChange}
                                                            size="small"
                                                        >
                                                        <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {item.colorsize.map((row, index) => {
                                                            return (
                                                            <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                                        )})}
                                                        </Select>
                                                </FormControl>
                                        </td>
                                        <td style={{fontSize: 10}}>

                                            {item.sales_iscombo == 0 || item.sales_iscombo == null   ? 
                                                <p> {item.productname} - {item.color} - {item.size} </p> :
                                                null
                                                }
                                        </td>
                                        <td>
                                                <FormControl sx={{width: '100%' }}>
                                                        <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Select</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-helper-label"
                                                            id="demo-simple-select-helper"
                                                            value={item.pbexp_id}
                                                            label="Age"
                                                            onChange={dateChange}
                                                            size="small"
                                                        >
                                                        <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {expirydatelist.map((row, index) => {
                                                            return (
                                                            <MenuItem value={row.pbexp_id}>{row.pbexp_date}</MenuItem>
                                                        )})}
                                                        </Select>
                                                </FormControl>
                                        </td>
                                        <td>
                                            {item.sales_iscombo == 0 ||  item.sales_iscombo == null   ?
                                                <div>  
                                                    {item.sales_isedit == 1 ?
                                                    <input min="1" disabled  autocomplete="off" type="number" name="query" value={item.sa_quantity} onChange={(event) => qtyChange(event, index)} />: 
                                                    <input min="1"  autocomplete="off" type="number" name="query" value={item.sa_quantity} onChange={(event) => qtyChange(event, index)} />
                                                    }
                                                    {   
                                                        <label style={{fontSize: 12, fontWeight: 'bold', color: 'red'}}>stock: {item.pb_stock}</label>
                                                    }
                                                </div>: 
                                                null
                                            }
                                        </td>
                                        <td>{item.unit}</td>
                                        <td>{item.price}</td>
                                        <td>{item.calcprice}</td>
                                        
                                        <td>
                                        {item.sales_isedit == 1 ?
                                        <input autocomplete="off" disabled  type="number" name="query" value={item.discount} onChange={(event) => disountChange(event, index)} />
                                        :<input autocomplete="off"  type="number" name="query" value={item.discount} onChange={(event) => disountChange(event, index)} />
                                        }
                                        </td>
                                        <td>{item.sd_total_amount}</td>
                                        <td>
                                        {item.sales_isedit == 1 ? null: <Button onClick={() => deleteRow(index, item.amount)} style={{backgroundColor: '#fff', border: 'none', boxShadow: 'none'}} variant="contained">< AiFillMinusCircle color="#6d37b9" fontSize={30} style={{marginRight: 10}} /></Button>}</td>
                                    </tr>
                                )
                            })}
                            
                        </tbody>
                    </Table>
                    <Button onClick={() => addRow()} style={{float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20, marginTop: 20}} variant="contained">Add New</Button>
                </Col>
            </Row>
            <div style={{marginTop: 30}}>
                <Row style={{marginTop: 20}}>
                    <Col xs={12}>
                        <TextareaAutosize 
                            onChange={(e) => setNarration(e.target.value)} 
                            value={narration}
                            minRows={3}
                            placeholder="Narration"
                            style={{background: 'transparent', width: '100%'}}
                        />
                    </Col>
                </Row>
                
                <Row style={{marginTop: 20}}>
                    <Col xs={12} >
                        <ul className="list-inline">
                            <li><Button onClick={() => onSubmit("addstock")} variant="outlined"><IconName.BsDownload style={{marginRight: 10}}/>Add Stock</Button></li>
                            {/* <li><Button onClick={() => onSubmit("minusstock")} variant="outlined"><IconName.BsDownload style={{marginRight: 10}}/>Minus Stock</Button></li> */}
                            {/* <li><Button variant="outlined"><GI.GiBinoculars style={{marginRight: 10}}/>Find</Button></li>
                            <li><Button variant="outlined"><BI.BiPrinter style={{marginRight: 10}}/>Print</Button></li>
                            <li><Button variant="outlined"><RI.RiDeleteBin6Line style={{marginRight: 10}}/>Delete</Button></li>
                            <li><Button variant="outlined"><RI.RiCloseFill style={{marginRight: 10}}/>Close</Button></li> */}
                        </ul>
                    </Col>
                </Row>
            </div>
          </Paper> 
      </div>
    )
}
export default StockAdjustment;